export const LUXURY_HOMES_FOR_SALE = 500

export const LUXURY_HOMES_FOR_SALE_TEXT = ''

export const LAVISH_HIGH_RISE_CONDOMINIUMS = 5000
export const GATED_MANSIONS = 5001
export const SINGLE_FAMILY_HOMES = 5002
export const CUSTOM_HOMES = 5003
export const GRAND_ESTATES = 5004
export const VAST_RANCHES = 5005

export const LAVISH_HIGH_RISE_CONDOMINIUMS_TEXT = 'Lavish High-Rise Condominiums'
export const GATED_MANSIONS_TEXT = 'Gated Mansions'
export const SINGLE_FAMILY_HOMES_TEXT = 'Single-Family Homes'
export const CUSTOM_HOMES_TEXT = 'Custom Homes'
export const GRAND_ESTATES_TEXT = 'Grand Estates'
export const VAST_RANCHES_TEXT = 'Vast Ranches'

export const LuxuryHomesForSaleSubTypes = [
  {
    id: LUXURY_HOMES_FOR_SALE,
    title: LUXURY_HOMES_FOR_SALE_TEXT,
    options: [
      { id: LAVISH_HIGH_RISE_CONDOMINIUMS, text: LAVISH_HIGH_RISE_CONDOMINIUMS_TEXT },
      { id: GATED_MANSIONS, text: GATED_MANSIONS_TEXT },
      { id: SINGLE_FAMILY_HOMES, text: SINGLE_FAMILY_HOMES_TEXT },
      { id: CUSTOM_HOMES, text: CUSTOM_HOMES_TEXT },
      { id: GRAND_ESTATES, text: GRAND_ESTATES_TEXT },
      { id: VAST_RANCHES, text: VAST_RANCHES_TEXT }
    ]
  }
]

export const LuxuryHomesForSaleSubTypesOptions = [
  ...LuxuryHomesForSaleSubTypes[0].options
]
