export default {
  viewType: 'map',
  realEstatePropertiesFilteredView: false,
  properties: {},
  propertiesMap: [],
  propertyById: {},
  propertyBids: [],
  pagination: {
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    total: 0
  },
  searchString: '',
  propertyType: null,
  propertySubTypes: [],
  cashOfferSent: null,
  cashOfferReceived: [],
  showBidsModal: false
}
