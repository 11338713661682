export const RESIDENTIAL_FOR_RENT_HOME = 700

export const RESIDENTIAL_FOR_RENT_HOME_TEXT = ''

export const HOME_APARTMENTS = 7001
export const HOME_HOUSES = 7002
export const HOME_CONDOS = 7003
export const HOME_TOWN_HOMES = 7004

export const HOME_APARTMENTS_TEXT = 'Apartments'
export const HOME_HOUSES_TEXT = 'Single Family'
export const HOME_CONDOS_TEXT = 'Condos'
export const HOME_TOWN_HOMES_TEXT = 'Townhouse'

export const ResidentialForRentSubTypes = [
  {
    id: RESIDENTIAL_FOR_RENT_HOME,
    title: RESIDENTIAL_FOR_RENT_HOME_TEXT,
    options: [
      { id: HOME_HOUSES, text: HOME_HOUSES_TEXT },
      { id: HOME_CONDOS, text: HOME_CONDOS_TEXT },
      { id: HOME_TOWN_HOMES, text: HOME_TOWN_HOMES_TEXT }
    ]
  }
]

export const ResidentialForRentSubTypesOptions = [
  ...ResidentialForRentSubTypes[0].options
]
