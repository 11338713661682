import * as faker from 'faker'
import moment from 'moment'
import User from '@/Model/User'
import Erez from '@/FackApi/api/Users/ErezJonathan'
import responsePresenter from '@/FackApi/services/responsePresenter'
import ChatMessage from '@/Model/Messanger/ChatMessage'

export default async function forwardMessage (data) {
  await new Promise((resolve) => setTimeout(resolve, 300))
  // Backend data
  const ErezJonathan = new User({ ...Erez })
  const creator = { image: ErezJonathan.avatar, me: true, fullName: ErezJonathan.fullName }
  const date = moment().utc().format()
  const messageData = {
    id: faker.datatype.uuid(),
    creator,
    parent: new ChatMessage(data.message),
    isForward: true,
    createdAt: date,
    updatedAt: date
  }

  const newMessage = new ChatMessage({ ...messageData })
  const response = responsePresenter({ data: { chatId: data.chatId, message: newMessage } })

  return response
}
