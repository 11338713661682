export const COMMERCIAL_FOR_LEASE_RETAIL = 301
export const COMMERCIAL_FOR_LEASE_OFFICE = 302
export const COMMERCIAL_FOR_LEASE_INDUSTRIAL = 303
export const COMMERCIAL_FOR_LEASE_LAND = 304
export const COMMERCIAL_FOR_LEASE_RESTAURANT = 305
export const COMMERCIAL_FOR_LEASE_SPECIAL_PURPOSE = 306

export const COMMERCIAL_FOR_LEASE_RETAIL_TEXT = 'Retail'
export const COMMERCIAL_FOR_LEASE_OFFICE_TEXT = 'Office'
export const COMMERCIAL_FOR_LEASE_INDUSTRIAL_TEXT = 'Industrial'
export const COMMERCIAL_FOR_LEASE_LAND_TEXT = 'Land'
export const COMMERCIAL_FOR_LEASE_RESTAURANT_TEXT = 'Restaurant'
export const COMMERCIAL_FOR_LEASE_SPECIAL_PURPOSE_TEXT = 'Special Purpose'

export const OFFICE_TRADITIONAL_OFFICE = 3002
export const OFFICE_EXECUTIVE_OFFICE = 3003
export const OFFICE_COWORKING = 3004
export const OFFICE_MEDICAL_OFFICE = 3005
export const OFFICE_CREATIVE_OFFICE = 3006

export const OFFICE_TRADITIONAL_OFFICE_TEXT = 'Traditional Office'
export const OFFICE_EXECUTIVE_OFFICE_TEXT = 'Excecutive Office'
export const OFFICE_COWORKING_TEXT = 'Coworking'
export const OFFICE_MEDICAL_OFFICE_TEXT = 'Medical Office'
export const OFFICE_CREATIVE_OFFICE_TEXT = 'Creative Office'

export const INDUSTRIAL_DISTRIBUTION = 3007
export const INDUSTRIAL_FLEX = 3008
export const INDUSTRIAL_WAREHOUSE = 3009
export const INDUSTRIAL_R_AND_D = 3010
export const INDUSTRIAL_MANUFACTURING = 3011
export const INDUSTRIAL_REFRIGERATED_COLD_STORAGE = 3012

export const INDUSTRIAL_DISTRIBUTION_TEXT = 'Distribution'
export const INDUSTRIAL_FLEX_TEXT = 'Flex'
export const INDUSTRIAL_WAREHOUSE_TEXT = 'Warehouse'
export const INDUSTRIAL_R_AND_D_TEXT = 'R&D'
export const INDUSTRIAL_MANUFACTURING_TEXT = 'Manufacturing'
export const INDUSTRIAL_REFRIGERATED_COLD_STORAGE_TEXT = 'Refrigerated/Cold Storage'

export const LAND_AGRICULTURAL = 3013
export const LAND_RESIDENTIAL = 3014
export const LAND_COMMERCIAL = 3015
export const LAND_INDUSTRIAL = 3016

export const LAND_AGRICULTURAL_TEXT = 'Agricultural'
export const LAND_RESIDENTIAL_TEXT = 'Residential'
export const LAND_COMMERCIAL_TEXT = 'Commercial'
export const LAND_INDUSTRIAL_TEXT = 'Industrial'

export const SPECIAL_PURPOSE_AIRPORT = 3017
export const SPECIAL_PURPOSE_AIRPORT_TEXT = 'Airport'

export const RETAIL = 3018
export const RESTAURANT = 3019

export const RETAIL_TEXT = 'Retail'
export const RESTAURANT_TEXT = 'Restaurant'

export const CommercialForLeaseSubTypes = [
  {
    id: 307,
    title: '',
    options: [
      { id: RETAIL, text: RETAIL_TEXT },
      { id: RESTAURANT, text: RESTAURANT_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_LEASE_OFFICE,
    title: COMMERCIAL_FOR_LEASE_OFFICE_TEXT,
    options: [
      { id: OFFICE_TRADITIONAL_OFFICE, text: OFFICE_TRADITIONAL_OFFICE_TEXT },
      { id: OFFICE_EXECUTIVE_OFFICE, text: OFFICE_EXECUTIVE_OFFICE_TEXT },
      { id: OFFICE_COWORKING, text: OFFICE_COWORKING_TEXT },
      { id: OFFICE_MEDICAL_OFFICE, text: OFFICE_MEDICAL_OFFICE_TEXT },
      { id: OFFICE_CREATIVE_OFFICE, text: OFFICE_CREATIVE_OFFICE_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_LEASE_INDUSTRIAL,
    title: COMMERCIAL_FOR_LEASE_INDUSTRIAL_TEXT,
    options: [
      { id: INDUSTRIAL_DISTRIBUTION, text: INDUSTRIAL_DISTRIBUTION_TEXT },
      { id: INDUSTRIAL_FLEX, text: INDUSTRIAL_FLEX_TEXT },
      { id: INDUSTRIAL_WAREHOUSE, text: INDUSTRIAL_WAREHOUSE_TEXT },
      { id: INDUSTRIAL_R_AND_D, text: INDUSTRIAL_R_AND_D_TEXT },
      { id: INDUSTRIAL_MANUFACTURING, text: INDUSTRIAL_MANUFACTURING_TEXT },
      { id: INDUSTRIAL_REFRIGERATED_COLD_STORAGE, text: INDUSTRIAL_REFRIGERATED_COLD_STORAGE_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_LEASE_LAND,
    title: COMMERCIAL_FOR_LEASE_LAND_TEXT,
    options: [
      { id: LAND_AGRICULTURAL, text: LAND_AGRICULTURAL_TEXT },
      { id: LAND_RESIDENTIAL, text: LAND_RESIDENTIAL_TEXT },
      { id: LAND_COMMERCIAL, text: LAND_COMMERCIAL_TEXT },
      { id: LAND_INDUSTRIAL, text: LAND_INDUSTRIAL_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_LEASE_SPECIAL_PURPOSE,
    title: COMMERCIAL_FOR_LEASE_SPECIAL_PURPOSE_TEXT,
    options: [
      { id: SPECIAL_PURPOSE_AIRPORT, text: SPECIAL_PURPOSE_AIRPORT_TEXT }
    ]
  }
]

export const CommercialForLeaseSubTypesOptions = [
  ...CommercialForLeaseSubTypes[0].options,
  ...CommercialForLeaseSubTypes[1].options,
  ...CommercialForLeaseSubTypes[2].options,
  ...CommercialForLeaseSubTypes[3].options,
  ...CommercialForLeaseSubTypes[4].options
]

export const CommercialForLeaseSubTypesOptionsMultiselect = []
