import * as actions from './types/actions'
import * as mutations from './types/mutations'

export default {
  [actions.SET_USER]: (
    { commit },
    user
  ) => {
    commit(mutations.SET_USER, user)
  },
  [actions.SET_ACTIVE_TAB]: (
    { commit },
    tabIndex
  ) => {
    commit(mutations.SET_ACTIVE_TAB, tabIndex)
  }
}
