import store from '@/store'
import responsePresenter from '@/FackApi/services/responsePresenter'

export default async function getMessageById (id) {
  await new Promise((resolve) => setTimeout(resolve, 300))
  const activeChatId = store.state.messanger.activeChatId
  const message = store.state.messanger.chatList[activeChatId].messages.find(item => item.id === id)
  const response = responsePresenter({ data: { ...message } })

  return response
}
