import * as users from '@/FackApi/api/Users'
import * as postType from '@/config/post/postType'
import Comment from '@/Model/Comment'
import LoveReaction from '@/FackApi/api/Reactions/Love'
import LikeReaction from '@/FackApi/api/Reactions/Like'
import CareReaction from '@/FackApi/api/Reactions/Care'
import PaulMolive from '@/FackApi/api/Comments/PaulMolive'

export default {
  description: 'Will putin die or will it be killed?',
  pollOptions: [
    { id: 1, value: 'Will be killed by russians', votes: 1 },
    { id: 2, value: 'Will die in the kremlin', votes: 2 },
    { id: 3, value: 'Will be killed by aliens', votes: 6 }
  ],
  type: postType.POLL,
  time: new Date(),
  is_follow: false,
  is_liked: true,
  like_type: LoveReaction.id,
  likes: 12,
  fake: 0,
  reactions: [
    {
      type: { ...LoveReaction },
      count: 6,
      user_list: []
    },
    {
      type: { ...LikeReaction },
      count: 4,
      user_list: []
    },
    {
      type: { ...CareReaction },
      count: 8,
      user_list: []
    }
  ],
  user: users.MikeMaschinoff,
  comments: [
    new Comment({ ...PaulMolive })
  ]
}
