import { Posts as CommonPosts } from '@/FackApi/api/SocialPost'
import { Posts as FriendsPosts } from '@/FackApi/api/FriendsPost'
import { Posts as PeepPosts } from '@/FackApi/api/PeepPost'
import { Posts as BrokeragePosts } from '@/FackApi/api/BrokeragePost'
import * as feedTypes from '@/config/post/feedType'

export const FriendsFeed = FriendsPosts

export const NeighborhoodFeed = CommonPosts

export const BusinessFeed = CommonPosts

export const BrokerageFeed = BrokeragePosts

export const PeepFeed = PeepPosts

export const PostList = {
  [feedTypes.FRIENDS_FEED]: FriendsFeed,
  [feedTypes.NEIGHBORHOOD_FEED]: NeighborhoodFeed,
  [feedTypes.BUSINESS_FEED]: BusinessFeed,
  [feedTypes.BROKERAGE_FEED]: BrokerageFeed,
  [feedTypes.PEEP_FEED]: PeepFeed
}
