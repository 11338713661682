import { is } from 'ramda'

export default class Pagination {
  constructor (entity = {}) {
    this.total = is(Number, entity.total) ? entity.total : 0
    this.currentPage = is(Number, entity.currentPage) ? entity.currentPage : 1
    this.perPage = is(Number, entity.perPage) ? entity.perPage : 10
  }

  get lastPage () {
    return Math.ceil(this.total / this.perPage)
  }

  get loadMore () {
    return this.currentPage !== this.lastPage
  }
}
