export const RESIDENTIAL_FOR_SALE = 1
export const RESIDENTIAL_FOR_RENT = 2
export const COMMERCIAL_FOR_SALE = 3
export const COMMERCIAL_FOR_LEASE = 4
export const COMING_SOON_RESIDENTIAL = 5
export const COMING_SOON_COMMERCIAL = 6
export const PRE_FORECLOSURE_RESIDENTIAL = 7
export const PRE_FORECLOSURE_COMMERCIAL = 8
export const FSBO_RESIDENTIAL_FOR_SALE = 9
export const FSBO_COMMERCIAL_FOR_SALE = 10
export const FIXER_UPPER_FOR_SALE = 11
export const LUXURY_HOMES_FOR_RENT = 12
export const LUXURY_HOMES_FOR_SALE = 13
export const TRIPLE_NET_INVESTMENTS_FOR_SALE = 14
export const CASH_OFFER_LISTINGS = 15
export const NOTE_FOR_SALE = 16
export const VACATION_HOMES_RENTALS = 17
export const RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS = 18
export const AUCTION_STYLE_LISTING = 19
export const ROOM_MATE_FINDER = 20
export const ROOMS_FOR_RENT = 21
export const NEED_WANT = 22

export const RESIDENTIAL_FOR_SALE_TEXT = 'Residential for sale'
export const RESIDENTIAL_FOR_RENT_TEXT = 'Residential for rent single unit'
export const RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS_TEXT = 'Apartments for rent - Apartment building'
export const COMMERCIAL_FOR_SALE_TEXT = 'Commercial for sale'
export const COMMERCIAL_FOR_LEASE_TEXT = 'Commercial for lease'
export const COMING_SOON_RESIDENTIAL_TEXT = 'Coming soon - Residential'
export const COMING_SOON_COMMERCIAL_TEXT = 'Coming soon - Commercial'
export const PRE_FORECLOSURE_RESIDENTIAL_TEXT = 'Pre-Foreclosure - Residential'
export const PRE_FORECLOSURE_COMMERCIAL_TEXT = 'Pre-Foreclosure - Commercial'
export const FSBO_RESIDENTIAL_FOR_SALE_TEXT = 'FSBO Residential for sale'
export const FSBO_COMMERCIAL_FOR_SALE_TEXT = 'FSBO Commercial for sale'
export const FIXER_UPPER_FOR_SALE_TEXT = 'Fixer upper for sale'
export const LUXURY_HOMES_FOR_RENT_TEXT = 'Luxury homes for rent'
export const LUXURY_HOMES_FOR_SALE_TEXT = 'Luxury homes for sale'
export const TRIPLE_NET_INVESTMENTS_FOR_SALE_TEXT = 'Triple net investments for sale'
export const CASH_OFFER_LISTINGS_TEXT = 'iBuyer investors marketplace'
export const NOTE_FOR_SALE_TEXT = 'Nonperforming loans (NPLs)'
export const VACATION_HOMES_RENTALS_TEXT = 'Vacation homes rentals'
export const AUCTION_STYLE_LISTING_TEXT = 'Auction style listing'
export const ROOM_MATE_FINDER_TEXT = 'Roommate finder'
export const ROOMS_FOR_RENT_TEXT = 'Rooms for rent'
export const NEED_WANT_TEXT = 'Need / Want'

export const RealEstatePropertyType = {
  [RESIDENTIAL_FOR_SALE]: RESIDENTIAL_FOR_SALE_TEXT,
  [RESIDENTIAL_FOR_RENT]: RESIDENTIAL_FOR_RENT_TEXT,
  [RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS]: RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS_TEXT,
  [ROOM_MATE_FINDER]: ROOM_MATE_FINDER_TEXT,
  [ROOMS_FOR_RENT]: ROOMS_FOR_RENT_TEXT,
  [COMMERCIAL_FOR_SALE]: COMMERCIAL_FOR_SALE_TEXT,
  [COMMERCIAL_FOR_LEASE]: COMMERCIAL_FOR_LEASE_TEXT,
  // [COMING_SOON_RESIDENTIAL]: COMING_SOON_RESIDENTIAL_TEXT,
  // [COMING_SOON_COMMERCIAL]: COMING_SOON_COMMERCIAL_TEXT,
  [PRE_FORECLOSURE_RESIDENTIAL]: PRE_FORECLOSURE_RESIDENTIAL_TEXT,
  [PRE_FORECLOSURE_COMMERCIAL]: PRE_FORECLOSURE_COMMERCIAL_TEXT,
  [FSBO_RESIDENTIAL_FOR_SALE]: FSBO_RESIDENTIAL_FOR_SALE_TEXT,
  [FSBO_COMMERCIAL_FOR_SALE]: FSBO_COMMERCIAL_FOR_SALE_TEXT,
  // [FIXER_UPPER_FOR_SALE]: FIXER_UPPER_FOR_SALE_TEXT,
  [LUXURY_HOMES_FOR_RENT]: LUXURY_HOMES_FOR_RENT_TEXT,
  [LUXURY_HOMES_FOR_SALE]: LUXURY_HOMES_FOR_SALE_TEXT,
  [TRIPLE_NET_INVESTMENTS_FOR_SALE]: TRIPLE_NET_INVESTMENTS_FOR_SALE_TEXT,
  [CASH_OFFER_LISTINGS]: CASH_OFFER_LISTINGS_TEXT,
  [NOTE_FOR_SALE]: NOTE_FOR_SALE_TEXT,
  [VACATION_HOMES_RENTALS]: VACATION_HOMES_RENTALS_TEXT,
  [AUCTION_STYLE_LISTING]: AUCTION_STYLE_LISTING_TEXT,
  [NEED_WANT]: NEED_WANT_TEXT
}

export const RealEstatePropertyTypeOptions = [
  { value: RESIDENTIAL_FOR_SALE, text: RESIDENTIAL_FOR_SALE_TEXT },
  { value: RESIDENTIAL_FOR_RENT, text: RESIDENTIAL_FOR_RENT_TEXT },
  { value: RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS, text: RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS_TEXT },
  { value: ROOM_MATE_FINDER, text: ROOM_MATE_FINDER_TEXT },
  { value: ROOMS_FOR_RENT, text: ROOMS_FOR_RENT_TEXT },
  { value: COMMERCIAL_FOR_SALE, text: COMMERCIAL_FOR_SALE_TEXT },
  { value: COMMERCIAL_FOR_LEASE, text: COMMERCIAL_FOR_LEASE_TEXT },
  // { value: COMING_SOON_RESIDENTIAL, text: COMING_SOON_RESIDENTIAL_TEXT },
  // { value: COMING_SOON_COMMERCIAL, text: COMING_SOON_COMMERCIAL_TEXT },
  { value: PRE_FORECLOSURE_RESIDENTIAL, text: PRE_FORECLOSURE_RESIDENTIAL_TEXT },
  { value: PRE_FORECLOSURE_COMMERCIAL, text: PRE_FORECLOSURE_COMMERCIAL_TEXT },
  { value: FSBO_RESIDENTIAL_FOR_SALE, text: FSBO_RESIDENTIAL_FOR_SALE_TEXT },
  { value: FSBO_COMMERCIAL_FOR_SALE, text: FSBO_COMMERCIAL_FOR_SALE_TEXT },
  // { value: FIXER_UPPER_FOR_SALE, text: FIXER_UPPER_FOR_SALE_TEXT },
  { value: LUXURY_HOMES_FOR_RENT, text: LUXURY_HOMES_FOR_RENT_TEXT },
  { value: LUXURY_HOMES_FOR_SALE, text: LUXURY_HOMES_FOR_SALE_TEXT },
  { value: TRIPLE_NET_INVESTMENTS_FOR_SALE, text: TRIPLE_NET_INVESTMENTS_FOR_SALE_TEXT },
  { value: NOTE_FOR_SALE, text: NOTE_FOR_SALE_TEXT },
  { value: CASH_OFFER_LISTINGS, text: CASH_OFFER_LISTINGS_TEXT },
  { value: AUCTION_STYLE_LISTING, text: AUCTION_STYLE_LISTING_TEXT },
  { value: NEED_WANT, text: NEED_WANT_TEXT }
]

export const RealEstatePropertyTypeSaleOptions = [
  { value: RESIDENTIAL_FOR_SALE, text: RESIDENTIAL_FOR_SALE_TEXT },
  { value: COMMERCIAL_FOR_SALE, text: COMMERCIAL_FOR_SALE_TEXT },
  { value: COMMERCIAL_FOR_LEASE, text: COMMERCIAL_FOR_LEASE_TEXT },
  // { value: COMING_SOON_RESIDENTIAL, text: COMING_SOON_RESIDENTIAL_TEXT },
  // { value: COMING_SOON_COMMERCIAL, text: COMING_SOON_COMMERCIAL_TEXT },
  { value: PRE_FORECLOSURE_RESIDENTIAL, text: PRE_FORECLOSURE_RESIDENTIAL_TEXT },
  { value: PRE_FORECLOSURE_COMMERCIAL, text: PRE_FORECLOSURE_COMMERCIAL_TEXT },
  { value: FSBO_RESIDENTIAL_FOR_SALE, text: FSBO_RESIDENTIAL_FOR_SALE_TEXT },
  { value: FSBO_COMMERCIAL_FOR_SALE, text: FSBO_COMMERCIAL_FOR_SALE_TEXT },
  // { value: FIXER_UPPER_FOR_SALE, text: FIXER_UPPER_FOR_SALE_TEXT },
  { value: LUXURY_HOMES_FOR_SALE, text: LUXURY_HOMES_FOR_SALE_TEXT },
  { value: TRIPLE_NET_INVESTMENTS_FOR_SALE, text: TRIPLE_NET_INVESTMENTS_FOR_SALE_TEXT },
  { value: NOTE_FOR_SALE, text: NOTE_FOR_SALE_TEXT }
]
