import { is } from 'ramda'

export default class ChatUser {
  constructor (user = {}) {
    this.id = is(Number, user.id) ? user.id : 0
    this.firstName = is(String, user.firstName) ? user.firstName : ''
    this.lastName = is(String, user.lastName) ? user.lastName : ''
    this.username = is(String, user.username) ? user.username : ''
    this.role = is(Number, user.role) ? 1 : 0
    this.time = is(String, user.time) ? user.time : 'Just now'
    this.avatar = is(String, user.avatar) ? user.avatar : require('@/assets/images/user/06.jpg')
    this.status = is(String, user.status) ? user.status : 'status-online'
    this.isFollow = false
  }

  get fullName () {
    return `${this.firstName} ${this.lastName}`
  }
}
