<template>
    <div>
        <div
            class="system-notification-block"
            v-for="(notification, key, index) in notifications"
            :key="notification.id"
            :style="`top: ${index * 60}px`"
        >
            <NotificationBlock :index="index" :notification="notification" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as notificationGetters from '@/store/modules/system-notification/types/getters'
import NotificationBlock from './NotificationBlock'

export default {
  name: 'NotificationsLayout',
  components: {
    NotificationBlock
  },
  computed: {
    ...mapGetters('systemNotification', {
      notifications: notificationGetters.GET_NOTIFICATIONS
    })
  }
}
</script>
