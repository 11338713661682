import moment from 'moment'
import * as users from '@/FackApi/api/Users'

export default {
  image: require('@/assets/images/user/erez.jpeg'),
  message: 'Jesica Parker, it is just example of nested comment, with long text to see how the edit/delete will looks like',
  time: moment(new Date(), 'YYYY-MM-DD H:i:s').add(-3, 'days'),
  user: users.ErezJonathan,
  likes: 0,
  is_liked: false,
  like_type: null,
  reactions: []
}
