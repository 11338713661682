import fetchAll from './fetchAll'
import fetchAllBookmarks from './fetchAllBookmarks'
import create from './create'
import saveDraft from './saveDraft'

export default {
  fetchAll,
  fetchAllBookmarks,
  create,
  saveDraft
}
