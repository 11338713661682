export const RESIDENTIAL_FOR_SALE_HOME = 100

export const RESIDENTIAL_FOR_SALE_HOME_TEXT = ''

export const HOME_SINGLE_FAMILY = 1001
export const HOME_VILLA = 1002
export const HOME_CONDO = 1003
export const HOME_HOUSEBOAT = 1004
export const HOME_TOWNHOUSE = 1005
export const HOME_CO_OP_UNIT = 1006
export const HOME_LOFT = 1007
export const HOME_MOBILE_MANUFACTURED = 1008
export const HOME_VACANT_LAND = 1009
export const HOME_DUPLEX_TRIPLEX_FOURPLEX = 1010
export const HOME_APARTMENT_BUILDING = 1011

export const HOME_SINGLE_FAMILY_TEXT = 'Single family'
export const HOME_VILLA_TEXT = 'Villa'
export const HOME_CONDO_TEXT = 'Condo'
export const HOME_HOUSEBOAT_TEXT = 'Houseboat'
export const HOME_TOWNHOUSE_TEXT = 'Townhouse'
export const HOME_CO_OP_UNIT_TEXT = 'Co-op Unit'
export const HOME_LOFT_TEXT = 'Loft'
export const HOME_MOBILE_MANUFACTURED_TEXT = 'Mobile / Manufactured'
export const HOME_VACANT_LAND_TEXT = 'Vacant Land'
export const HOME_DUPLEX_TRIPLEX_FOURPLEX_TEXT = 'Duplex/Triplex/Fourplex'
export const HOME_APARTMENT_BUILDING_TEXT = 'Appartment Building'

export const ResidentialForSaleSubTypes = [
  {
    id: RESIDENTIAL_FOR_SALE_HOME,
    title: RESIDENTIAL_FOR_SALE_HOME_TEXT,
    options: [
      { id: HOME_SINGLE_FAMILY, text: HOME_SINGLE_FAMILY_TEXT },
      { id: HOME_VILLA, text: HOME_VILLA_TEXT },
      { id: HOME_CONDO, text: HOME_CONDO_TEXT },
      { id: HOME_HOUSEBOAT, text: HOME_HOUSEBOAT_TEXT },
      { id: HOME_TOWNHOUSE, text: HOME_TOWNHOUSE_TEXT },
      { id: HOME_CO_OP_UNIT, text: HOME_CO_OP_UNIT_TEXT },
      { id: HOME_LOFT, text: HOME_LOFT_TEXT },
      { id: HOME_MOBILE_MANUFACTURED, text: HOME_MOBILE_MANUFACTURED_TEXT },
      { id: HOME_VACANT_LAND, text: HOME_VACANT_LAND_TEXT },
      { id: HOME_DUPLEX_TRIPLEX_FOURPLEX, text: HOME_DUPLEX_TRIPLEX_FOURPLEX_TEXT },
      { id: HOME_APARTMENT_BUILDING, text: HOME_APARTMENT_BUILDING_TEXT }
    ]
  }
]

export const ResidentialForSaleSubTypesOptions = [
  ...ResidentialForSaleSubTypes[0].options
]
