import User from '../../Model/User'
import * as faker from 'faker'
import MikeMaschinoff from './Users/MikeMaschinoff'
import YgalEdy from './Users/YgalEdy'

const GroupUsers = {
  11: new User({ id: 11, firstName: 'Barb', lastName: 'Ackue', username: faker.internet.userName() }),
  6: new User({ ...MikeMaschinoff, id: '6' }),
  2: new User({ ...YgalEdy, id: '2' }),
  3: new User({ id: '3', firstName: 'Paul', lastName: 'Molive', username: faker.internet.userName(), avatar: require('@/assets/images/user/02.jpg') }),
  4: new User({ id: '4', firstName: 'Anna', lastName: 'Mull', username: faker.internet.userName(), avatar: require('@/assets/images/user/03.jpg') }),
  5: new User({ id: '5', firstName: 'Paige', lastName: 'Turner', username: faker.internet.userName(), avatar: require('@/assets/images/user/04.jpg') }),
  7: new User({ id: '7', firstName: 'Greta', lastName: 'Life', username: faker.internet.userName(), avatar: require('@/assets/images/user/07.jpg') }),
  8: new User({ id: '8', firstName: 'Sara', lastName: 'Membrit', username: faker.internet.userName(), avatar: require('@/assets/images/user/08.jpg') }),
  9: new User({ id: '9', firstName: 'Pete', lastName: 'Sariya', username: faker.internet.userName(), avatar: require('@/assets/images/user/09.jpg') }),
  10: new User({ id: '10', firstName: 'Pete', lastName: 'Tauchend', username: faker.internet.userName(), avatar: require('@/assets/images/user/10.jpg') })
}

export default GroupUsers
