import store from '@/store'
import responsePresenter from '@/FackApi/services/responsePresenter'

export default async function fetchChatById (id) {
  await new Promise((resolve) => setTimeout(resolve, 300))
  const messengerState = store.state.messanger
  const messages = [ ...messengerState.chatList[id].messages ]
  const messagesSorted = messages.sort((a, b) => {
    return new Date(a.createdAt) - new Date(b.createdAt)
  })

  const response = responsePresenter({ data: {
    messages: messagesSorted,
    info: messengerState.chatList[id].info,
    members: messengerState.chatList[id].members
  } })
  return response
}
