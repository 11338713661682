<template>
  <div :class="'iq-card cursor-pointer' + className" v-b-toggle="`collapsed-widtget-${id}`" role="button">
    <slot name="cardImage"/>
    <div v-if="hasHeaderTitleSlot || hasHeaderActionSlot" :class="'iq-card-header d-flex justify-content-between '+headerClass">
      <div class="iq-header-title">
        <slot name="headerTitle" />
      </div>
      <div class="iq-card-header-toolbar d-flex align-items-center cursor-pointer">
        <slot name="headerAction" />
      </div>
    </div>
    <b-collapse :id="'collapsed-widtget-' +id" class="mb-2">
      <b-card-body :body-class="'iq-card-body ' + bodyClass" v-if="hasBodySlot">
        <slot name="body"/>
      </b-card-body>
    </b-collapse>
    <slot />
    <div v-if="hasFooterSlot" :class="'card-footer' + footerClass">
      <slot name="footer"/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'iq-card-collapsed',
  props: {
    className: { type: String, default: '' },
    bodyClass: { type: String, default: '' },
    headerClass: { type: String, default: '' },
    footerClass: { type: String, default: '' },
    id: null
  },
  mounted () {
  },
  computed: {
    hasHeaderTitleSlot () {
      return !!this.$slots.headerTitle
    },
    hasHeaderActionSlot () {
      return !!this.$slots.headerAction
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    hasFooterSlot () {
      return !!this.$slots.footer
    }
  }
}
</script>
