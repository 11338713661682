import Notification from '@/Model/Notification'

const Notifications = {
  1: new Notification({ id: 1, userName: 'Ygal Edy', userAvatar: require('@/assets/images/user/ygal.jpg'), text: 'sent you a friend request', time: 'new' }),
  2: new Notification({ id: 2, userName: 'Mike Maschinoff', userAvatar: 'https://avatars.githubusercontent.com/u/9008136?v=4', text: 'liked you photo', time: '2 days ago' }),
  3: new Notification({ id: 3, userName: 'Anna Sthesia', userAvatar: require('../../assets/images/user/user-08.jpg'), text: 'started following you', time: '3 days ago' }),
  4: new Notification({ id: 4, userName: 'Bob Frapples', userAvatar: require('../../assets/images/user/user-03.jpg'), text: 'liked you post', time: '4 days ago' })
}

export default Notifications
