import * as mutations from './types/mutations'

export default {
  [mutations.SET_USER]: (state, user) => {
    state.user = user
  },
  [mutations.SET_ACTIVE_TAB]: (state, index) => {
    state.activeTab = index
  }
}
