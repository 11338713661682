import * as faker from 'faker'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/CashOfferSubTypes'
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'
import Listing from '@/Model/Listing'
import AuctionData from '@/Model/RealEstate/AuctionData'

export default {
  52: new Listing({
    id: 52,
    type: propertyTypes.AUCTION_STYLE_LISTING,
    subType: subTypes.HOME_SINGLE_FAMILY,
    address: '585087 East Nathan',
    city: 'Messa',
    state: 'AZ',
    zip: '85215',
    price: 2750000,
    yearBuild: 1912,
    createdAt: '2022-06-30 18:22:32',
    auction: new AuctionData({
      endDate: faker.date.soon(faker.datatype.number(25)),
      startingBid: 2750000,
      reservePrice: 3000000,
      highestBid: 0,
      yourBid: 0,
      bids: 0
    })
  }),
  53: new Listing({
    id: 53,
    type: propertyTypes.AUCTION_STYLE_LISTING,
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    subType: subTypes.HOME_DUPLEX_TRIPLEX_FOURPLEX,
    address: '414 Bryan Street',
    city: 'China Basin',
    state: 'CA',
    zip: '94107',
    price: 2750000,
    createdAt: '2022-06-30 18:22:32',
    status: 3,
    auction: new AuctionData({
      endDate: faker.date.past(faker.datatype.number(25)),
      startingBid: 2750000,
      reservePrice: 3000000,
      highestBid: 2975000,
      yourBid: 0,
      bids: 3
    })
  }),
  54: new Listing({
    id: 54,
    type: propertyTypes.AUCTION_STYLE_LISTING,
    address: '16137 Meadowcrest Road',
    city: 'Sherman Oaks',
    state: 'CA',
    zip: '91403',
    price: 1750000,
    subType: subTypes.HOME_SINGLE_FAMILY,
    createdAt: '2022-06-30 18:22:32',
    auction: new AuctionData({
      endDate: faker.date.soon(faker.datatype.number(25)),
      startingBid: 1750000,
      reservePrice: 2000000,
      highestBid: 2850000,
      yourBid: 2170000,
      bids: 5
    })
  })
}
