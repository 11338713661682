import { PostList } from '@/FackApi/api/PostData'
import * as feedTypes from '@/config/post/feedType'
import responsePresenter from '@/FackApi/services/responsePresenter'

export default async function fetchAll (
  { page = 1, type = feedTypes.BUSINESS_FEED, all = false }
) {
  const posts = PostList[type]
  const perPage = 10
  const offset = (page - 1) * perPage
  const list = posts.slice(offset, offset + perPage)
  const total = Object.keys(posts).length

  return responsePresenter({
    data: list,
    meta: {
      total: total,
      current_page: page,
      per_page: perPage
    }
  })
}
