import BusinessChat from '../../../FackApi/api/BusinessChat'
import AgentsChat from '../../../FackApi/api/AgentsChat'
import GroupUsers from '../../../FackApi/api/GroupUsers'

export default {
  business: BusinessChat,
  friends: {},
  agents: AgentsChat,
  group: GroupUsers
}
