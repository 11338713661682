import PaulMolive from '@/FackApi/api/Comments/PaulMolive'
import TextWithMultipleImages from '@/FackApi/api/Posts/TextWithMultipleImages'
import * as users from '@/FackApi/api/Users'
import Comment from '@/Model/Comment'

export default {
  ...TextWithMultipleImages,
  id: 2,
  time: new Date('December 31, 2018 11:30:25'),
  images: [
    require('../../../assets/images/page-img/p4.jpg'),
    require('../../../assets/images/page-img/p5.jpg')
  ],
  user: users.ErezJonathan,
  comments: [
    new Comment({ ...PaulMolive })
  ]
}
