export const SET_THEME = 'SET_THEME'
export const SET_CREATE_GROUP_CHAT_STATE = 'SET_CREATE_GROUP_CHAT_STATE'
export const SET_ACTIVE_CHAT_ID = 'SET_ACTIVE_CHAT_ID'
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING'
export const SET_CHAT_LIST_LOADING = 'SET_CHAT_LIST_LOADING'
export const SET_CHAT_LOADING = 'SET_CHAT_LOADING'
export const SET_CHAT_LIST = 'SET_CHAT_LIST'
export const SET_ACTIVE_CHAT_INFO = 'SET_ACTIVE_CHAT_INFO'
export const SET_ACTIVE_CHAT_MESSAGES = 'SET_ACTIVE_CHAT_MESSAGES'
export const SET_ACTIVE_CHAT_MEMBERS = 'SET_ACTIVE_CHAT_MEMBERS'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const CREATE_CHAT = 'CREATE_CHAT'
export const SET_EDIT_GROUP_CHAT_STATE = 'SET_EDIT_GROUP_CHAT_STATE'
export const SAVE_CHAT = 'SAVE_CHAT'
export const DELETE_CHAT_BY_ID = 'DELETE_CHAT_BY_ID'
export const CLEAR_CHAT_BY_ID = 'CLEAR_CHAT_BY_ID'
export const SET_EDIT_MEMBERS_GROUP_CHAT_STATE = 'SET_EDIT_MEMBERS_GROUP_CHAT_STATE'
export const DELETE_MESSAGE_BY_ID = 'DELETE_MESSAGE_BY_ID'
export const SET_EDIT_MESSAGE_BY_ID = 'SET_EDIT_MESSAGE_BY_ID'
export const UPDATE_MESSAGE_BY_ID = 'UPDATE_MESSAGE_BY_ID'
export const SET_DRAFT_MESSAGE = 'SET_DRAFT_MESSAGE'
export const SET_DRAFT_HTML_MESSAGE = 'SET_DRAFT_HTML_MESSAGE'
export const SET_REPLY_MESSAGE_BY_ID = 'SET_REPLY_MESSAGE_BY_ID'
export const SET_FORWARD_MESSAGE_BY_ID = 'SET_FORWARD_MESSAGE_BY_ID'
export const FORWARD_MESSAGE = 'FORWARD_MESSAGE'
export const SELECT_MESSAGE_BY_ID = 'SELECT_MESSAGE_BY_ID'
export const CLEAR_SELECTED_MESSAGES = 'CLEAR_SELECTED_MESSAGES'
