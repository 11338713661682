import SimpleText from '@/FackApi/api/Posts/SimpleText'
import Comment from '@/Model/Comment'
import PaulMolive from '@/FackApi/api/Comments/PaulMolive'
import JesicaParker from '@/FackApi/api/Comments/JesicaParker'
import RandomComment from '@/FackApi/api/Comments/RandomComment'
import FakeComment from '@/Faker/Comment'

export default {
  ...SimpleText,
  id: '99999',
  comments: [
    new Comment({ ...PaulMolive }),
    new Comment({ ...JesicaParker }),
    new Comment({ ...RandomComment }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() }),
    new Comment({ ...FakeComment.create() })
  ]
}
