import clearChatById from './clearChatById'
import createChat from './createChat'
import createMessage from './createMessage'
import deleteChatById from './deleteChatById'
import deleteMessageById from './deleteMessageById'
import fetchChatById from './fetchChatById'
import fetchChatList from './fetchChatList'
import forwardMessage from './forwardMessage'
import getMessageById from './getMessageById'
import updateChatById from './updateChatById'
import updateMessageById from './updateMessageById'

export default {
  clearChatById,
  createChat,
  createMessage,
  deleteChatById,
  deleteMessageById,
  fetchChatById,
  fetchChatList,
  forwardMessage,
  getMessageById,
  updateChatById,
  updateMessageById
}
