import { is } from 'ramda'
import * as postType from '@/config/post/postType'
import * as feedType from '@/config/post/feedType'
import * as faker from 'faker'
import * as users from '@/FackApi/api/Users'
import User from '@/Model/User'

export default class Post {
  constructor (post = {}) {
    this.id = is(String, post.id) ? post.id : faker.datatype.uuid()
    this.type = is(Number, post.type) ? post.type : postType.POST
    this.feed_type = is(Number, post.feed_type) ? post.feed_type : feedType.FRIENDS_FEED
    this.images = is(Array, post.images) ? post.images : []
    this.files = is(Array, post.files) ? post.files : []
    this.videos = is(Array, post.videos) ? post.videos : []
    this.description = is(String, post.description) ? post.description : ''
    this.pollOptions = is(Array, post.pollOptions) ? post.pollOptions : []
    this.pollDuration = is(Object, post.pollDuration) ? post.pollDuration : { days: 2, hours: 0, minutes: 0 }
    this.time = is(Date, post.time) ? post.time : new Date()
    this.is_follow = is(Boolean, post.is_follow) ? post.is_follow : false
    this.is_liked = is(Boolean, post.is_liked) ? post.is_liked : false
    this.like_type = is(Number, post.like_type) ? post.like_type : null
    this.likes = is(Number, post.likes) ? post.likes : 0
    this.fake = is(Number, post.fake) ? post.fake : 0
    this.bookmark = is(Number, post.fake) ? post.fake : 0
    this.retweet = is(Number, post.retweet) ? post.retweet : 0
    this.taggedPeople = is(Array, post.taggedPeople) ? post.taggedPeople : []
    /**
     string:id, string:type, string:image, int:count, []users
     */
    this.reactions = is(Array, post.reactions) ? post.reactions : []
    this.user = is(Object, post.user) ? new User(post.user) : users.RandomUser
    this.comments = is(Array, post.comments) ? post.comments : []

    this.is_hidden = is(Boolean, post.is_hidden) ? post.is_hidden : false

    this.property = is(Object, post.property) ? post.property : null
  }
}
