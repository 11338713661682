import TextWithMultipleImages from '@/FackApi/api/Posts/TextWithMultipleImages'
import * as users from '@/FackApi/api/Users'
import Comment from '@/Model/Comment'
import JesicaParker from '@/FackApi/api/Comments/JesicaParker'
import DanielYonathan from '@/FackApi/api/Comments/DanielYonathan'

export default {
  ...TextWithMultipleImages,
  id: 1,
  images: [
    require('../../../assets/images/page-img/p4.jpg'),
    require('../../../assets/images/page-img/p5.jpg'),
    require('../../../assets/images/page-img/p2.jpg')
  ],
  user: users.DanielYonathan,
  comments: [
    new Comment({ ...JesicaParker }),
    new Comment({ ...DanielYonathan })
  ]
}
