import * as faker from 'faker'
import SimpleText from '@/FackApi/api/Posts/SimpleText'
import User from '@/Model/User'
import FakeUser from '@/Faker/User'
import LikeReaction from '@/FackApi/api/Reactions/Like'

export default class FakePost {
  static create () {
    return {
      ...SimpleText,
      id: faker.datatype.uuid(),
      description: faker.lorem.sentences(faker.datatype.number(3, 12)),
      likes: 90,
      time: faker.date.past(faker.datatype.number(25)),
      is_follow: false,
      is_liked: false,
      like_type: LikeReaction.id,
      is_commentLike: true,
      user: new User({ ...FakeUser.create() }),
      comments: []
    }
  }
}
