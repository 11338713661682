export const VACATION_RENTALS = 11000

export const VACATION_RENTALS_TEXT = 'Vacation Rentals'

export const RESORT = 11001
export const TOWNHOUSE = 11002
export const HOTEL = 11003
export const VILLA = 11004
export const GUEST_HOUSE = 11005
export const CARAVAN = 11006
export const CHALET = 11007
export const LODGE = 11008
export const FARMHOUSE = 11009
export const ESTATE = 11010
export const COUNTRY_HOUSE = 11011
export const BARN = 11012
export const BOAT = 11013
export const HOUSEBOAT = 11014
export const YACHT = 11015
export const TOWER = 11016
export const CASTLE = 11017
export const MILL = 11018
export const APARTMENT = 11019
export const HOUSE = 11020
export const CABIN = 11021
export const BEACH_FRONT = 11022
export const SKI_RESORT = 11023
export const CONDO = 11024
export const MENTION = 11025
export const LAKE_FRONT = 11026
export const ISLAND = 11027
export const CAVES = 11028
export const BED_AND_BREAKFAST = 11029

export const RESORT_TEXT = 'Resort'
export const TOWNHOUSE_TEXT = 'Townhouse'
export const HOTEL_TEXT = 'Hotel'
export const VILLA_TEXT = 'Villa'
export const GUEST_HOUSE_TEXT = 'Guest House'
export const CARAVAN_TEXT = 'Caravan'
export const CHALET_TEXT = 'Chalet'
export const LODGE_TEXT = 'Lodge'
export const FARMHOUSE_TEXT = 'Farmhouse'
export const ESTATE_TEXT = 'Estate'
export const COUNTRY_HOUSE_TEXT = 'Country House'
export const BARN_TEXT = 'Barn'
export const BOAT_TEXT = 'Boat'
export const HOUSEBOAT_TEXT = 'Houseboat'
export const YACHT_TEXT = 'Yacht'
export const TOWER_TEXT = 'Tower'
export const CASTLE_TEXT = 'Castle'
export const MILL_TEXT = 'Mill'
export const APARTMENT_TEXT = 'Apartment'
export const HOUSE_TEXT = 'House'
export const CABIN_TEXT = 'Cabin'
export const BEACH_FRONT_TEXT = 'Bachfront'
export const SKI_RESORT_TEXT = 'Ski Resort'
export const CONDO_TEXT = 'Condo'
export const MENTION_TEXT = 'Mention'
export const LAKE_FRONT_TEXT = 'Lakefront'
export const ISLAND_TEXT = 'Island'
export const CAVES_TEXT = 'Cave'
export const BED_AND_BREAKFAST_TEXT = 'Bed & Breakfast'

export const VacationRentalsSubTypes = [
  {
    id: VACATION_RENTALS,
    title: VACATION_RENTALS_TEXT,
    options: [
      { id: RESORT, text: RESORT_TEXT },
      { id: TOWNHOUSE, text: TOWNHOUSE_TEXT },
      { id: HOTEL, text: HOTEL_TEXT },
      { id: VILLA, text: VILLA_TEXT },
      { id: GUEST_HOUSE, text: GUEST_HOUSE_TEXT },
      { id: CARAVAN, text: CARAVAN_TEXT },
      { id: CHALET, text: CHALET_TEXT },
      { id: LODGE, text: LODGE_TEXT },
      { id: FARMHOUSE, text: FARMHOUSE_TEXT },
      { id: ESTATE, text: ESTATE_TEXT },
      { id: COUNTRY_HOUSE, text: COUNTRY_HOUSE_TEXT },
      { id: BARN, text: BARN_TEXT },
      { id: BOAT, text: BOAT_TEXT },
      { id: HOUSEBOAT, text: HOUSEBOAT_TEXT },
      { id: YACHT, text: YACHT_TEXT },
      { id: TOWER, text: TOWER_TEXT },
      { id: CASTLE, text: CASTLE_TEXT },
      { id: MILL, text: MILL_TEXT },
      { id: APARTMENT, text: APARTMENT_TEXT },
      { id: HOUSE, text: HOUSE_TEXT },
      { id: CABIN, text: CABIN_TEXT },
      { id: BEACH_FRONT, text: BEACH_FRONT_TEXT },
      { id: SKI_RESORT, text: SKI_RESORT_TEXT },
      { id: CONDO, text: CONDO_TEXT },
      { id: MENTION, text: MENTION_TEXT },
      { id: LAKE_FRONT, text: LAKE_FRONT_TEXT },
      { id: ISLAND, text: ISLAND_TEXT },
      { id: CAVES, text: CAVES_TEXT },
      { id: BED_AND_BREAKFAST, text: BED_AND_BREAKFAST_TEXT }
    ]
  }
]

export const VacationRentalsSubTypesOptions = [
  ...VacationRentalsSubTypes[0].options
]
