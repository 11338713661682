import { is } from 'ramda'
import * as faker from 'faker'
import Listing from '../Listing'
import User from '../User'

export default class PriceOpinion {
  constructor (data = {}) {
    this.id = is(Number, data.id) ? data.id : faker.datatype.uuid()
    this.listing = is(Object, data.listing) ? data.listing : new Listing()
    this.user = is(Object, data.user) ? data.user : new User()
    this.price = is(Number, data.price) ? data.price : 0
    this.licenceNumber = is(String, data.licenceNumber) ? data.licenceNumber : null
    this.state = is(String, data.state) ? data.state : null
    this.propertyVisitedAt = is(Date, data.propertyVisitedAt) ? data.propertyVisitedAt : new Date()
    this.propertyBasedOn = is(Array, data.propertyBasedOn) ? data.propertyBasedOn : []
    this.createdAt = is(Date, data.createdAt) ? data.createdAt : new Date()
  }
}
