export const CHANGE_THEME = 'CHANGE_THEME'
export const OPEN_CREATE_GROUP_CHAT = 'OPEN_CREATE_GROUP_CHAT'
export const CLOSE_CREATE_GROUP_CHAT = 'CLOSE_CREATE_GROUP_CHAT'
export const FETCH_ACTIVE_CHAT_BY_ID = 'FETCH_ACTIVE_CHAT_BY_ID'
export const FETCH_CHAT_LIST = 'FETCH_CHAT_LIST'
export const UPDATE_SEARCH_STRING = 'UPDATE_SEARCH_STRING'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const CREATE_CHAT = 'CREATE_CHAT'
export const OPEN_EDIT_GROUP_CHAT = 'OPEN_EDIT_GROUP_CHAT'
export const CLOSE_EDIT_GROUP_CHAT = 'CLOSE_EDIT_GROUP_CHAT'
export const SAVE_CHAT = 'SAVE_CHAT'
export const DELETE_CHAT_BY_ID = 'DELETE_CHAT_BY_ID'
export const CLEAR_CHAT_BY_ID = 'CLEAR_CHAT_BY_ID'
export const BLOCK_CHAT_BY_ID = 'BLOCK_CHAT_BY_ID'
export const UNBLOCK_CHAT_BY_ID = 'UNBLOCK_CHAT_BY_ID'
export const OPEN_EDIT_MEMBERS_GROUP_CHAT = 'OPEN_EDIT_MEMBERS_GROUP_CHAT'
export const CLOSE_EDIT_MEMBERS_GROUP_CHAT = 'CLOSE_EDIT_MEMBERS_GROUP_CHAT'
export const DELETE_MESSAGE_BY_ID = 'DELETE_MESSAGE_BY_ID'
export const SET_EDIT_MESSAGE_BY_ID = 'SET_EDIT_MESSAGE_BY_ID'
export const DISCARD_EDIT_MESSAGE = 'DISCARD_EDIT_MESSAGE'
export const UPDATE_MESSAGE_BY_ID = 'UPDATE_MESSAGE_BY_ID'
export const UPDATE_DRAFT_MESSAGE = 'UPDATE_DRAFT_MESSAGE'
export const UPDATE_DRAFT_HTML_MESSAGE = 'UPDATE_DRAFT_HTML_MESSAGE'
export const SET_REPLY_MESSAGE_BY_ID = 'SET_REPLY_MESSAGE_BY_ID'
export const DISCARD_REPLY_MESSAGE = 'DISCARD_REPLY_MESSAGE'
export const SET_FORWARD_MESSAGE_BY_ID = 'SET_FORWARD_MESSAGE_BY_ID'
export const DISCARD_FORWARD_MESSAGE = 'DISCARD_FORWARD_MESSAGE'
export const FORWARD_MESSAGE = 'FORWARD_MESSAGE'
export const SELECT_MESSAGE = 'SELECT_MESSAGE'
export const CLEAR_SELECTED_MESSAGES = 'CLEAR_SELECTED_MESSAGES'
export const BULK_FORWARD_MESSAGE = 'BULK_FORWARD_MESSAGE'
