import * as ResidentialForSaleSubTypes from './ResidentialForSaleSubTypes' // 100
import * as CommercialForSaleSubTypes from './CommercialForSaleSubTypes' // 200
import * as CommercialForLeaseSubTypes from './CommercialForLeaseSubTypes' // 300
import * as LuxuryHomesForRentSubTypes from './LuxuryHomesForRentSubTypes' // 400
import * as LuxuryHomesForSaleSubTypes from './LuxuryHomesForSaleSubTypes' // 500
import * as TripleNetInvestmentForSaleSubTypes from './TripleNetInvestmentsForSaleSubTypes' // 600
import * as ResidentialForRentSubTypes from './ResidentialForRentSubTypes' // 700
import * as NoteForSaleSubTypes from './NoteForSaleSubTypes' // 800
import * as CashOfferSubTypes from './CashOfferSubTypes' // 900
import * as FixerUpperForSaleSubTypes from './FixerUpperForSaleSubTypes' // 1000
import * as VacationRentalsSubTypes from './VacationRentalsSubTypes' // 11000

export const RealEstatePropertySubType = [
  ...ResidentialForSaleSubTypes.ResidentialForSaleSubTypesOptions,
  ...CommercialForSaleSubTypes.CommercialForSaleSubTypesOptions,
  ...CommercialForLeaseSubTypes.CommercialForLeaseSubTypesOptions,
  ...LuxuryHomesForRentSubTypes.LuxuryHomesForRentSubTypesOptions,
  ...LuxuryHomesForSaleSubTypes.LuxuryHomesForSaleSubTypesOptions,
  ...TripleNetInvestmentForSaleSubTypes.TripleNetInvestmentsForSaleSubTypesOptions,
  ...ResidentialForRentSubTypes.ResidentialForRentSubTypesOptions,
  ...NoteForSaleSubTypes.NoteForSaleSubTypesOptions,
  ...CashOfferSubTypes.CashOfferSubTypesOptions,
  ...FixerUpperForSaleSubTypes.FixerUpperForSaleSubTypesOptions,
  ...VacationRentalsSubTypes.VacationRentalsSubTypesOptions
]
