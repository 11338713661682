import moment from 'moment'
import * as users from '@/FackApi/api/Users'

export default {
  image: require('@/assets/images/user/joseph.jpg'),
  message: 'Erez Jonathan, it is just reply to Erez reply',
  time: moment(new Date(), 'YYYY-MM-DD H:i:s').add(-3, 'days'),
  user: users.JosephKaminski,
  likes: 0,
  is_liked: false,
  like_type: null,
  reactions: []
}
