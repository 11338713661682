import { is } from 'ramda'
import * as userStatus from '@/config/messanger/userStatus'

export default class User {
  constructor (user = {}) {
    this.id = is(String, user.id) ? user.id : 0
    this.firstName = is(String, user.firstName) ? user.firstName : ''
    this.lastName = is(String, user.lastName) ? user.lastName : ''
    this.username = is(String, user.username) ? user.username : ''
    this.email = is(String, user.email) ? user.email : ''
    this.avatar = is(String, user.avatar) ? user.avatar : ''
    this.profileImage = is(String, user.profileImage) ? user.profileImage : ''
    this.role = is(String, user.role) ? user.role : ''
    this.about = is(String, user.about) ? user.about : ''
    this.dob = is(String, user.dob) ? user.dob : ''
    this.onlineStatus = is(Number, user.onlineStatus) ? user.onlineStatus : userStatus.ACTIVE
    this.isActive = is(Boolean, user.isActive) ? user.isActive : false
    this.isPrivate = is(Boolean, user.isPrivate) ? user.isPrivate : false
  }

  get fullName () {
    return `${this.firstName} ${this.lastName}`
  }

  get nickname () {
    return `@${this.username}`
  }

  get isBusinessOwner () {
    return this.role === 'Business Owner'
  }

  get isFollow () {
    return false
  }
}
