import Post from '@/Model/Post'
import FakePost from '@/Faker/Post'
import MikeMaschinoffPost from '@/FackApi/api/Posts/MikeMaschinoff'
import JosephKaminskiPost from '@/FackApi/api/Posts/JosephKaminski'
import YgalEdyPost from '@/FackApi/api/Posts/YgalEdy'
import ErezJonathanPost from '@/FackApi/api/Posts/ErezJonathan'
import DanielYonathanPost from '@/FackApi/api/Posts/DanielYonathan'
import PaulMullerPost from '@/FackApi/api/Posts/PaulMuller'
import RandomUserPost from '@/FackApi/api/Posts/RandomUser'

export const Posts = [
  new Post({ ...MikeMaschinoffPost, id: '99999' }),
  new Post({ ...RandomUserPost, id: '6' }),
  new Post({ ...PaulMullerPost, id: '5' }),
  new Post({ ...JosephKaminskiPost, id: '1' }),
  new Post({ ...YgalEdyPost, id: '2' }),
  new Post({ ...ErezJonathanPost, id: '3' }),
  new Post({ ...DanielYonathanPost, id: '4' }),
  new Post({ ...RandomUserPost, id: '7' }),
  new Post({ ...FakePost.create() }),
  new Post({ ...FakePost.create() }),
  new Post({ ...FakePost.create() }),
  new Post({ ...FakePost.create() }),
  new Post({ ...FakePost.create() }),
  new Post({ ...FakePost.create() })
]
