import * as actions from './types/actions'
import * as mutations from './types/mutations'
import * as systemNotifyActions from '@/store/modules/system-notification/types/actions'
import authService from '@/services/auth/authServiceMock'
import router from '@/router'

export default {
  [actions.SIGN_IN]: async (context, loginData) => {
    await authService.signIn(loginData)
  },
  [actions.FETCH_LOGGED_USER]: async ({ commit, dispatch }) => {
    try {
      const fetchUserResponse = await authService.fetchLoggedUser()
      commit(mutations.SET_LOGGED_USER, fetchUserResponse)
    } catch (error) {
      dispatch(actions.CHECK_IF_UNAUTHORIZED, error)
    }
  },
  [actions.SIGN_OUT]: async ({ commit, dispatch }) => {
    try {
      await authService.signOut()
      commit(mutations.USER_LOGOUT)
    } catch (error) {
      dispatch(actions.CHECK_IF_UNAUTHORIZED, error)
    }
  },
  [actions.CHECK_IF_UNAUTHORIZED]: ({ dispatch, state }, error) => {
    if (error.response.status === 401) {
      authService.removeToken()
      state.authUser = null
      router.push({ name: 'metwitt.login' })
      dispatch(
        'notification/' + systemNotifyActions.SET_ERROR_NOTIFICATION,
        'Unauthenticated',
        { root: true }
      )
    }
  }
}
