import store from '@/store'
import Chat from '@/Model/Messanger/Chat'
import User from '@/Model/User'
import Erez from '@/FackApi/api/Users/ErezJonathan'
import responsePresenter from '@/FackApi/services/responsePresenter'

export default async function update (id, data = {}) {
  await new Promise((resolve) => setTimeout(resolve, 300))
  const messengerState = store.state.messanger

  const ErezJonathan = new User({ ...Erez })

  const chat = new Chat({
    ...messengerState.chatList[id],
    ...data,
    creator: new User({ ...ErezJonathan })
  })

  const response = responsePresenter({ data: chat })

  return response
}
