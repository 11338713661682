import * as faker from 'faker'

export default class FakeGroupChat {
  static create ({ title, image, members, creator, isPrivate }) {
    return {
      id: faker.datatype.uuid(),
      creator,
      isGroup: true,
      isPrivate,
      members: members ?? [],
      info: this.info({ title, image, creator }),
      messages: []
    }
  }

  static info ({ title, image, creator }) {
    return {
      title: title ?? faker.company.companyName(),
      description: faker.lorem.sentence(),
      administrators: [
        creator
      ],
      image: image ?? `https://i.pravatar.cc/300?u=${faker.datatype.uuid()}`
    }
  }
}
