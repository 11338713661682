import ChatUser from '../../Model/ChatUser'

const AgentsChat = {
  1: new ChatUser({ id: 1, firstName: 'Pete', lastName: 'Tauchend', role: 1, avatar: require('@/assets/images/user/10.jpg') }),
  2: new ChatUser({ id: 2, firstName: 'Barb', lastName: 'Ackue', role: 1 }),
  3: new ChatUser({ id: 3, firstName: 'Ygal', lastName: 'Edy', role: 1, avatar: require('@/assets/images/user/ygal.jpg') }),
  4: new ChatUser({ id: 4, firstName: 'Pete', lastName: 'Sariya', role: 1, avatar: require('@/assets/images/user/09.jpg') }),
  5: new ChatUser({ id: 5, firstName: 'Paul', lastName: 'Molive', role: 1, avatar: require('@/assets/images/user/02.jpg') }),
  6: new ChatUser({ id: 6, firstName: 'Paige', lastName: 'Turner', role: 1, avatar: require('@/assets/images/user/04.jpg') }),
  7: new ChatUser({ id: 7, firstName: 'Bob', lastName: 'Frapples', role: 1, avatar: require('@/assets/images/user/05.jpg') }),
  8: new ChatUser({ id: 8, firstName: 'Greta', lastName: 'Life', role: 1, avatar: require('@/assets/images/user/07.jpg') }),
  9: new ChatUser({ id: 9, firstName: 'Sara', lastName: 'Membrit', role: 1, avatar: require('@/assets/images/user/08.jpg') }),
  10: new ChatUser({ id: 10, firstName: 'Anna', lastName: 'Mull', role: 1, avatar: require('@/assets/images/user/03.jpg') })
}

export default AgentsChat
