export default {
  id: '6',
  firstName: 'Mike',
  lastName: 'Maschinoff',
  username: 'maschin',
  avatar: 'https://avatars.githubusercontent.com/u/9008136?v=4',
  profileImage: require('@/assets/images/user/profile/mikeProfile.jpg'),
  email: 'maschinoff@zeppit.pro',
  role: 'Business Owner'
}
