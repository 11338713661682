import moment from 'moment'
import * as users from '@/FackApi/api/Users'

export default {
  image: require('@/assets/images/user/user-03.jpg'),
  message: 'Lorem ipsum dolor sit amet, but we are just testing the long text, cause it have issue with the avatar',
  user: users.PaulMolive,
  time: moment(new Date(), 'YYYY-MM-DD H:i:s').add(-2, 'days'),
  likes: 0,
  is_liked: false,
  like_type: null,
  reactions: []
}
