<template>
  <div class="app">
    <!-- <div id="loading">
      <Loader />
    </div> -->
    <NotificationsLayout />
    <router-view/>
  </div>
</template>

<script>
import { socialvue } from './config/pluginInit'
import NotificationsLayout from '@/components/homeiz/system-notifications/NotificationsLayout'
// import Loader from '@/components/socialvue/loader/Loader'

export default {
  name: 'App',
  components: {
    NotificationsLayout
    // Loader
  },
  mounted () {
    socialvue.mainIndex()
  }
}
</script>
<style lang="scss">
    @import "assets/scss/style.scss";
</style>
