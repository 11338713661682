import User from '@/Model/User'
import FakeUser from '@/Faker/User'
import Erez from './Users/ErezJonathan'
import Ygal from './Users/YgalEdy'
import Joseph from './Users/JosephKaminski'
import Daniel from './Users/DanielYonathan'
import Paul from './Users/PaulMuller'
import Mike from './Users/MikeMaschinoff'
import Jesica from './Users/JesicaParker'
import PaulM from './Users/PaulMolive'

export const ErezJonathan = new User({ ...Erez })

export const YgalEdy = new User({ ...Ygal })

export const JosephKaminski = new User({ ...Joseph })

export const DanielYonathan = new User({ ...Daniel })

export const PaulMuller = new User({ ...Paul })

export const MikeMaschinoff = new User({ ...Mike })

export const JesicaParker = new User({ ...Jesica })

export const PaulMolive = new User({ ...PaulM })

export const RandomUser = new User({ ...FakeUser.create() })

export const UserList = {
  [ErezJonathan.id]: ErezJonathan,
  [YgalEdy.id]: YgalEdy,
  [JosephKaminski.id]: JosephKaminski,
  [DanielYonathan.id]: DanielYonathan,
  [PaulMuller.id]: PaulMuller,
  [MikeMaschinoff.id]: MikeMaschinoff,
  [RandomUser.id]: RandomUser
}
