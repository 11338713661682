import * as faker from 'faker'

export default class FakeUser {
  static create () {
    return {
      id: faker.datatype.uuid(),
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      username: faker.internet.userName(),
      avatar: `https://i.pravatar.cc/300?u=${faker.datatype.uuid()}`,
      profileImage: 'https://via.placeholder.com/350x150',
      email: faker.internet.email()
    }
  }
}
