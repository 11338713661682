import * as faker from 'faker'
import User from '@/Model/User'
import FakeChat from '@/Faker/Chat'
import FakeGroupChat from '@/Faker/ChatGroup'
import FakeChatMessage from '@/Faker/ChatMessage'
import Chat from '@/Model/Messanger/Chat'
import ChatMessage from '@/Model/Messanger/ChatMessage'
import Erez from '@/FackApi/api/Users/ErezJonathan'
import Mike from '@/FackApi/api/Users/MikeMaschinoff'
import Ygal from '@/FackApi/api/Users/YgalEdy'
import FakeUser from '@/Faker/User'

const CHAT_COUNT = 22

const ChatLisGenerator = () => {
  const data = []

  data.push(generateHOMEiZGroup())

  for (let i = 0; i < CHAT_COUNT; i++) {
    const chat = i % 4 ? generateChat() : generateGroupChat()
    data.push(chat)
  }

  return data
}

const geterateMessages = (user = {}, count = 0) => {
  const messages = []

  if (!count) {
    count = faker.datatype.number({ min: 10, max: 30 })
  }

  for (let i = 0; i < count; i++) {
    const ErezJonathan = new User({ ...Erez })

    const chatUser = {
      me: { image: ErezJonathan.avatar, fullName: ErezJonathan.fullName, me: true },
      guest: { image: user.avatar, fullName: user.fullName, me: false }
    }

    const creator = faker.random.objectElement(chatUser)

    const message = new ChatMessage({ ...FakeChatMessage.create({ ...creator }, { ...user }) })
    messages.push(message)
  }

  return messages
}

const generateGroupMembers = (count = 0) => {
  const members = []

  if (!count) {
    count = faker.datatype.number({ min: 10, max: 30 })
  }

  for (let i = 0; i < count; i++) {
    const user = new User({ ...FakeUser.create() })
    members.push(user)
  }

  const ErezJonathan = new User({ ...Erez })
  members.push(ErezJonathan)

  return members
}

const geterateGroupMessages = (users = [], count = 0) => {
  const messages = []

  if (!count) {
    count = faker.datatype.number({ min: 10, max: 30 })
  }

  for (let i = 0; i < count; i++) {
    const randomUser = faker.random.arrayElement(users)

    const recipient = new User({ ...users.find(x => x.id !== '1') })

    const creator = randomUser.id === '1'
      ? { image: randomUser.avatar, fullName: randomUser.fullName, me: true }
      : { image: randomUser.avatar, fullName: randomUser.fullName, me: false }

    const message = new ChatMessage({ ...FakeChatMessage.create({ ...creator }, { ...recipient }) })
    messages.push(message)
  }

  return messages
}

const generateHOMEiZGroup = () => {
  const ErezJonathan = new User({ ...Erez })

  const homeizGroup = new Chat({
    ...FakeGroupChat.create({
      title: 'HOMEiZ Introduction',
      image: require('@/assets/images/user/erez.jpeg'),
      isPrivate: true,
      creator: ErezJonathan
    })
  })

  homeizGroup.members.push(new User({ ...Ygal }))
  homeizGroup.members.push(new User({ ...Mike }))
  homeizGroup.members.push(new User({ ...Erez }))

  homeizGroup.messages = geterateGroupMessages(homeizGroup.members)

  return homeizGroup
}

const generateChat = () => {
  const ErezJonathan = new User({ ...Erez })
  const chat = new Chat({ ...FakeChat.create(), creator: ErezJonathan })
  const user = new User({
    avatar: chat.info.image,
    fullName: chat.info.title,
    firstName: chat.info.firstName,
    lastName: chat.info.lastName
  })

  chat.messages = geterateMessages(user)

  return chat
}

const generateGroupChat = () => {
  const members = generateGroupMembers()
  const chat = new Chat({ ...FakeGroupChat.create({ members, creator: faker.random.arrayElement(members) }) })
  chat.messages = geterateGroupMessages(chat.members)
  return chat
}

export const ChatList = ChatLisGenerator()
