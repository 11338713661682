import { is } from 'ramda'

export default class ConnectionRequest {
  constructor (request = {}) {
    this.id = is(Number, request.id) ? request.id : 0
    this.userId = is(Number, request.userId) ? request.userId : 0
    this.userName = is(String, request.userName) ? request.userName : 'UserName'
    this.userAvatar = is(String, request.userName) ? request.userAvatar : require('@/assets/images/user/06.jpg')
    this.mutualConnections = is(Number, request.mutualConnections) ? request.mutualConnections : 0
  }
}
