import { is } from 'ramda'
import * as messageType from '@/config/messanger/messageType'
import User from '../User'

export default class ChatMessage {
  constructor (entity = {}) {
    this.id = is(String, entity.id) ? entity.id : 0
    this.parent = is(Object, entity.parent) ? entity.parent : null
    this.message = is(String, entity.message) ? entity.message : ''
    this.type = is(Number, entity.type) ? entity.type : messageType.TEXT
    this.media = is(Array, entity.media) ? entity.media : []
    this.creator = is(Object, entity.creator) ? entity.creator : null
    this.recipient = is(Object, entity.recipient) ? new User({ ...entity.recipient }) : null
    this.isForward = is(Boolean, entity.isForward) ? entity.isForward : false
    this.isRead = is(Boolean, entity.isRead) ? entity.isRead : false
    this.createdAt = is(Object, entity.createdAt) ? new Date(entity.createdAt) : new Date()
    this.updatedAt = is(Object, entity.updatedAt) ? new Date(entity.updatedAt) : new Date()
  }

  get isEdited () {
    return this.createdAt.getTime() !== this.updatedAt.getTime()
  }

  get messagePreview () {
    if (this.message.length) {
      if (this.message.length > 99) {
        return `${this.message.substring(0, 99)}...`
      } else {
        return `${this.message}`
      }
    }
  }
}
