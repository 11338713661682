export default {
  activePageCommit (state, data) {
    state.activePage = data
  },
  setLangCommit (state, data) {
    state.lang = data
  },
  layoutModeCommit (state, data) {
    state.layoutMode = data
  },
  setSearchCommit (state, data) {
    state.showSearch = data
  },
  setLanguagesCommit (state, data) {
    state.showLanguages = data
  },
  setNotificationsCommit (state, data) {
    state.showNotifications = data
  }
}
