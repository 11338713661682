import * as mutations from './types/mutations'

export default {
  [mutations.SET_NOTIFICATION]: (state, notification) => {
    state.systemNotifications = {
      ...state.systemNotifications,
      [notification.id]: notification
    }
  },

  [mutations.CLOSE_NOTIFICATION]: (state, id) => {
    state.systemNotifications = {
      ...state.systemNotifications,
      [id]: { ...state.systemNotifications[id], showing: false }
    }
  },

  [mutations.REMOVE_NOTIFICATION]: (state, id) => {
    const notifications = { ...state.systemNotifications }
    delete notifications[id]
    state.systemNotifications = notifications
  }
}
