import Listing from '@/Model/Listing'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/VacationRentalsSubTypes'
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'

export default {
  49: new Listing({
    id: 49,
    type: propertyTypes.VACATION_HOMES_RENTALS,
    subType: subTypes.TOWNHOUSE,
    address: '',
    city: 'Lebec',
    state: 'California',
    zip: '',
    price: 99,
    params: [],
    paramsAll: [
      { name: 'type', title: 'House', value: '500 sq. ft.' },
      { name: 'bedroom', title: '1 Bedroom', value: '3 beds, 4 Sleeps' },
      { name: 'bathroom', title: '1 Bathroom', value: '1 Full bath' },
      { name: 'spaces', title: 'Spaces', value: 'Living Room' },
      { name: 'spaces', title: 'Spaces', value: 'Kitchen' },
      { name: 'spaces', title: 'Spaces', value: 'Deck/Pation' }
    ],
    amenities: [
      { name: 'sea-view', title: 'Sea View' },
      { name: 'mountain-view', title: 'Mountain View' },
      { name: 'kitchen', title: 'Kitchen' },
      { name: 'parking', title: 'Free Parking on premises' },
      { name: 'tv', title: 'TV' },
      { name: 'backyard', title: 'Backyard' },
      { name: 'resort-view', title: 'Resort View' },
      { name: 'wifi', title: 'Wifi' },
      { name: 'pets', title: 'Pets allowed' },
      { name: 'air-conditioning', title: 'Portable air conditioning' },
      { name: 'hair-dryer', title: 'Hair dryer' }
    ],
    yearBuild: 1912,
    status: 2,
    createdAt: '2022-06-30 18:22:32'
  }),
  50: new Listing({
    id: 50,
    type: propertyTypes.VACATION_HOMES_RENTALS,
    subType: subTypes.BED_AND_BREAKFAST,
    address: '',
    city: 'Topanga',
    state: 'California',
    zip: '',
    price: 50,
    params: [],
    paramsAll: [
      { name: 'type', title: 'House', value: '500 sq. ft.' },
      { name: 'bedroom', title: '1 Bedroom', value: '3 beds, 4 Sleeps' },
      { name: 'bathroom', title: '1 Bathroom', value: '1 Full bath' },
      { name: 'spaces', title: 'Spaces', value: 'Living Room' },
      { name: 'spaces', title: 'Spaces', value: 'Kitchen' },
      { name: 'spaces', title: 'Spaces', value: 'Deck/Pation' }
    ],
    createdAt: '2022-06-30 18:22:32'
  }),
  51: new Listing({
    id: 51,
    type: propertyTypes.VACATION_HOMES_RENTALS,
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    address: '',
    city: 'Twin Peaks',
    state: 'California',
    zip: '',
    price: 120,
    params: [],
    paramsAll: [
      { name: 'type', title: 'House', value: '500 sq. ft.' },
      { name: 'bedroom', title: '1 Bedroom', value: '3 beds, 4 Sleeps' },
      { name: 'bathroom', title: '1 Bathroom', value: '1 Full bath' },
      { name: 'spaces', title: 'Spaces', value: 'Living Room' },
      { name: 'spaces', title: 'Spaces', value: 'Kitchen' },
      { name: 'spaces', title: 'Spaces', value: 'Deck/Pation' }
    ],
    subType: subTypes.HOTEL,
    createdAt: '2022-06-30 18:22:32'
  }),
  55: new Listing({
    id: 55,
    type: propertyTypes.VACATION_HOMES_RENTALS,
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    address: '',
    city: 'Pine Mountains',
    state: 'California',
    zip: '',
    price: 20,
    params: [],
    paramsAll: [
      { name: 'type', title: 'House', value: '500 sq. ft.' },
      { name: 'bedroom', title: '1 Bedroom', value: '3 beds, 4 Sleeps' },
      { name: 'bathroom', title: '1 Bathroom', value: '1 Full bath' },
      { name: 'spaces', title: 'Spaces', value: 'Living Room' },
      { name: 'spaces', title: 'Spaces', value: 'Kitchen' },
      { name: 'spaces', title: 'Spaces', value: 'Deck/Pation' }
    ],
    amenities: [
      { name: 'mountain-view', title: 'Mountain View' },
      { name: 'kitchen', title: 'Kitchen' },
      { name: 'parking', title: 'Free Parking on premises' },
      { name: 'tv', title: 'TV' },
      { name: 'backyard', title: 'Backyard' },
      { name: 'resort-view', title: 'Resort View' },
      { name: 'wifi', title: 'Wifi' },
      { name: 'pets', title: 'Pets allowed' },
      { name: 'air-conditioning', title: 'Portable air conditioning' },
      { name: 'hair-dryer', title: 'Hair dryer' }
    ],
    subType: subTypes.HOTEL,
    createdAt: '2022-06-30 18:22:32'
  }),
  56: new Listing({
    id: 56,
    type: propertyTypes.VACATION_HOMES_RENTALS,
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    address: '',
    city: 'Frazier Park',
    state: 'California',
    zip: '',
    price: 70,
    params: [],
    paramsAll: [
      { name: 'type', title: 'House', value: '500 sq. ft.' },
      { name: 'bedroom', title: '1 Bedroom', value: '3 beds, 4 Sleeps' },
      { name: 'bathroom', title: '1 Bathroom', value: '1 Full bath' },
      { name: 'spaces', title: 'Spaces', value: 'Living Room' },
      { name: 'spaces', title: 'Spaces', value: 'Kitchen' },
      { name: 'spaces', title: 'Spaces', value: 'Deck/Pation' }
    ],
    subType: subTypes.HOTEL,
    createdAt: '2022-06-30 18:22:32'
  }),
  57: new Listing({
    id: 57,
    type: propertyTypes.VACATION_HOMES_RENTALS,
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    address: '',
    city: 'Big Lake',
    state: 'California',
    zip: '',
    price: 87,
    params: [],
    paramsAll: [
      { name: 'type', title: 'House', value: '500 sq. ft.' },
      { name: 'bedroom', title: '1 Bedroom', value: '3 beds, 4 Sleeps' },
      { name: 'bathroom', title: '1 Bathroom', value: '1 Full bath' },
      { name: 'spaces', title: 'Spaces', value: 'Living Room' },
      { name: 'spaces', title: 'Spaces', value: 'Kitchen' },
      { name: 'spaces', title: 'Spaces', value: 'Deck/Pation' }
    ],
    subType: subTypes.HOTEL,
    createdAt: '2022-06-30 18:22:32'
  }),
  58: new Listing({
    id: 58,
    type: propertyTypes.VACATION_HOMES_RENTALS,
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    address: '',
    city: 'Hollywood',
    state: 'California',
    zip: '',
    price: 75,
    params: [],
    paramsAll: [
      { name: 'type', title: 'House', value: '500 sq. ft.' },
      { name: 'bedroom', title: '1 Bedroom', value: '3 beds, 4 Sleeps' },
      { name: 'bathroom', title: '1 Bathroom', value: '1 Full bath' },
      { name: 'spaces', title: 'Spaces', value: 'Living Room' },
      { name: 'spaces', title: 'Spaces', value: 'Kitchen' },
      { name: 'spaces', title: 'Spaces', value: 'Deck/Pation' }
    ],
    subType: subTypes.HOTEL,
    createdAt: '2022-06-30 18:22:32'
  })
}
