import store from '@/store'
import responsePresenter from '@/FackApi/services/responsePresenter'

export default async function deleteMessageById (id) {
  await new Promise((resolve) => setTimeout(resolve, 300))
  const activeChatId = store.state.messanger.activeChatId
  const response = responsePresenter({ data: { messageId: id, chatId: activeChatId } })

  return response
}
