import Listing from '@/Model/Listing'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/CommercialForSaleSubTypes'
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'

export default {
  28: new Listing({
    id: 28,
    type: propertyTypes.FSBO_COMMERCIAL_FOR_SALE,
    subType: subTypes.RETAIL_GROCERY_STORE,
    params: [
      { name: 'cap-rate', value: '4.70% Cap Rate' },
      { name: 'sqft', value: '9,154 SF' },
      { name: 'units', value: '8 Unit Apartment Building' },
      { name: 'year-built', value: 'Built in 2022' }
    ],
    address: '1613 West Boulevard',
    city: 'Mid city',
    state: 'CA',
    zip: '90019',
    price: 385000,
    yearBuild: 1912,
    status: 2,
    createdAt: '2022-06-30 18:22:32'
  }),
  29: new Listing({
    id: 29,
    type: propertyTypes.FSBO_COMMERCIAL_FOR_SALE,
    subType: subTypes.RETAIL_GROCERY_STORE,
    params: [
      { name: 'cap-rate', value: '3.20% Cap Rate' },
      { name: 'sqft', value: '10,154 SF' },
      { name: 'units', value: '8 Unit Apartment Building' },
      { name: 'year-built', value: 'Built in 2012' }
    ],
    price: 500000,
    createdAt: '2022-06-30 18:22:32'
  }),
  30: new Listing({
    id: 30,
    type: propertyTypes.FSBO_COMMERCIAL_FOR_SALE,
    subType: subTypes.RETAIL_GROCERY_STORE,
    params: [
      { name: 'cap-rate', value: '5.20% Cap Rate' },
      { name: 'sqft', value: '6,154 SF' },
      { name: 'units', value: '8 Unit Apartment Building' },
      { name: 'year-built', value: 'Built in 2021' }
    ],
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    price: 200000,
    createdAt: '2022-06-30 18:22:32'
  })
}
