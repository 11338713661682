import * as faker from 'faker'
import FakeUser from '@/Faker/User'
import User from '@/Model/User'

export default {
  image: require('@/assets/images/user/user-04.jpg'),
  message: faker.lorem.sentences(6),
  time: faker.date.past(faker.datatype.number(25)),
  user: new User({ ...FakeUser.create() }),
  replies: [ ]
}
