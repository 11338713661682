import PriceOpinion from '@/Model/RealEstate/PriceOpinion'
import Listing from '@/Model/Listing'
import * as faker from 'faker'
import User from '@/Model/User'
import FakeUser from '@/Faker/User'
import Erez from '@/FackApi/api/Users/ErezJonathan'
import Ygal from '@/FackApi/api//Users/YgalEdy'

export default {
  1: new PriceOpinion({
    id: 1,
    listing: new Listing(),
    user: new User({ ...Erez }),
    price: 204300,
    licenceNumber: faker.datatype.number({ min: 10000000, max: 99999999 }).toString(),
    state: 'California',
    propertyVisitedAt: faker.date.past(faker.datatype.number(25)),
    propertyBasedOn: ['Location', 'Design', 'Parking', 'Future Development Opportunity', 'Size and Layout', 'Land Size'],
    createdAt: faker.date.past(faker.datatype.number(10))
  }),
  2: new PriceOpinion({
    id: 2,
    listing: new Listing(),
    user: new User({ ...Ygal }),
    price: 241300,
    licenceNumber: faker.datatype.number({ min: 10000000, max: 99999999 }).toString(),
    state: 'Maryland',
    propertyVisitedAt: faker.date.past(faker.datatype.number(25)),
    propertyBasedOn: ['Future Development Opportunity', 'Size and Layout', 'Land Size'],
    createdAt: faker.date.past(faker.datatype.number(10))
  }),
  3: new PriceOpinion({
    id: 3,
    listing: new Listing(),
    user: new User({ ...FakeUser.create() }),
    price: 21430,
    licenceNumber: faker.datatype.number({ min: 10000000, max: 99999999 }).toString(),
    state: 'Arizona',
    propertyVisitedAt: faker.date.past(faker.datatype.number(25)),
    propertyBasedOn: ['Location', 'Design', 'Parking'],
    createdAt: faker.date.past(faker.datatype.number(10))
  })
}
