import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import chatPanel from './modules/chat-panel'
import connectionsRequest from './modules/connections-request'
import company from './modules/company'
import messanger from './modules/messanger'
import notifications from './modules/notifications'
import settings from './modules/settings'
import feed from './modules/feed'
import loader from './modules/loader'
import systemNotification from './modules/system-notification'
import realEstateProperties from './modules/real-estate-properties'
import propertyPriceOpinions from './modules/property-price-opinions'
import userFollowers from './modules/user-followers'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    chatPanel,
    company,
    connectionsRequest,
    feed,
    loader,
    messanger,
    notifications,
    propertyPriceOpinions,
    realEstateProperties,
    settings,
    systemNotification,
    userFollowers
  },
  strict: debug,
  plugins: []
})
