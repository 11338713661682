import * as actions from './types/actions'
import companyService from '@/FackApi/services/company/services'
import {
  SET_COMPANY,
  SET_COMPANY_ID,
  SET_COMPANY_OWNER_ID,
  SET_COMPANY_ACTION_BUTTON
} from './types/mutations'

export default {
  [actions.FETCH_COMPANY_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await companyService.getById(id)
      commit(SET_COMPANY, response?.data?.data)
      commit(SET_COMPANY_ID, response?.data?.data)
      commit(SET_COMPANY_OWNER_ID, response?.data?.data)
    } catch (error) {
      console.log(error)
    }
  },
  [actions.FETCH_COMPANY_ACTION_BUTTON]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await companyService.getCompanyActionButton(id)
      commit(SET_COMPANY_ACTION_BUTTON, response?.data?.data)
    } catch (error) {
      console.log(error)
    }
  },
  [actions.ADD_COMPANY_ACTION_BUTTON]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit(SET_COMPANY_ACTION_BUTTON, data)
    } catch (error) {
      console.log(error)
    }
  },
  [actions.DELETE_COMPANY_ACTION_BUTTON]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(SET_COMPANY_ACTION_BUTTON, null)
    } catch (error) {
      console.log(error)
    }
  }
}
