import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs'
import authService from '@/services/auth/authServiceMock'

/* Layouts */
const LayoutPeep = () => import('@/layouts/LayoutPeep')
const LayoutFluid = () => import('@/layouts/LayoutFluid')
const AuthLayout = () => import('@/layouts/AuthLayout')
const Default = () => import('@/layouts/BlankLayout')

const Login = () => import('@/views/Apps/LoginPage')

const PeepsFeed = () => import('@/views/Apps/Social/PeepsFeed')

const PeepBookmarks = () => import('@/views/Apps/Social/PeepBookmarks')

const InterestGroups = () => import('@/views/Apps/Social/AllGroups')
const MessangerPage = () => import('@/views/Apps/Social/MessangerPage')
const MessangerPageDev = () => import('@/views/Apps/Social/MessangerPageDev')

const ProfileEdit = () => import('@/views/Apps/Social/Profile/ProfileEdit')
const ProfileView = () => import('@/views/Apps/Social/Profile/ProfileView')

const ErrorPage = () => import('@/views/Apps/Social/ErrorPage')

const ComingSoonPage = () => import('@/views/Apps/Social/ComingSoon')

const GridExample = () => import('@/views/Apps/Examples/GridExample')

Vue.use(VueRouter)

const errorsChildRoutes = (prop, mode = false) => [
  {
    path: '/error/:code',
    name: prop + '.error',
    meta: { auth: false },
    component: ErrorPage
  }
]

const comingSoonChildRoutes = (prop, mode = false) => [
  {
    path: '/coming-soon',
    name: prop + '.coming-soon',
    meta: { auth: true },
    component: ComingSoonPage
  }
]

const childRoutes = (prop, mode) => [
  {
    path: '/',
    name: prop + '.feed',
    meta: { auth: true, name: 'MeTwitt' },
    component: PeepsFeed
  },
  {
    path: '/bookmarks',
    name: prop + '.bookmarks',
    meta: { auth: true, name: 'MeTwitt' },
    component: PeepBookmarks
  },
  {
    path: '/profiles/my/edit',
    name: prop + '.profile.my.edit',
    meta: { auth: true, name: 'MeTwitt - Edit profile' },
    component: ProfileEdit
  },
  {
    path: '/profiles/my',
    name: prop + '.profile.my',
    meta: { auth: true, name: 'MeTwitt - View profile' },
    component: ProfileView
  },
  {
    path: '/profiles/:id',
    name: prop + '.profiles.view',
    meta: { auth: true, name: 'MeTwitt - View profile' },
    component: ProfileView
  },
  {
    path: '/groups/',
    name: prop + '.societies',
    meta: { auth: true, name: 'MeTwitt - Interest groups' },
    component: InterestGroups
  },
  {
    path: '/grid-layout/',
    name: prop + '.grid-layout',
    meta: { auth: true, name: 'MeTwitt - Grid Layout' },
    component: GridExample
  }
]

const childRoutesFluid = (prop, mode) => [
  {
    path: '/messanger-layout/',
    name: prop + '.messenger-layout',
    meta: { auth: true, name: 'MeTwitt - Messanger' },
    component: MessangerPage
  },
  {
    path: '/messanger/',
    name: prop + '.messenger',
    meta: { auth: true, name: 'MeTwitt - Messanger' },
    component: MessangerPageDev
  }
]

const authChildRoutes = (prop, mode = false) => [
  {
    path: '/login',
    name: prop + '.login',
    meta: { auth: false, name: 'Login page' },
    component: Login
  }
]

const routes = [
  {
    path: '/',
    name: 'social',
    component: LayoutPeep,
    children: childRoutes('social')
  },
  {
    path: '/',
    name: 'fluid',
    component: LayoutFluid,
    children: childRoutesFluid('messanger')
  },
  {
    path: '/login',
    name: 'metwitt',
    component: AuthLayout,
    children: authChildRoutes('metwitt')
  },
  {
    path: '/error',
    name: 'errors',
    component: Default,
    children: errorsChildRoutes('errors')
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: Default,
    children: comingSoonChildRoutes('page')
  },
  {
    path: '*',
    redirect: { name: 'errors.error', params: { code: '404' } }
  }
]

const router = new VueRouter({
  linkExactActiveClass: 'homeiz-link-active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  parseQuery (query) {
    return qs.parse(query)
  },
  stringifyQuery (query) {
    let result = qs.stringify(query, { encode: false })

    return result ? '?' + result : ''
  }
})

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some(record => record.meta.auth)
  const loggedIn = authService.getToken()

  if (authRequired && !loggedIn) {
    next({ name: 'metwitt.login' })
  } else {
    next()
  }
  next()
})

export default router
