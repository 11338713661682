import * as faker from 'faker'
import User from '@/Model/User'
import Erez from '@/FackApi/api/Users/ErezJonathan'
import Chat from '@/Model/Messanger/Chat'
import ChatMessage from '@/Model/Messanger/ChatMessage'
import responsePresenter from '@/FackApi/services/responsePresenter'
import moment from 'moment'

export default async function create (data) {
  await new Promise((resolve) => setTimeout(resolve, 300))
  data.id = faker.datatype.uuid()
  const ErezJonathan = new User({ ...Erez })
  const date = moment().utc().format()
  const chatApiData = {
    id: faker.datatype.uuid(),
    creator: ErezJonathan,
    messages: [
      new ChatMessage({
        id: faker.datatype.uuid(),
        creator: { image: ErezJonathan.avatar, me: true, fullName: ErezJonathan.fullName },
        message: `${ErezJonathan.fullName} created group ${data.info.title}`,
        isRead: true,
        createdAt: date,
        updatedAt: date
      })
    ]
  }

  data.members.push(ErezJonathan)
  const chat = new Chat({
    ...data,
    ...chatApiData
  })

  const response = responsePresenter({ data: chat })

  return response
}
