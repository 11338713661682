import * as users from '@/FackApi/api/Users'
import HahaReaction from '@/FackApi/api/Reactions/Haha'
import JesicaParker from '@/FackApi/api/Comments/JesicaParker'
import PaulMolive from '@/FackApi/api/Comments/PaulMolive'
import Comment from '@/Model/Comment'

export default {
  description: 'Post created by the PostModelConstructor Ipsum is simply dummy text of the printing and typesetting industry.',
  images: [
    require('../../../assets/images/page-img/p1.jpg')
  ],
  time: new Date('October 12, 2021 17:42:25'),
  is_follow: false,
  is_liked: true,
  like_type: HahaReaction.id,
  likes: 12,
  fake: 2,
  reactions: [
    {
      type: { ...HahaReaction },
      count: 6,
      user_list: []
    }
  ],
  user: users.JosephKaminski,
  comments: [
    new Comment({ ...JesicaParker }),
    new Comment({ ...PaulMolive })
  ]
}
