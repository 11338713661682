import TextWithMultipleImages from '@/FackApi/api/Posts/TextWithMultipleImages'
import * as users from '@/FackApi/api/Users'

export default {
  ...TextWithMultipleImages,
  images: [
    require('../../../assets/images/page-img/p5.jpg'),
    require('../../../assets/images/page-img/59.jpg'),
    require('../../../assets/images/page-img/56.jpg'),
    require('../../../assets/images/page-img/p2.jpg')
  ],
  id: 4,
  user: users.PaulMuller
}
