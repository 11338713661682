import * as actions from './types/actions'
import * as mutations from './types/mutations'
import { v4 as uuidv4 } from 'uuid'

export default {
  [actions.SET_ERROR_NOTIFICATION]: async ({ commit }, payload) => {
    commit(mutations.SET_NOTIFICATION, {
      id: uuidv4(),
      showing: true,
      header: payload.header ? payload.header : null,
      text: payload.text ? payload.text : payload,
      typeSnackbar: 'error',
      timeout: true
    })
  },

  [actions.SET_INFO_NOTIFICATION]: async ({ commit }, payload) => {
    commit(mutations.SET_NOTIFICATION, {
      id: uuidv4(),
      showing: true,
      header: payload.header ? payload.header : null,
      text: payload.text ? payload.text : payload,
      typeSnackbar: 'info',
      timeout: true
    })
  },

  [actions.SET_SUCCESS_NOTIFICATION]: async ({ commit }, payload) => {
    commit(mutations.SET_NOTIFICATION, {
      id: uuidv4(),
      showing: true,
      header: payload.header ? payload.header : null,
      text: payload.text ? payload.text : payload,
      typeSnackbar: 'success',
      timeout: true
    })
  },
  [actions.SET_DONE_NOTIFICATION]: async ({ commit }, payload) => {
    commit(mutations.SET_NOTIFICATION, {
      id: uuidv4(),
      showing: true,
      header: payload.header ? payload.header : null,
      text: payload.text ? payload.text : payload,
      typeSnackbar: 'success',
      timeout: false
    })
  },
  [actions.REMOVE_NOTIFICATION]: async ({ commit }, id) => {
    commit(mutations.CLOSE_NOTIFICATION, id)
    commit(mutations.REMOVE_NOTIFICATION, id)
  }
}
