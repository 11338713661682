import * as mutations from './types/mutations'
import PriceOpinion from '@/Model/RealEstate/PriceOpinion'

export default {
  [mutations.CREATE_PRICE_OPINION]: (state, data) => {
    const opinions = { ...state.opinions }

    state.opinions = {
      ...opinions,
      [data.id]: new PriceOpinion(data)
    }

    state.opinionsMap.slice().unshift(data.id)
  }
}
