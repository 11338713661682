import * as getters from './types/getters'

export default {
  [getters.GET_PEEP_BOOKMARKS]: state => state.peepBookmarks,
  [getters.GET_PEEP_BOOKMARKS_MAP]: state => state.peepBookmarksMap,
  [getters.GET_POST_BOOKMARKS]: state => state.postsBookmarks,
  [getters.GET_POST_BOOKMARKS_MAP]: state => state.postsBookmarksMap,
  [getters.GET_DRAFT_POST]: state => state.draftPost,
  [getters.GET_POSTS]: state => state.posts,
  [getters.GET_POSTS_MAP]: state => state.postsMap,
  [getters.GET_PAGINATION]: state => state.pagination,
  [getters.GET_CREATE_PEEP_MODAL_STATE]: state => state.createPeepModalState
}
