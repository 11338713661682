export const COMMERCIAL_FOR_SALE_RETAIL = 200
export const COMMERCIAL_FOR_SALE_MULTIFAMILY = 201
export const COMMERCIAL_FOR_SALE_OFFICE = 202
export const COMMERCIAL_FOR_SALE_INDUSTRIAL = 203
export const COMMERCIAL_FOR_SALE_HOSPITALITY = 204
export const COMMERCIAL_FOR_SALE_LAND = 205
export const COMMERCIAL_FOR_SALE_SPECIAL_PURPOSE = 206

export const COMMERCIAL_FOR_SALE_RETAIL_TEXT = 'Retail'
export const COMMERCIAL_FOR_SALE_MULTIFAMILY_TEXT = 'Multifamily'
export const COMMERCIAL_FOR_SALE_OFFICE_TEXT = 'Office'
export const COMMERCIAL_FOR_SALE_INDUSTRIAL_TEXT = 'Industrial'
export const COMMERCIAL_FOR_SALE_HOSPITALITY_TEXT = 'Hospitality'
export const COMMERCIAL_FOR_SALE_LAND_TEXT = 'Land'
export const COMMERCIAL_FOR_SALE_SPECIAL_PURPOSE_TEXT = 'Special Purpose'

export const RETAIL_BANK = 2001
export const RETAIL_CONVENIENCE_STORE = 2002
export const RETAIL_DAY_CARE_NURSERY = 2003
export const RETAIL_QSR_FAST_FOOD = 2004
export const RETAIL_GAS_STATION = 2005
export const RETAIL_GROCERY_STORE = 2006
export const RETAIL_PHARMACY_DRUG = 2007
export const RETAIL_RESTAURANT = 2008
export const RETAIL_BAR = 2009
export const RETAIL_STOREFRONT = 2010
export const RETAIL_SHOPPING_CENTER = 2011
export const RETAIL_AUTO_SHOP = 2012

export const RETAIL_BANK_TEXT = 'Bank'
export const RETAIL_CONVENIENCE_STORE_TEXT = 'Convenience Store'
export const RETAIL_DAY_CARE_NURSERY_TEXT = 'Day Care/Nursery'
export const RETAIL_QSR_FAST_FOOD_TEXT = 'QSR/Fast Food'
export const RETAIL_GAS_STATION_TEXT = 'Gas Station'
export const RETAIL_GROCERY_STORE_TEXT = 'Grocery Store'
export const RETAIL_PHARMACY_DRUG_TEXT = 'Pharmacy Drug'
export const RETAIL_RESTAURANT_TEXT = 'Reustarant'
export const RETAIL_BAR_TEXT = 'Bar'
export const RETAIL_STOREFRONT_TEXT = 'Storefront'
export const RETAIL_SHOPPING_CENTER_TEXT = 'Shoping Center'
export const RETAIL_AUTO_SHOP_TEXT = 'Auto Shop'

export const MULTIFAMILY_STUDENT_HOUSING = 2013
export const MULTIFAMILY_RV_PARK = 2014
export const MULTIFAMILY_APARTMENT_BUILDING = 2015
export const MULTIFAMILY_CONDOMINIUMS = 2016
export const MULTIFAMILY_MIXED_USE = 2017
export const MULTIFAMILY_AGE_RESTRICTED = 2018

export const MULTIFAMILY_STUDENT_HOUSING_TEXT = 'Student Housing'
export const MULTIFAMILY_RV_PARK_TEXT = 'RV Park'
export const MULTIFAMILY_APARTMENT_BUILDING_TEXT = 'Apartment Building'
export const MULTIFAMILY_CONDOMINIUMS_TEXT = 'Condominiums'
export const MULTIFAMILY_MIXED_USE_TEXT = 'Mixed Use'
export const MULTIFAMILY_AGE_RESTRICTED_TEXT = 'Age Restricted'

export const OFFICE_TRADITIONAL_OFFICE = 2019
export const OFFICE_EXECUTIVE_OFFICE = 2020
export const OFFICE_MEDICAL_OFFICE = 2021
export const OFFICE_CREATIVE_OFFICE = 2022

export const OFFICE_TRADITIONAL_OFFICE_TEXT = 'Traditional Office'
export const OFFICE_EXECUTIVE_OFFICE_TEXT = 'Excecutive Office'
export const OFFICE_MEDICAL_OFFICE_TEXT = 'Medical Office'
export const OFFICE_CREATIVE_OFFICE_TEXT = 'Creative Office'

export const INDUSTRIAL_DISTRIBUTION = 2023
export const INDUSTRIAL_FLEX = 2024
export const INDUSTRIAL_WAREHOUSE = 2025
export const INDUSTRIAL_R_AND_D = 2026
export const INDUSTRIAL_MANUFACTURING = 2027
export const INDUSTRIAL_REFRIGERATED_COLD_STORAGE = 2028

export const INDUSTRIAL_DISTRIBUTION_TEXT = 'Distribution'
export const INDUSTRIAL_FLEX_TEXT = 'Flex'
export const INDUSTRIAL_WAREHOUSE_TEXT = 'Warehouse'
export const INDUSTRIAL_R_AND_D_TEXT = 'R&D'
export const INDUSTRIAL_MANUFACTURING_TEXT = 'Manufacturing'
export const INDUSTRIAL_REFRIGERATED_COLD_STORAGE_TEXT = 'Refrigerated/Cold Storage'

export const HOSPITALITY_HOTEL = 2029
export const HOSPITALITY_MOTEL = 2030
export const HOSPITALITY_CASINO = 2031

export const HOSPITALITY_HOTEL_TEXT = 'Hotel'
export const HOSPITALITY_MOTEL_TEXT = 'Motel'
export const HOSPITALITY_CASINO_TEXT = 'Casino'

export const LAND_AGRICULTURAL = 2032
export const LAND_RESIDENTIAL = 2033
export const LAND_COMMERCIAL = 2034
export const LAND_INDUSTRIAL = 2035
export const LAND_ISLANDS = 2036
export const LAND_FARM = 2037
export const LAND_RANCH = 2038
export const LAND_TIMBER = 2039
export const LAND_HUNTING_RECREATIONAL = 2040

export const LAND_AGRICULTURAL_TEXT = 'Agricultural'
export const LAND_RESIDENTIAL_TEXT = 'Residential'
export const LAND_COMMERCIAL_TEXT = 'Commercial'
export const LAND_INDUSTRIAL_TEXT = 'Industrial'
export const LAND_ISLANDS_TEXT = 'Islands'
export const LAND_FARM_TEXT = 'Farm'
export const LAND_RANCH_TEXT = 'Ranch'
export const LAND_TIMBER_TEXT = 'Timber'
export const LAND_HUNTING_RECREATIONAL_TEXT = 'Hunting/Recreational'

export const SPECIAL_PURPOSE_TELECOM_DATA_CENTER = 2041
export const SPECIAL_PURPOSE_SPORTS_ENTERTAIMENT = 2042
export const SPECIAL_PURPOSE_MARINA = 2043
export const SPECIAL_PURPOSE_GOLF_COURSE = 2044
export const SPECIAL_PURPOSE_SCHOOL = 2045
export const SPECIAL_PURPOSE_RELIGIOUS_CHURCH = 2046
export const SPECIAL_PURPOSE_GARAGE_PARKING = 2047
export const SPECIAL_PURPOSE_CAR_WASH = 2048
export const SPECIAL_PURPOSE_AIRPORT = 2049

export const SPECIAL_PURPOSE_TELECOM_DATA_CENTER_TEXT = 'Telecom Data Center'
export const SPECIAL_PURPOSE_SPORTS_ENTERTAIMENT_TEXT = 'Sports Entertaiment'
export const SPECIAL_PURPOSE_MARINA_TEXT = 'Marina'
export const SPECIAL_PURPOSE_GOLF_COURSE_TEXT = 'Golf Course'
export const SPECIAL_PURPOSE_SCHOOL_TEXT = 'School'
export const SPECIAL_PURPOSE_RELIGIOUS_CHURCH_TEXT = 'Religious/Church'
export const SPECIAL_PURPOSE_GARAGE_PARKING_TEXT = 'Garage/Parking'
export const SPECIAL_PURPOSE_CAR_WASH_TEXT = 'Car Wash'
export const SPECIAL_PURPOSE_AIRPORT_TEXT = 'Airport'

export const SELF_STOTAGE = 2050
export const SELF_STOTAGE_TEXT = 'Self Storage'
export const MOBILE_HOME_PARK = 2051
export const MOBILE_HOME_PARK_TEXT = 'Mobile Home Park'
export const SENIOR_LIVING = 2052
export const SENIOR_LIVING_TEXT = 'Senior Living'

export const CommercialForSaleSubTypes = [
  {
    id: COMMERCIAL_FOR_SALE_RETAIL,
    title: COMMERCIAL_FOR_SALE_RETAIL_TEXT,
    options: [
      { id: RETAIL_BANK, text: RETAIL_BANK_TEXT },
      { id: RETAIL_CONVENIENCE_STORE, text: RETAIL_CONVENIENCE_STORE_TEXT },
      { id: RETAIL_DAY_CARE_NURSERY, text: RETAIL_DAY_CARE_NURSERY_TEXT },
      { id: RETAIL_QSR_FAST_FOOD, text: RETAIL_QSR_FAST_FOOD_TEXT },
      { id: RETAIL_GAS_STATION, text: RETAIL_GAS_STATION_TEXT },
      { id: RETAIL_GROCERY_STORE, text: RETAIL_GROCERY_STORE_TEXT },
      { id: RETAIL_PHARMACY_DRUG, text: RETAIL_PHARMACY_DRUG_TEXT },
      { id: RETAIL_RESTAURANT, text: RETAIL_RESTAURANT_TEXT },
      { id: RETAIL_BAR, text: RETAIL_BAR_TEXT },
      { id: RETAIL_STOREFRONT, text: RETAIL_STOREFRONT_TEXT },
      { id: RETAIL_SHOPPING_CENTER, text: RETAIL_SHOPPING_CENTER_TEXT },
      { id: RETAIL_AUTO_SHOP, text: RETAIL_AUTO_SHOP_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_SALE_MULTIFAMILY,
    title: COMMERCIAL_FOR_SALE_MULTIFAMILY_TEXT,
    options: [
      { id: MULTIFAMILY_STUDENT_HOUSING, text: MULTIFAMILY_STUDENT_HOUSING_TEXT },
      { id: MULTIFAMILY_RV_PARK, text: MULTIFAMILY_RV_PARK_TEXT },
      { id: MULTIFAMILY_APARTMENT_BUILDING, text: MULTIFAMILY_APARTMENT_BUILDING_TEXT },
      { id: MULTIFAMILY_CONDOMINIUMS, text: MULTIFAMILY_CONDOMINIUMS_TEXT },
      { id: MULTIFAMILY_MIXED_USE, text: MULTIFAMILY_MIXED_USE_TEXT },
      { id: MULTIFAMILY_AGE_RESTRICTED, text: MULTIFAMILY_AGE_RESTRICTED_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_SALE_OFFICE,
    title: COMMERCIAL_FOR_SALE_OFFICE_TEXT,
    options: [
      { id: OFFICE_TRADITIONAL_OFFICE, text: OFFICE_TRADITIONAL_OFFICE_TEXT },
      { id: OFFICE_EXECUTIVE_OFFICE, text: OFFICE_EXECUTIVE_OFFICE_TEXT },
      { id: OFFICE_MEDICAL_OFFICE, text: OFFICE_MEDICAL_OFFICE_TEXT },
      { id: OFFICE_CREATIVE_OFFICE, text: OFFICE_CREATIVE_OFFICE_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_SALE_INDUSTRIAL,
    title: COMMERCIAL_FOR_SALE_INDUSTRIAL_TEXT,
    options: [
      { id: INDUSTRIAL_DISTRIBUTION, text: INDUSTRIAL_DISTRIBUTION_TEXT },
      { id: INDUSTRIAL_FLEX, text: INDUSTRIAL_FLEX_TEXT },
      { id: INDUSTRIAL_WAREHOUSE, text: INDUSTRIAL_WAREHOUSE_TEXT },
      { id: INDUSTRIAL_R_AND_D, text: INDUSTRIAL_R_AND_D_TEXT },
      { id: INDUSTRIAL_MANUFACTURING, text: INDUSTRIAL_MANUFACTURING_TEXT },
      { id: INDUSTRIAL_REFRIGERATED_COLD_STORAGE, text: INDUSTRIAL_REFRIGERATED_COLD_STORAGE_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_SALE_HOSPITALITY,
    title: COMMERCIAL_FOR_SALE_HOSPITALITY_TEXT,
    options: [
      { id: HOSPITALITY_HOTEL, text: HOSPITALITY_HOTEL_TEXT },
      { id: HOSPITALITY_MOTEL, text: HOSPITALITY_MOTEL_TEXT },
      { id: HOSPITALITY_CASINO, text: HOSPITALITY_CASINO_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_SALE_LAND,
    title: COMMERCIAL_FOR_SALE_LAND_TEXT,
    options: [
      { id: LAND_AGRICULTURAL, text: LAND_AGRICULTURAL_TEXT },
      { id: LAND_RESIDENTIAL, text: LAND_RESIDENTIAL_TEXT },
      { id: LAND_COMMERCIAL, text: LAND_COMMERCIAL_TEXT },
      { id: LAND_INDUSTRIAL, text: LAND_INDUSTRIAL_TEXT },
      { id: LAND_ISLANDS, text: LAND_ISLANDS_TEXT },
      { id: LAND_FARM, text: LAND_FARM_TEXT },
      { id: LAND_RANCH, text: LAND_RANCH_TEXT },
      { id: LAND_TIMBER, text: LAND_TIMBER_TEXT },
      { id: LAND_HUNTING_RECREATIONAL, text: LAND_HUNTING_RECREATIONAL_TEXT }
    ]
  },
  {
    id: 207,
    title: 'Other',
    options: [
      { id: SELF_STOTAGE, text: SELF_STOTAGE_TEXT },
      { id: MOBILE_HOME_PARK, text: MOBILE_HOME_PARK_TEXT },
      { id: SENIOR_LIVING, text: SENIOR_LIVING_TEXT }
    ]
  },
  {
    id: COMMERCIAL_FOR_SALE_SPECIAL_PURPOSE,
    title: COMMERCIAL_FOR_SALE_SPECIAL_PURPOSE_TEXT,
    options: [
      { id: SPECIAL_PURPOSE_TELECOM_DATA_CENTER, text: SPECIAL_PURPOSE_TELECOM_DATA_CENTER_TEXT },
      { id: SPECIAL_PURPOSE_SPORTS_ENTERTAIMENT, text: SPECIAL_PURPOSE_SPORTS_ENTERTAIMENT_TEXT },
      { id: SPECIAL_PURPOSE_MARINA, text: SPECIAL_PURPOSE_MARINA_TEXT },
      { id: SPECIAL_PURPOSE_GOLF_COURSE, text: SPECIAL_PURPOSE_GOLF_COURSE_TEXT },
      { id: SPECIAL_PURPOSE_SCHOOL, text: SPECIAL_PURPOSE_SCHOOL_TEXT },
      { id: SPECIAL_PURPOSE_RELIGIOUS_CHURCH, text: SPECIAL_PURPOSE_RELIGIOUS_CHURCH_TEXT },
      { id: SPECIAL_PURPOSE_GARAGE_PARKING, text: SPECIAL_PURPOSE_GARAGE_PARKING_TEXT },
      { id: SPECIAL_PURPOSE_CAR_WASH, text: SPECIAL_PURPOSE_CAR_WASH_TEXT },
      { id: SPECIAL_PURPOSE_AIRPORT, text: SPECIAL_PURPOSE_AIRPORT_TEXT }
    ]
  }
]

export const CommercialForSaleSubTypesOptions = [
  ...CommercialForSaleSubTypes[0].options,
  ...CommercialForSaleSubTypes[1].options,
  ...CommercialForSaleSubTypes[2].options,
  ...CommercialForSaleSubTypes[3].options,
  ...CommercialForSaleSubTypes[5].options,
  ...CommercialForSaleSubTypes[5].options,
  ...CommercialForSaleSubTypes[6].options,
  ...CommercialForSaleSubTypes[7].options
]
