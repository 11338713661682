import * as faker from 'faker'
import { is } from 'ramda'

export default class Comment {
  constructor (comment = {}) {
    this.id = is(String, comment.id) ? comment.id : faker.datatype.uuid()
    this.image = is(String, comment.image) ? comment.image : null
    this.message = is(String, comment.message) ? comment.message : null
    this.time = is(Date, comment.time) ? comment.time : new Date()
    this.is_post_author = is(Boolean, comment.is_post_author) ? comment.is_post_author : false
    this.is_liked = is(Boolean, comment.is_liked) ? comment.is_liked : false
    this.like_type = is(Number, comment.like_type) ? comment.like_type : null
    this.likes = is(Number, comment.likes) ? comment.likes : 0
    this.reactions = is(Array, comment.reactions) ? comment.reactions : []
    this.user = is(Object, comment.user) ? comment.user : { profile: require('@/assets/images/page-img/g1.jpg'), name: 'Nik Jon', address: { city: 'Agoura hills', zip: 91301 } }
    this.replies = is(Array, comment.replies) ? comment.replies : []
  }
}
