import * as faker from 'faker'
import User from '@/Model/User'
import FakeUser from '@/Faker/User'

export default class FakeComment {
  static create () {
    return {
      image: require('@/assets/images/user/user-04.jpg'),
      message: faker.lorem.sentences(faker.datatype.number(3, 12)),
      time: faker.date.past(faker.datatype.number(25)),
      user: new User({ ...FakeUser.create() }),
      replies: [ ]
    }
  }
}
