import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'
import ResidentialForSale from '@/FackApi/api/Listings/ResidentialForSale'
import ResidentialForRent from '@/FackApi/api/Listings/ResidentialForRent'
import ResidentialForRentMultipleUnits from '@/FackApi/api/Listings/ResidentialForRentMultipleUnits'
import CommercialForSale from '@/FackApi/api/Listings/CommercialForSale'
import CommercialForLease from '@/FackApi/api/Listings/CommercialForLease'
import ComingSoonResidential from '@/FackApi/api/Listings/ComingSoonResidential'
import ComingSoonCommercial from '@/FackApi/api/Listings/ComingSoonCommercial'
import PreForeclosureResidential from '@/FackApi/api/Listings/PreForeclosureResidential'
import PreForeclosureCommercial from '@/FackApi/api/Listings/PreForeclosureCommercial'
import FSBOResidentialForSale from '@/FackApi/api/Listings/FSBOResidentialForSale'
import FSBOCommercialForSale from '@/FackApi/api/Listings/FSBOCommercialForSale'
import TripleNetInvestmentsForSale from '@/FackApi/api/Listings/TripleNetInvestmentsForSale'
import CashOfferIBuyer from '@/FackApi/api/Listings/CashOfferIBuyer'
import AuctionStyle from '@/FackApi/api/Listings/AuctionStyle'
import VacationHomes from '@/FackApi/api/Listings/VacationHomes'

export const ListingsByType = {
  [null]: {
    ...ResidentialForSale,
    ...ResidentialForRent,
    ...ResidentialForRentMultipleUnits,
    ...CommercialForSale,
    ...CommercialForLease,
    ...ComingSoonResidential,
    ...ComingSoonCommercial,
    ...PreForeclosureResidential,
    ...PreForeclosureCommercial,
    ...FSBOResidentialForSale,
    ...FSBOCommercialForSale,
    ...TripleNetInvestmentsForSale,
    ...CashOfferIBuyer,
    ...AuctionStyle
  },
  [propertyTypes.RESIDENTIAL_FOR_SALE]: ResidentialForSale, // 1,2,3
  [propertyTypes.RESIDENTIAL_FOR_RENT]: ResidentialForRent, // 4,5,6
  [propertyTypes.COMMERCIAL_FOR_SALE]: CommercialForSale, // 7,8,9
  [propertyTypes.COMMERCIAL_FOR_LEASE]: CommercialForLease, // 10,11,12
  [propertyTypes.COMING_SOON_RESIDENTIAL]: ComingSoonResidential, // 13,14,15
  [propertyTypes.COMING_SOON_COMMERCIAL]: ComingSoonCommercial, // 16,17,18
  [propertyTypes.PRE_FORECLOSURE_RESIDENTIAL]: PreForeclosureResidential, // 19,20,21
  [propertyTypes.PRE_FORECLOSURE_COMMERCIAL]: PreForeclosureCommercial, // 22,23,24
  [propertyTypes.FSBO_RESIDENTIAL_FOR_SALE]: FSBOResidentialForSale, // 25,26,27
  [propertyTypes.FSBO_COMMERCIAL_FOR_SALE]: FSBOCommercialForSale, // 28,29,30
  [propertyTypes.FIXER_UPPER_FOR_SALE]: {}, // 31,32,33
  [propertyTypes.LUXURY_HOMES_FOR_RENT]: {}, // 34,35,36
  [propertyTypes.LUXURY_HOMES_FOR_SALE]: {}, // 37,38,39
  [propertyTypes.TRIPLE_NET_INVESTMENTS_FOR_SALE]: TripleNetInvestmentsForSale, // 40,41,42
  [propertyTypes.CASH_OFFER_LISTINGS]: CashOfferIBuyer, // 43,44,45
  [propertyTypes.NOTE_FOR_SALE]: {}, // 46,47,48
  [propertyTypes.VACATION_HOMES_RENTALS]: VacationHomes, // 49,50,51,55,56,57,58,59
  [propertyTypes.AUCTION_STYLE_LISTING]: AuctionStyle, // 52,53,54
  [propertyTypes.RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS]: ResidentialForRentMultipleUnits // 60,61,62
}

export const ListingsListAll = {
  [null]: {
    ...ResidentialForSale,
    ...ResidentialForRent,
    ...ResidentialForRentMultipleUnits,
    ...CommercialForSale,
    ...CommercialForLease,
    ...ComingSoonResidential,
    ...ComingSoonCommercial,
    ...PreForeclosureResidential,
    ...PreForeclosureCommercial,
    ...FSBOResidentialForSale,
    ...FSBOCommercialForSale,
    ...TripleNetInvestmentsForSale,
    ...CashOfferIBuyer,
    ...AuctionStyle,
    ...VacationHomes
  }
}

// export const Listings = {
//   1: new Listing({
//     id: 1,
//     createdAt: '2022-06-30 18:22:32'
//   }),
//   2: new Listing({
//     id: 2,
//     type: propertyTypes.COMMERCIAL_FOR_SALE,
//     subType: subTypes.HOME_APARTMENTS,
//     image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
//     address: '1613 West Boulevard',
//     city: 'Mid city',
//     state: 'CA',
//     zip: '90019',
//     price: 385000,
//     yearBuild: 1912,
//     status: 2,
//     createdAt: '2022-06-29 18:22:32'
//   }),
//   3: new Listing({ id: 3 }),
//   4: new Listing({
//     id: 4,
//     image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
//     address: '1613 West Boulevard',
//     city: 'Mid city',
//     state: 'CA',
//     zip: '90019',
//     price: 385000,
//     yearBuild: 1912
//   }),
//   5: new Listing({ id: 5 }),
//   6: new Listing({
//     id: 6,
//     image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
//     address: '1613 West Boulevard',
//     city: 'Mid city',
//     state: 'CA',
//     zip: '90019',
//     price: 385000,
//     yearBuild: 1912
//   }),
//   7: new Listing({ id: 7 }),
//   8: new Listing({ id: 8 }),
//   9: new Listing({ id: 9 }),
//   10: new Listing({ id: 10 })
// }

// export default Listings
