import * as getters from './types/getters'

export default {
  [getters.GET_THEME]: state => state.theme,
  [getters.GET_SEARCH_STRING]: state => state.searchString,
  [getters.GET_CHAT_LIST]: state => state.chatList,
  [getters.GET_CHAT_LIST_MAP]: state => state.chatListMap,
  [getters.GET_ACTIVE_CHAT_ID]: state => state.activeChatId,
  [getters.CREATE_GROUP_CHAT_STATE]: state => state.createGroupChatState,
  [getters.CREATE_GROUP_CHAT_DRAFT]: state => state.createGroupChatDraft,
  [getters.GET_CHAT_LIST_IS_LOADING]: state => state.chatListIsLoading,
  [getters.GET_CHAT_IS_LOADING]: state => state.chatIsLoading,
  [getters.GET_ACTIVE_CHAT_INFO]: state => state.activeChatInfo,
  [getters.GET_ACTIVE_CHAT_MESSAGES]: state => state.activeChatMessages,
  [getters.GET_ACTIVE_CHAT_MEMBERS]: state => state.activeChatMembers,
  [getters.GET_ACTIVE_CHAT]: state => state.chatList[state.activeChatId],
  [getters.EDIT_GROUP_CHAT_STATE]: state => state.editGroupChatState,
  [getters.EDIT_GROUP_MEMBERS_CHAT_STATE]: state => state.editGroupMembersChatState,
  [getters.GET_EDIT_MESSAGE_STATE]: state => state.editMessage,
  [getters.GET_DRAFT_MESSAGE]: state => state.draftMessages,
  [getters.GET_DRAFT_HTML_MESSAGE]: state => state.draftHtmlMessages,
  [getters.GET_REPLY_MESSAGE_STATE]: state => state.replyMessage,
  [getters.GET_FORWARD_MESSAGE]: state => state.forwardMessage,
  [getters.GET_SELECTED_MESSAGES]: state => state.selectedMessages
}
