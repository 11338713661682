import moment from 'moment'
import * as users from '@/FackApi/api/Users'
import ErezJonathan from '@/FackApi/api/Comments/Replies/ErezJonathan'
import JosephKaminski from '@/FackApi/api/Comments/Replies/JosephKaminski'
import LoveReaction from '@/FackApi/api/Reactions/Love'
import WowReaction from '@/FackApi/api/Reactions/Wow'
import Comment from '@/Model/Comment'

export default {
  image: require('@/assets/images/user/user-04.jpg'),
  message: 'Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  time: moment(new Date(), 'YYYY-MM-DD H:i:s').add(-3, 'days'),
  user: users.JesicaParker,
  likes: 6,
  is_liked: true,
  like_type: LoveReaction.id,
  reactions: [
    {
      type: { ...LoveReaction },
      count: 2,
      user_list: []
    },
    {
      type: { ...WowReaction },
      count: 6,
      user_list: []
    }
  ],
  replies: [
    new Comment({ ...ErezJonathan }),
    new Comment({ ...JosephKaminski })
  ]
}
