import Listing from '@/Model/Listing'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/ResidentialForSaleSubTypes'
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'

export default {
  25: new Listing({
    id: 25,
    type: propertyTypes.FSBO_RESIDENTIAL_FOR_SALE,
    subType: subTypes.HOME_LOFT,
    address: '1613 West Boulevard',
    city: 'Mid city',
    state: 'CA',
    zip: '90019',
    price: 385000,
    yearBuild: 1912,
    status: 2,
    createdAt: '2022-06-30 18:22:32'
  }),
  26: new Listing({
    id: 26,
    type: propertyTypes.FSBO_RESIDENTIAL_FOR_SALE,
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    subType: subTypes.HOME_LOFT,
    price: 500000,
    createdAt: '2022-06-30 18:22:32'
  }),
  27: new Listing({
    id: 27,
    type: propertyTypes.FSBO_RESIDENTIAL_FOR_SALE,
    price: 200000,
    subType: subTypes.HOME_CONDO,
    createdAt: '2022-06-30 18:22:32'
  })
}
