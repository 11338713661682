import * as mutations from './types/mutations'
import PaginationModel from '@/Model/Pagination'
import PaginationApiNormalizer from '@/Model/apiNormalizer/Pagination'
import Post from '@/Model/Post'

export default {
  [mutations.ADD_PEEP_BOOKMARK]: (state, data) => {
    const bookmarks = { ...state.peepBookmarks }
    state.peepBookmarks = {
      ...bookmarks,
      [data.id]: new Post({ ...data })
    }

    if (state.peepBookmarksMap.indexOf(data.id) === -1) {
      state.peepBookmarksMap.unshift(data.id)
    }
  },
  [mutations.ADD_POST_BOOKMARK]: (state, data) => {
    const bookmarks = { ...state.postsBookmarks }
    state.postsBookmarks = {
      ...bookmarks,
      [data.id]: new Post({ ...data })
    }

    if (state.postsBookmarksMap.indexOf(data.id) === -1) {
      state.postsBookmarksMap.unshift(data.id)
    }
  },
  [mutations.REMOVE_POST_BOOKMARK]: (state, data) => {
    const bookmarks = { ...state.postsBookmarks }

    delete bookmarks[data.id]

    const index = state.postsBookmarksMap.indexOf(data.id)
    if (index !== -1) {
      state.postsBookmarksMap.splice(index)
    }

    state.postsBookmarks = { ...bookmarks }
  },
  [mutations.REMOVE_PEEP_BOOKMARK]: (state, data) => {
    const bookmarks = { ...state.peepBookmarks }
    delete bookmarks[data.id]

    const index = state.peepBookmarksMap.indexOf(data.id)
    if (index !== -1) {
      state.peepBookmarksMap.splice(index)
    }

    state.peepBookmarks = { ...bookmarks }
  },
  [mutations.CLEAR_POST_BOOKMARKS]: (state) => {
    state.postsBookmarks = {}
    state.postsBookmarksMap = []
  },
  [mutations.CLEAR_PEEP_BOOKMARKS]: (state) => {
    state.peepBookmarks = {}
    state.peepBookmarksMap = []
  },
  [mutations.SET_FEED_TYPE]: (state, type) => {
    state.feedType = type
  },
  [mutations.SET_POSTS]: (state, data) => {
    state.posts = {
      ...state.posts,
      ...data.reduce(
        (prev, item) => ({
          ...prev,
          [item.id]: new Post({ ...item })
        }),
        {}
      )
    }

    state.postsMap = [
      ...new Set([
        ...state.postsMap,
        ...data.map(item => item.id)
      ])
    ]
  },
  [mutations.CLEAR_POSTS]: (state) => {
    state.posts = {}
    state.postsMap = []
  },
  [mutations.SET_DRAFT_POST]: (state) => {

  },
  [mutations.SET_PAGINATION]: (state, data) => {
    const pagination = new PaginationModel({ ...PaginationApiNormalizer(data) })
    state.pagination = pagination
  },
  [mutations.CREATE_POST]: (state, data) => {
    const items = { ...state.posts }

    state.posts = {
      ...items,
      [data.id]: new Post({ ...data })
    }
    state.postsMap.unshift(data.id)
  },
  [mutations.SAVE_DRAFT]: (state, data) => {
    state.draftPost = new Post({ ...data })
  },
  [mutations.CLEAR_DRAFT]: (state) => {
    state.draftPost = null
  },
  [mutations.SET_CREATE_PEEP_MODAL_STATE]: (state, data) => {
    state.createPeepModalState = data
  }
}
