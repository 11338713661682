import { is } from 'ramda'

export default class Notification {
  constructor (request = {}) {
    this.id = is(Number, request.id) ? request.id : 0
    this.userId = is(Number, request.userId) ? request.userId : 0
    this.userName = is(String, request.userName) ? request.userName : 'Tommy Emanuel'
    this.userAvatar = is(String, request.userName) ? request.userAvatar : require('@/assets/images/user/06.jpg')
    this.text = is(String, request.text) ? request.text : 'Hey man how things?'
    this.time = is(String, request.mutualConnections) ? request.mutualConnections : 'Just now'
  }
}
