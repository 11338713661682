export default {
  theme: 'theme-default',
  searchString: '',
  chatList: {},
  chatListMap: [],
  chatListIsLoading: true,
  activeChatId: null,
  activeChatInfo: {},
  activeChatMessages: [],
  activeChatMembers: {},
  chatIsLoading: true,
  createGroupChatState: false,
  editGroupMembersChatState: false,
  editGroupChatState: false,
  editMessage: null,
  replyMessage: null,
  forwardMessage: null,
  createGroupChatDraft: {},
  draftMessages: {},
  draftHtmlMessages: {},
  selectedMessages: []
}
