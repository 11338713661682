export const SET_VIEW_TYPE = 'SET_VIEW_TYPE'
export const SET_REAL_ESTATE_TYPE = 'SET_REAL_ESTATE_TYPE'
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING'
export const SET_PROPERTY_SUB_TYPES = 'SET_PROPERTY_SUB_TYPES'
export const SET_PROPERTIES = 'SET_PROPERTIES'
export const SET_PROPERTY = 'SET_PROPERTY'
export const CLEAR_PROPERTIES = 'CLEAR_PROPERTIES'
export const SET_PROPERTY_BIDS = 'SET_PROPERTY_BIDS'
export const ADD_PROPERTY_BID = 'ADD_PROPERTY_BID'
export const RETRACT_PROPERTY_BID = 'RETRACT_PROPERTY_BID'
export const SET_SHOW_BIDS_MODAL = 'SET_SHOW_BIDS_MODAL'
