import Notification from '@/Model/Notification'

const Notifications = {
  4: new Notification({ id: 4, userName: 'Mike Maschinoff', userAvatar: 'https://avatars.githubusercontent.com/u/9008136?v=4', time: '4 days ago', text: 'How are you?' }),
  1: new Notification({ id: 1, userName: 'Ygal Edy', userAvatar: 'http://homiezdev.us/appfiles/avatar/888f876ed649df8c116856256a6c560f61f982bd.jpg' }),
  3: new Notification({ id: 3 }),
  5: new Notification({ id: 5 })
}

export default Notifications
