import {
  SET_COMPANY,
  SET_COMPANY_ACTION_BUTTON,
  SET_COMPANY_ID,
  SET_COMPANY_OWNER_ID
} from '@/store/modules/company/types/mutations'

export default {
  [SET_COMPANY]: (state, data) => {
    state.company = data
  },
  [SET_COMPANY_ID]: (state, data) => {
    state.companyId = data.id
  },
  [SET_COMPANY_OWNER_ID]: (state, data) => {
    state.ownerId = data.owner_id
  },
  [SET_COMPANY_ACTION_BUTTON]: (state, data) => {
    state.actionButton = data
  }
}
