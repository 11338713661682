import ChatUser from '../../Model/ChatUser'
import * as faker from 'faker'
import MikeMaschinoff from './Users/MikeMaschinoff'

const BusinessChat = {
  11: new ChatUser({ id: 11, firstName: 'Barb', lastName: 'Ackue', username: faker.internet.userName() }),
  12: new ChatUser({ ...MikeMaschinoff, id: 12 }),
  2: new ChatUser({ id: 2, firstName: 'Ygal', lastName: 'Edy', username: faker.internet.userName(), avatar: require('@/assets/images/user/ygal.jpg') }),
  3: new ChatUser({ id: 3, firstName: 'Paul', lastName: 'Molive', username: faker.internet.userName(), avatar: require('@/assets/images/user/02.jpg') }),
  4: new ChatUser({ id: 4, firstName: 'Anna', lastName: 'Mull', username: faker.internet.userName(), avatar: require('@/assets/images/user/03.jpg') }),
  5: new ChatUser({ id: 5, firstName: 'Paige', lastName: 'Turner', username: faker.internet.userName(), avatar: require('@/assets/images/user/04.jpg') }),
  6: new ChatUser({ id: 6, firstName: 'Bob', lastName: 'Frapples', username: faker.internet.userName(), avatar: require('@/assets/images/user/05.jpg') }),
  7: new ChatUser({ id: 7, firstName: 'Greta', lastName: 'Life', username: faker.internet.userName(), avatar: require('@/assets/images/user/07.jpg') }),
  8: new ChatUser({ id: 8, firstName: 'Sara', lastName: 'Membrit', username: faker.internet.userName(), avatar: require('@/assets/images/user/08.jpg') }),
  9: new ChatUser({ id: 9, firstName: 'Pete', lastName: 'Sariya', username: faker.internet.userName(), avatar: require('@/assets/images/user/09.jpg') }),
  10: new ChatUser({ id: 10, firstName: 'Pete', lastName: 'Tauchend', username: faker.internet.userName(), avatar: require('@/assets/images/user/10.jpg') })
}

export default BusinessChat
