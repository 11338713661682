import Listing from '@/Model/Listing'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/TripleNetInvestmentsForSaleSubTypes'
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'

export default {
  40: new Listing({
    id: 40,
    type: propertyTypes.TRIPLE_NET_INVESTMENTS_FOR_SALE,
    subType: subTypes.MEXICAN_FAST_FOOD,
    params: [
      { name: 'cap-rate', value: '4.70% Cap Rate' },
      { name: 'sqft', value: '9,154 SF' },
      { name: 'net', value: 'Double Net (NN)' },
      { name: 'year-built', value: 'Built in 2022' }
    ],
    paramsAll: [
      { name: 'type', title: 'Property Type', value: 'Retail' },
      { name: 'sub-type', title: 'Subtype', value: 'Supermarket' },
      { name: 'building-size', title: 'Building Size', value: '28,000 SF' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1921' },
      { name: 'price-per-sf', title: 'Price Per SF', value: '$711' },
      { name: 'noi', title: 'NOI', value: '$658,000' },
      { name: 'tenancy', title: 'Tenancy', value: 'Single' },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'cap-rate', title: 'Cap Rate', value: 'Solar' },
      { name: 'zonning', title: 'Zonning', value: 'HPCG-R1-R3' },
      { name: 'condition', title: 'Condition', value: 'Very Good' }
    ],
    address: '1613 West Boulevard',
    city: 'Mid city',
    state: 'CA',
    zip: '90019',
    price: 385000,
    yearBuild: 1912,
    status: 2,
    createdAt: '2022-06-30 18:22:32'
  }),
  41: new Listing({
    id: 41,
    type: propertyTypes.TRIPLE_NET_INVESTMENTS_FOR_SALE,
    subType: subTypes.HAMBURGER_CHAIN,
    params: [
      { name: 'cap-rate', value: '3.20% Cap Rate' },
      { name: 'sqft', value: '10,154 SF' },
      { name: 'net', value: 'Triple Net (NNN)' },
      { name: 'year-built', value: 'Built in 2012' }
    ],
    paramsAll: [
      { name: 'type', title: 'Property Type', value: 'Retail' },
      { name: 'sub-type', title: 'Subtype', value: 'Supermarket' },
      { name: 'building-size', title: 'Building Size', value: '28,000 SF' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1921' },
      { name: 'price-per-sf', title: 'Price Per SF', value: '$711' },
      { name: 'noi', title: 'NOI', value: '$658,000' },
      { name: 'tenancy', title: 'Tenancy', value: 'Single' },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'cap-rate', title: 'Cap Rate', value: 'Solar' },
      { name: 'zonning', title: 'Zonning', value: 'HPCG-R1-R3' },
      { name: 'condition', title: 'Condition', value: 'Mint' }
    ],
    price: 500000,
    createdAt: '2022-06-30 18:22:32'
  }),
  42: new Listing({
    id: 42,
    type: propertyTypes.TRIPLE_NET_INVESTMENTS_FOR_SALE,
    subType: subTypes.SEVEN_ELEVEN,
    params: [
      { name: 'cap-rate', value: '5.20% Cap Rate' },
      { name: 'sqft', value: '6,154 SF' },
      { name: 'net', value: 'Single Net (N)' },
      { name: 'year-built', value: 'Built in 2021' }
    ],
    paramsAll: [
      { name: 'type', title: 'Property Type', value: 'Retail' },
      { name: 'sub-type', title: 'Subtype', value: 'Supermarket' },
      { name: 'building-size', title: 'Building Size', value: '28,000 SF' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1921' },
      { name: 'price-per-sf', title: 'Price Per SF', value: '$711' },
      { name: 'noi', title: 'NOI', value: '$658,000' },
      { name: 'tenancy', title: 'Tenancy', value: 'Single' },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'cap-rate', title: 'Cap Rate', value: 'Solar' },
      { name: 'zonning', title: 'Zonning', value: 'HPCG-R1-R3' },
      { name: 'condition', title: 'Condition', value: 'Very Good' }
    ],
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    price: 200000,
    createdAt: '2022-06-30 18:22:32'
  })
}
