import * as feedType from '@/config/post/feedType'
import Post from '@/Model/Post'
import MikeMaschinoffPost from '@/FackApi/api/Posts/MikeMaschinoff'
import JosephKaminskiPost from '@/FackApi/api/Posts/JosephKaminski'
import YgalEdyPost from '@/FackApi/api/Posts/YgalEdy'
import ErezJonathanPost from '@/FackApi/api/Posts/ErezJonathan'
import DanielYonathanPost from '@/FackApi/api/Posts/DanielYonathan'
import PaulMullerPost from '@/FackApi/api/Posts/PaulMuller'
import RandomUserPost from '@/FackApi/api/Posts/RandomUser'
import Poll from '@/FackApi/api/Posts/Poll'

export const Posts = [
  new Post({ ...Poll, id: '2022', feed_type: feedType.PEEP_FEED }),
  new Post({ ...MikeMaschinoffPost, id: '99999', feed_type: feedType.PEEP_FEED }),
  new Post({ ...JosephKaminskiPost, id: '1', feed_type: feedType.PEEP_FEED }),
  new Post({ ...RandomUserPost, id: '6', feed_type: feedType.PEEP_FEED }),
  new Post({ ...YgalEdyPost, id: '2', feed_type: feedType.PEEP_FEED }),
  new Post({ ...ErezJonathanPost, id: '3', feed_type: feedType.PEEP_FEED }),
  new Post({ ...DanielYonathanPost, id: '4', feed_type: feedType.PEEP_FEED }),
  new Post({ ...PaulMullerPost, id: '5', feed_type: feedType.PEEP_FEED })
]
