export default {
  companyId: null,
  ownerId: null,
  company: null,
  feed: null,
  reviews: null,
  videos: null,
  photos: null,
  invitee: null,
  viewed: null,
  liked: null,
  leads: null,
  followers: null,
  actionButton: null
}
