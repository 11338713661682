import { is } from 'ramda'
import * as userStatus from '@/config/messanger/userStatus'

export default class Chat {
  constructor (entity = {}) {
    this.id = is(String, entity.id) ? entity.id : 0
    this.creator = is(Object, entity.creator) ? entity.creator : {}
    this.isGroup = is(Boolean, entity.isGroup) ? entity.isGroup : false
    this.isPrivate = is(Boolean, entity.isPrivate) ? entity.isPrivate : false
    this.isBlocked = is(Boolean, entity.isBlocked) ? entity.isBlocked : false
    this.members = is(Array, entity.members) ? entity.members : []
    this.info = is(Object, entity.info) ? entity.info : {}
    this.messages = is(Object, entity.messages) ? entity.messages : []
  }

  get messageCounter () {
    return this.messages.length
  }

  get lastMessage () {
    return this.messages.length ? this.messages[this.messages.length - 1] : ''
  }

  get avatar () {
    return this.info.image ? this.info.image : ''
  }

  get title () {
    return this.isGroup ? this.info.title : this.info.title
  }

  get onlineStatus () {
    return this.info.onlineStatus ? this.info.onlineStatus : userStatus.ACTIVE
  }

  get isActive () {
    return this.info.isOnline
  }
}
