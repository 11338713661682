import { is } from 'ramda'
import AuctionData from '@/Model/RealEstate/AuctionData'
import * as listingStatuses from '@/config/listingStatuses'
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'
import * as ResidentialForSaleSubTypes from '@/FackApi/api/RealEstate/SubTypes/ResidentialForSaleSubTypes'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/subTypes'

export default class Listing {
  constructor (listing = {}) {
    this.id = is(Number, listing.id) ? listing.id : null
    this.type = is(Number, listing.type) ? listing.type : propertyTypes.RESIDENTIAL_FOR_SALE
    this.subType = is(Number, listing.subType) ? listing.subType : ResidentialForSaleSubTypes.HOME_TOWNHOUSE
    this.headline = is(String, listing.headline) ? listing.headline : ''
    this.image = is(String, listing.image) ? listing.image : 'https://homeiz.com/appfiles/photoalbum/f8dd2fdab75109613dc41544a32e78ec044d5843/thumbs/b4ead2f7cf15a44a54d8deda2b9725b835519687.jpg'
    this.gallery = is(Array, listing.gallery) ? listing.gallery : []
    this.address = is(String, listing.address) ? listing.address : '25742 Simpson Place'
    this.city = is(String, listing.city) ? listing.city : 'Calabasas'
    this.state = is(String, listing.state) ? listing.state : 'CA'
    this.zip = is(String, listing.zip) ? listing.zip : '91302'
    this.price = is(Number, listing.price) ? listing.price : '2660000'

    this.yearBuild = is(Number, listing.yearBuild) ? listing.yearBuild : 1970

    this.auction = is(Object, listing.auction) ? new AuctionData({ ...listing.auction }) : null

    this.params = is(Array, listing.params) ? listing.params : [
      { name: 'beds', value: '4 Beds' },
      { name: 'bath', value: '2 Bath' },
      { name: 'sqft', value: '720 Sq. Ft.' },
      { name: 'year-built', value: 'Built in 1985' }
    ]
    this.paramsAll = is(Array, listing.paramsAll) ? listing.paramsAll : [
      { name: 'type', title: 'Type', value: 'Single Family' },
      { name: 'sqft', title: 'Sq. Footage', value: '1,410' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1921' },
      { name: 'beds', title: 'Bedrooms', value: 4 },
      { name: 'bath', title: 'Bathrooms', value: 1 },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'air-conditioning-type', title: 'Air Conditioning Type', value: 'Solar' },
      { name: 'heating-type', title: 'Heating Type', value: 'Forced Air' },
      { name: 'condition', title: 'Condition', value: 'Very Good' }
    ]
    this.amenities = is(Array, listing.amenities) ? listing.amenities : [
      { name: 'mountain-view', title: 'Mountain View' },
      { name: 'kitchen', title: 'Kitchen' },
      { name: 'parking', title: 'Free Parking on premises' },
      { name: 'tv', title: 'TV' },
      { name: 'backyard', title: 'Backyard' },
      { name: 'resort-view', title: 'Resort View' },
      { name: 'wifi', title: 'Wifi' },
      { name: 'pets', title: 'Pets allowed' },
      { name: 'air-conditioning', title: 'Portable air conditioning' },
      { name: 'hair-dryer', title: 'Hair dryer' }
    ]
    this.status = is(Number, listing.status) ? listing.status : 1
    this.viewsCount = is(Number, listing.viewsCount) ? listing.viewsCount : 2261
    this.createdAt = is(String, listing.createdAt) ? new Date(listing.createdAt) : new Date()
  }

  get auctionActive () {
    return this.status !== listingStatuses.AUCTION_ENDED
  }

  get typeText () {
    return propertyTypes.RealEstatePropertyType[this.type]
  }

  get subTypeText () {
    return subTypes.RealEstatePropertySubType.find(item => item.id === this.subType).text
  }

  get title () {
    return `${this.address} ${this.city}, ${this.state}, ${this.zip}`
  }

  get statusText () {
    return Listing.getStatuses()[this.status]
  }

  get statusColor () {
    return Listing.getStatusColors()[this.status]
  }

  get reservePriceMet () {
    return this.auction.highestBid >= this.auction.reservePrice
  }

  static getStatuses () {
    return {
      [listingStatuses.ACTIVE]: 'Active',
      [listingStatuses.PRICE_REDUCED]: 'Price reduced',
      [listingStatuses.AUCTION_ENDED]: 'Auction ended'
    }
  }

  static getStatusColors () {
    return {
      [listingStatuses.ACTIVE]: 'success',
      [listingStatuses.PRICE_REDUCED]: 'danger',
      [listingStatuses.AUCTION_ENDED]: 'danger'
    }
  }
}
