<template>
    <div aria-live="polite" aria-atomic="true" class="d-flex justify-content-center align-items-center"
         style="min-height: 200px;">
        <b-toast visible static no-auto-hide no-close-button>
            <template v-slot:toast-title>
                <strong class="mr-auto d-flex align-items-center">
                  <i :class="iconClass" class="notify-icon mr-2"></i>
                  <span v-if="!notification.header">MeTwitt</span>
                  <span v-else>{{ notification.header }}</span>
                </strong>
                <button @click="closeSnackbar" type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </template>
            <div v-if="notification.text.validatorError">
                <div
                        v-for="(validatorError, index) in notification.text
                            .validatorError"
                        :key="index"
                >
                    {{ validatorError }}
                </div>
            </div>
            <div v-else>
              <div class="d-flex flex-wrap">
                <p class="w-100">{{ notification.text }}</p>
                <div class="d-flex w-100 justify-content-center">
                  <b-button @click="closeSnackbar" variant="primary">Done</b-button>
                </div>
              </div>
            </div>
        </b-toast>
    </div>
</template>

<script>
import * as notificationActions from '@/store/modules/system-notification/types/actions'
import { mapActions } from 'vuex'

export default {
  name: 'NotificationBlock',

  props: {
    notification: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  mounted () {
    if (this.notification.timeout) {
      this.timoutID = setTimeout(() => this.closeSnackbar(), 6000)
    }
  },

  data () {
    return {
      top: true,
      timeout: -1,
      timoutID: null
    }
  },
  computed: {
    iconClass () {
      return `${this.types[this.notification.typeSnackbar].icon} ${this.types[this.notification.typeSnackbar].color}`
    },
    types () {
      return {
        success: {
          icon: 'ri-checkbox-circle-fill',
          color: 'text-success'
        },
        error: {
          icon: 'ri-error-warning-fill',
          color: 'text-danger'
        },
        info: {
          icon: 'ri-information-fill',
          color: 'text-primary'
        }
      }
    }
  },
  methods: {
    ...mapActions('systemNotification', {
      removeErrorNotification:
        notificationActions.REMOVE_NOTIFICATION
    }),

    closeSnackbar () {
      if (this.notification.timeout) {
        clearTimeout(this.timoutID)
      }
      this.removeErrorNotification(this.notification.id)
    }
  }
}
</script>

<style lang="scss" scoped>
    .close::v-deep {
        font-size: 16px;
        cursor: pointer;
    }

    .col-12::v-deep {
        padding: 0 5px;
    }

    .notify-icon {
      font-size: 22px;
    }
</style>
