import { is } from 'ramda'
import * as faker from 'faker'
import User from '@/Model/User'

export default class Bid {
  constructor (data = {}) {
    this.id = is(Number, data.id) ? data.id : faker.datatype.uuid()
    this.user = is(Object, data.user) ? new User(data.user) : new User()
    this.amount = is(Number, data.amount) ? data.amount : null
    this.date = is(Date, data.date) ? data.date : new Date()
    this.retracted = is(Date, data.retracted) ? data.retracted : false
  }
}
