export const CASH_OFFER = 900

export const CASH_OFFER_TEXT = 'Cash offer'

export const HOME_SINGLE_FAMILY = 9000
export const HOME_VILLA = 9001
export const HOME_CONDO = 9002
export const HOME_HOUSEBOAT = 9003
export const HOME_TOWNHOUSE = 9004
export const HOME_CO_OP_UNIT = 9005
export const HOME_LOFT = 9006
export const HOME_MOBILE_MANUFACTURED = 9007
export const HOME_VACANT_LAND = 9008
export const HOME_DUPLEX_TRIPLEX_FOURPLEX = 9009
export const HOME_INDUSTRIAL = 9010
export const HOME_APARTMENT_BUILDING = 9011

export const HOME_SINGLE_FAMILY_TEXT = 'Single family'
export const HOME_VILLA_TEXT = 'Villa'
export const HOME_CONDO_TEXT = 'Condo'
export const HOME_HOUSEBOAT_TEXT = 'Houseboat'
export const HOME_TOWNHOUSE_TEXT = 'Townhouse'
export const HOME_CO_OP_UNIT_TEXT = 'Co-op unit'
export const HOME_LOFT_TEXT = 'Loft'
export const HOME_MOBILE_MANUFACTURED_TEXT = 'Mobile / Manufactured'
export const HOME_VACANT_LAND_TEXT = 'Vacant land'
export const HOME_DUPLEX_TRIPLEX_FOURPLEX_TEXT = 'Duplex/Triplex/Fourplex'
export const HOME_INDUSTRIAL_TEXT = 'Industrial'
export const HOME_APARTMENT_BUILDING_TEXT = 'Apartment building 5+ units'

export const CashOfferSubTypes = [
  {
    id: CASH_OFFER,
    title: CASH_OFFER_TEXT,
    options: [
      { id: HOME_SINGLE_FAMILY, text: HOME_SINGLE_FAMILY_TEXT },
      { id: HOME_VILLA, text: HOME_VILLA_TEXT },
      { id: HOME_CONDO, text: HOME_CONDO_TEXT },
      { id: HOME_HOUSEBOAT, text: HOME_HOUSEBOAT_TEXT },
      { id: HOME_TOWNHOUSE, text: HOME_TOWNHOUSE_TEXT },
      { id: HOME_CO_OP_UNIT, text: HOME_CO_OP_UNIT_TEXT },
      { id: HOME_LOFT, text: HOME_LOFT_TEXT },
      { id: HOME_MOBILE_MANUFACTURED, text: HOME_MOBILE_MANUFACTURED_TEXT },
      { id: HOME_VACANT_LAND, text: HOME_VACANT_LAND_TEXT },
      { id: HOME_DUPLEX_TRIPLEX_FOURPLEX, text: HOME_DUPLEX_TRIPLEX_FOURPLEX_TEXT },
      // { id: HOME_INDUSTRIAL, text: HOME_INDUSTRIAL_TEXT },
      { id: HOME_APARTMENT_BUILDING, text: HOME_APARTMENT_BUILDING_TEXT }
    ]
  }
]

export const CashOfferSubTypesOptions = [
  ...CashOfferSubTypes[0].options
]
