export const ADD_BOOKMARK = 'ADD_BOOKMARK'
export const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK'
export const FETCH_BOOKMARKS = 'FETCH_BOOKMARKS'
export const CLEAR_POST_BOOKMARKS = 'CLEAR_POST_BOOKMARKS'
export const CLEAR_PEEP_BOOKMARKS = 'CLEAR_PEEP_BOOKMARKS'
export const FETCH_POSTS = 'FETCH_POSTS'
export const CLEAR_POSTS = 'CLEAR_POSTS'
export const CREATE_POST = 'CREATE_POST'
export const SAVE_DRAFT = 'SAVE_DAFT'
export const CLEAR_DRAFT = 'CLEAR_DRAFT'
export const SET_CREATE_PEEP_MODAL_STATE = 'SET_CREATE_PEEP_MODAL_STATE'
