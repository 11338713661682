import PaulMolive from '@/FackApi/api/Comments/PaulMolive'
import TextWithMultipleImages from '@/FackApi/api/Posts/TextWithMultipleImages'
import Comment from '@/Model/Comment'

export default {
  ...TextWithMultipleImages,
  id: 6,
  time: new Date('December 31, 2017 11:30:25'),
  comments: [
    new Comment({ ...PaulMolive })
  ]
}
