import moment from 'moment'
import * as users from '@/FackApi/api/Users'

export default {
  message: 'Comment from post creator',
  time: moment(new Date(), 'YYYY-MM-DD H:i:s').add(-3, 'days'),
  user: users.DanielYonathan,
  is_post_author: true,
  likes: 0,
  is_liked: false,
  like_type: null,
  reactions: []
}
