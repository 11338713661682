import { is } from 'ramda'

export default class Company {
  constructor (company = {}) {
    this.id = is(Number, company.id) ? company.id : null
    this.owner_id = is(Number, company.owner_id) ? company.owner_id : null
    this.title = is(String, company.title) ? company.title : ''
    this.description = is(String, company.description) ? company.description : null
    this.email = is(String, company.email) ? company.email : ''
    this.isActive = is(Boolean, company.isActive) ? company.isActive : false
  }
}
