import Vue from 'vue'
import moment from 'moment'

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
})

Vue.filter('formatDateMain', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
})

Vue.filter('formatDateStringShort', function (value) {
  if (value) {
    return moment(String(value)).format('MMM Do, YYYY')
  }
})

Vue.filter('formatDateStringDate', function (value) {
  if (value) {
    return moment(String(value)).format('dddd, MMMM Do, YYYY')
  }
})

Vue.filter('formatDateMothYear', function (value) {
  if (value) {
    return moment(String(value)).format('MMM, YYYY')
  }
})

Vue.filter('formatDateFull', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mma')
  }
})

Vue.filter('formatDateString', function (value) {
  if (value) {
    return moment(String(value)).format('dddd, MMMM Do, hh:mma')
  }
})
