import * as mutations from './types/mutations'
import Bid from '@/Model/RealEstate/Auction/Bid'
import User from '@/Model/User'
import Erez from '@/FackApi/api/Users/ErezJonathan'

export default {
  [mutations.SET_VIEW_TYPE]: (state, type) => {
    state.viewType = type
  },
  [mutations.SET_REAL_ESTATE_TYPE]: (state, type) => {
    state.propertyType = type
  },
  [mutations.SET_SEARCH_STRING]: (state, string) => {
    state.searchString = string
  },
  [mutations.SET_PROPERTY_SUB_TYPES]: (state, data = []) => {
    state.propertySubTypes = data
  },
  [mutations.SET_PROPERTIES]: (state, data = {}) => {
    state.properties = { ...data }
    state.propertiesMap = [...new Set([...state.propertiesMap, ...Object.keys(data)])]
  },
  [mutations.CLEAR_PROPERTIES]: state => {
    state.properties = {}
    state.propertiesMap = []
  },
  [mutations.SET_PROPERTY]: (state, data) => {
    const newItem = data
    state.propertyById = newItem
  },
  [mutations.SET_PROPERTY_BIDS]: (state, data) => {
    state.propertyBids = [ ...data ]
  },
  [mutations.SET_SHOW_BIDS_MODAL]: (state, status) => {
    state.showBidsModal = status
  },
  [mutations.ADD_PROPERTY_BID]: (state, amount) => {
    const property = state.propertyById
    property.auction.highestBid = amount
    property.auction.yourBid = amount
    state.propertyById = property
    state.propertyBids.push(new Bid({
      user: new User({ ...Erez }),
      amount: amount,
      date: new Date()
    }))
  },
  [mutations.RETRACT_PROPERTY_BID]: (state, data) => {
    state.propertyBids.pop()
    const property = state.propertyById
    if (state.propertyBids.length) {
      const highetsBidData = state.propertyBids[state.propertyBids.length - 1]
      property.auction.highestBid = highetsBidData.amount
    } else {
      property.auction.highestBid = 0
    }

    property.auction.yourBid = 0
    state.propertyById = property
  }
}
