import * as users from '@/FackApi/api/Users'
import LikeReaction from '@/FackApi/api/Reactions/Like'
import JesicaParker from '@/FackApi/api/Comments/JesicaParker'
import PaulMolive from '@/FackApi/api/Comments/PaulMolive'
import Comment from '@/Model/Comment'

export default {
  description: 'Post created by the PostModelConstructor Ipsum is simply dummy text of the printing and typesetting industry.',
  images: [
    require('../../../assets/images/page-img/n1.jpg'),
    require('../../../assets/images/page-img/gibson1.jpg'),
    require('../../../assets/images/page-img/1001.jpg'),
    require('../../../assets/images/page-img/r4.jpg'),
    require('../../../assets/images/page-img/p5.jpg'),
    require('../../../assets/images/page-img/59.jpg'),
    require('../../../assets/images/page-img/56.jpg'),
    require('../../../assets/images/page-img/p2.jpg'),
    require('../../../assets/images/page-img/p3.jpg')
  ],
  time: new Date('October 22, 2021 17:42:25'),
  is_follow: false,
  is_liked: false,
  like_type: LikeReaction.id,
  likes: 12,
  fake: 0,
  reactions: [ ],
  user: users.YgalEdy,
  comments: [
    new Comment({ ...JesicaParker }),
    new Comment({ ...PaulMolive })
  ]
}
