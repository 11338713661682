export const TRIPLE_NET_INVESTMENTS_FOR_SALE = 600

export const TRIPLE_NET_INVESTMENTS_FOR_SALE_TEXT = 'Triple Net / Sale'

export const MEXICAN_FAST_FOOD = 6000
export const HAMBURGER_CHAIN = 6001
export const SEVEN_ELEVEN = 6002
export const HOBBYCRAFT = 6003
export const ACADEMY_SCHOOL = 6004
export const INDUSTRIAL = 6005
export const BANK = 6006
export const INSURANCE = 6007
export const BARS_NIGHT_CLUBS = 6008
export const MASSAGE = 6009
export const BEAUTY_SUPPLY = 6010
export const MATTRESS_RETAILERS = 6011
export const BIG_BOX_RETAIL = 6012
export const MEDIA = 6013
export const CAR_WASHES = 6014
export const MEDICAL = 6015
export const CASUAL_DINING = 6016
export const MUSIC_STORES = 6017
export const CHURCHES = 6018
export const OFFICE_BUILDING = 6019
export const COFFEE = 6020
export const OIL_CHANGE_FACILITIES = 6021
export const CONSIGNMENT_STORES = 6022
export const PARKING_LOTS = 6023
export const DAY_CARE_AFFORDABLE_CARE = 6024
export const PET_STORE = 6025
export const DRUGSTORES = 6026
export const PHARMACIES = 6027
export const DRY_CLEANERS = 6028
export const QUICK_SERVE_RESTAURANT = 6029
export const ELECTRONICS = 6030
export const RESTAURANT = 6031
export const FINANCIAL_SERVICES = 6032
export const RETAIL = 6033
export const FITNESS = 6034
export const SPORT_HEALTH = 6035
export const FLOORING = 6036
export const STAFFING_SERVICES = 6037
export const FOOTWEAR = 6038
export const SUPERMARKETS = 6039
export const FURNITURE = 6040
export const TATTOO_PIERCING = 6041
export const GAS_STATION = 6042
export const THRIFT_STORES = 6043
export const GAS_STATION_SEVEN_ELEVEN = 6044
export const TOBACCO_CIGARS = 6045
export const GENERAL = 6046
export const VEHICLE_RELATED = 6047
export const GIFT_SHOPS = 6048
export const VITAMIN_NUTRITION_HERBAL_REMEDIES = 6049
export const GOVERNMENT = 6050
export const WINE_BEER_LIQUOR = 6051
export const GROCERY = 6052
export const WIRELESS_COMMUNICATIONS = 6053

export const MEXICAN_FAST_FOOD_TEXT = 'Mexican Fast Food'
export const HAMBURGER_CHAIN_TEXT = 'Hamburger/Chain'
export const SEVEN_ELEVEN_TEXT = '7 Eleven'
export const HOBBYCRAFT_TEXT = 'Hobbycraft'
export const ACADEMY_SCHOOL_TEXT = 'Academy/School'
export const INDUSTRIAL_TEXT = 'Industrial'
export const BANK_TEXT = 'Bank'
export const INSURANCE_TEXT = 'Insurance'
export const BARS_NIGHT_CLUBS_TEXT = 'Bars / Night Clubs'
export const MASSAGE_TEXT = 'Massage'
export const BEAUTY_SUPPLY_TEXT = 'Beauty Supply'
export const MATTRESS_RETAILERS_TEXT = 'Mattress Retailers'
export const BIG_BOX_RETAIL_TEXT = 'Big Box Retail'
export const MEDIA_TEXT = 'Media'
export const CAR_WASHES_TEXT = 'Car Washes'
export const MEDICAL_TEXT = 'Medical'
export const CASUAL_DINING_TEXT = 'Casual Dining'
export const MUSIC_STORES_TEXT = 'Music Stores'
export const CHURCHES_TEXT = 'Churches'
export const OFFICE_BUILDING_TEXT = 'Office Building'
export const COFFEE_TEXT = 'Coffee'
export const OIL_CHANGE_FACILITIES_TEXT = 'Oil Change Facilities'
export const CONSIGNMENT_STORES_TEXT = 'Consignment Stores'
export const PARKING_LOTS_TEXT = 'Parking Lots'
export const DAY_CARE_AFFORDABLE_CARE_TEXT = 'Day Care/Affordable Care'
export const PET_STORE_TEXT = 'Pet Store'
export const DRUGSTORES_TEXT = 'Drugstores'
export const PHARMACIES_TEXT = 'Pharmacies'
export const DRY_CLEANERS_TEXT = 'Dry Cleaners'
export const QUICK_SERVE_RESTAURANT_TEXT = 'Quick Serve Restaurant'
export const ELECTRONICS_TEXT = 'Electronics'
export const RESTAURANT_TEXT = 'Restaurant'
export const FINANCIAL_SERVICES_TEXT = 'Financial Services'
export const RETAIL_TEXT = 'Retail'
export const FITNESS_TEXT = 'Fitness'
export const SPORT_HEALTH_TEXT = 'Sport/Health'
export const FLOORING_TEXT = 'Flooring'
export const STAFFING_SERVICES_TEXT = 'Staffing Services'
export const FOOTWEAR_TEXT = 'Footwear'
export const SUPERMARKETS_TEXT = 'Supermarkets'
export const FURNITURE_TEXT = 'Furniture'
export const TATTOO_PIERCING_TEXT = 'Tattoo / Piercing'
export const GAS_STATION_TEXT = 'Gas Station'
export const THRIFT_STORES_TEXT = 'Thrift Stores'
export const GAS_STATION_SEVEN_ELEVEN_TEXT = 'Gas Station/7 Eleven'
export const TOBACCO_CIGARS_TEXT = 'Tobacco / Cigars'
export const GENERAL_TEXT = 'General'
export const VEHICLE_RELATED_TEXT = 'Vehicle Related'
export const GIFT_SHOPS_TEXT = 'Gift Shops'
export const VITAMIN_NUTRITION_HERBAL_REMEDIES_TEXT = 'Vitamin / Nutrition / Herbal Remedies'
export const GOVERNMENT_TEXT = 'Government'
export const WINE_BEER_LIQUOR_TEXT = 'Wine / Beer / Liquor'
export const GROCERY_TEXT = 'Grocery'
export const WIRELESS_COMMUNICATIONS_TEXT = 'Wireless Communications'

export const TripleNetInvestmentsForSaleSubTypes = [
  {
    id: TRIPLE_NET_INVESTMENTS_FOR_SALE,
    title: TRIPLE_NET_INVESTMENTS_FOR_SALE_TEXT,
    options: [
      { id: MEXICAN_FAST_FOOD, text: MEXICAN_FAST_FOOD_TEXT },
      { id: HAMBURGER_CHAIN, text: HAMBURGER_CHAIN_TEXT },
      { id: SEVEN_ELEVEN, text: SEVEN_ELEVEN_TEXT },
      { id: HOBBYCRAFT, text: HOBBYCRAFT_TEXT },
      { id: ACADEMY_SCHOOL, text: ACADEMY_SCHOOL_TEXT },
      { id: INDUSTRIAL, text: INDUSTRIAL_TEXT },
      { id: BANK, text: BANK_TEXT },
      { id: INSURANCE, text: INSURANCE_TEXT },
      { id: BARS_NIGHT_CLUBS, text: BARS_NIGHT_CLUBS_TEXT },
      { id: MASSAGE, text: MASSAGE_TEXT },
      { id: BEAUTY_SUPPLY, text: BEAUTY_SUPPLY_TEXT },
      { id: MATTRESS_RETAILERS, text: MATTRESS_RETAILERS_TEXT },
      { id: BIG_BOX_RETAIL, text: BIG_BOX_RETAIL_TEXT },
      { id: MEDIA, text: MEDIA_TEXT },
      { id: CAR_WASHES, text: CAR_WASHES_TEXT },
      { id: MEDICAL, text: MEDICAL_TEXT },
      { id: CASUAL_DINING, text: CASUAL_DINING_TEXT },
      { id: MUSIC_STORES, text: MUSIC_STORES_TEXT },
      { id: CHURCHES, text: CHURCHES_TEXT },
      { id: OFFICE_BUILDING, text: OFFICE_BUILDING_TEXT },
      { id: COFFEE, text: COFFEE_TEXT },
      { id: OIL_CHANGE_FACILITIES, text: OIL_CHANGE_FACILITIES_TEXT },
      { id: CONSIGNMENT_STORES, text: CONSIGNMENT_STORES_TEXT },
      { id: PARKING_LOTS, text: PARKING_LOTS_TEXT },
      { id: DAY_CARE_AFFORDABLE_CARE, text: DAY_CARE_AFFORDABLE_CARE_TEXT },
      { id: PET_STORE, text: PET_STORE_TEXT },
      { id: DRUGSTORES, text: DRUGSTORES_TEXT },
      { id: PHARMACIES, text: PHARMACIES_TEXT },
      { id: DRY_CLEANERS, text: DRY_CLEANERS_TEXT },
      { id: QUICK_SERVE_RESTAURANT, text: QUICK_SERVE_RESTAURANT_TEXT },
      { id: ELECTRONICS, text: ELECTRONICS_TEXT },
      { id: RESTAURANT, text: RESTAURANT_TEXT },
      { id: FINANCIAL_SERVICES, text: FINANCIAL_SERVICES_TEXT },
      { id: RETAIL, text: RETAIL_TEXT },
      { id: FITNESS, text: FITNESS_TEXT },
      { id: SPORT_HEALTH, text: SPORT_HEALTH_TEXT },
      { id: FLOORING, text: FLOORING_TEXT },
      { id: STAFFING_SERVICES, text: STAFFING_SERVICES_TEXT },
      { id: FOOTWEAR, text: FOOTWEAR_TEXT },
      { id: SUPERMARKETS, text: SUPERMARKETS_TEXT },
      { id: FURNITURE, text: FURNITURE_TEXT },
      { id: TATTOO_PIERCING, text: TATTOO_PIERCING_TEXT },
      { id: GAS_STATION, text: GAS_STATION_TEXT },
      { id: THRIFT_STORES, text: THRIFT_STORES_TEXT },
      { id: GAS_STATION_SEVEN_ELEVEN, text: GAS_STATION_SEVEN_ELEVEN_TEXT },
      { id: TOBACCO_CIGARS, text: TOBACCO_CIGARS_TEXT },
      { id: GENERAL, text: GENERAL_TEXT },
      { id: VEHICLE_RELATED, text: VEHICLE_RELATED_TEXT },
      { id: GIFT_SHOPS, text: GIFT_SHOPS_TEXT },
      { id: VITAMIN_NUTRITION_HERBAL_REMEDIES, text: VITAMIN_NUTRITION_HERBAL_REMEDIES_TEXT },
      { id: GOVERNMENT, text: GOVERNMENT_TEXT },
      { id: WINE_BEER_LIQUOR, text: WINE_BEER_LIQUOR_TEXT },
      { id: GROCERY, text: GROCERY_TEXT },
      { id: WIRELESS_COMMUNICATIONS, text: WIRELESS_COMMUNICATIONS_TEXT }
    ]
  }
]

export const TripleNetInvestmentsForSaleSubTypesOptions = [
  ...TripleNetInvestmentsForSaleSubTypes[0].options
]
