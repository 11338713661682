import TextWithSingleImage from '@/FackApi/api/Posts/TextWithSingleImage'
import PaulMolive from '@/FackApi/api/Comments/PaulMolive'
import Comment from '@/Model/Comment'

export default {
  ...TextWithSingleImage,
  id: 3,
  comments: [
    new Comment({ ...PaulMolive })
  ]
}
