import { is } from 'ramda'

export default class AuctionData {
  constructor (data = {}) {
    this.endDate = is(Date, data.endDate) ? data.endDate : new Date()
    this.startingBid = is(Number, data.startingBid) ? data.startingBid : null
    this.reservePrice = is(Number, data.reservePrice) ? data.reservePrice : null
    this.highestBid = is(Number, data.highestBid) ? data.highestBid : null
    this.yourBid = is(Number, data.yourBid) ? data.yourBid : 0
    this.bids = is(Number, data.bids) ? data.bids : 0
  }
}
