export const SET_BOOKMARKS = 'SET_BOOKMARKS'
export const CLEAR_POST_BOOKMARKS = 'CLEAR_POST_BOOKMARKS'
export const CLEAR_PEEP_BOOKMARKS = 'CLEAR_PEEP_BOOKMARKS'
export const ADD_PEEP_BOOKMARK = 'ADD_PEEP_BOKMARK'
export const ADD_POST_BOOKMARK = 'ADD_POST_BOKMARK'
export const REMOVE_PEEP_BOOKMARK = 'REMOVE_PEEP_BOKMARK'
export const REMOVE_POST_BOOKMARK = 'REMOVE_POST_BOKMARK'

export const SET_DRAFT_POST = 'SET_DRAFT_POST'
export const SET_FEED_TYPE = 'SET_FEED_TYPE'
export const SET_POSTS = 'SET_POSTS'
export const SET_PAGINATION = 'SET_PAGINATION'
export const CLEAR_POSTS = 'CLEAR_POSTS'
export const CREATE_POST = 'CREATE_POST'
export const SAVE_DRAFT = 'SAVE_DRAFT'
export const CLEAR_DRAFT = 'CLEAR_DRAFT'

export const SET_CREATE_PEEP_MODAL_STATE = 'SET_CREATE_PEEP_MODAL_STATE'
