import Company from '@/Model/Company'
import FakeCompany from '@/Faker/Company'

export const RandomCompany = new Company({ ...FakeCompany.create() })

export const CompanyList = {
  [RandomCompany.id]: RandomCompany,
  1: new Company({ ...RandomCompany, id: 1, owner_id: 1 }),
  2: new Company({ ...RandomCompany, id: 2, owner_id: 7 })
}
