import * as faker from 'faker'

export default class FakeCompany {
  static create () {
    return {
      id: 9999999,
      title: faker.company.companyName(),
      description: faker.lorem.sentences(),
      email: faker.internet.email(),
      isActive: true
    }
  }
}
