import { ErezJonathan } from '@/FackApi/api/Users'

const authService = {
  async signIn (userLoginData) {
    let response = {
      data: {
        data: {
          error: { message: `Account ${userLoginData.email} doesn't exist or password is invalid!` }
        }
      }
    }

    if (userLoginData.email === 'metwitt@metwitt.com' &&
        userLoginData.password === 'MeTwitt2023'
    ) {
      response.data = {
        data: {
          access_token: `token-for-${userLoginData.email}`,
          token_type: 'bearer',
          expires_in: ''
        }
      }
      this.saveToken(response?.data?.data.access_token)
    } else {
      throw new Error(response?.data?.data.error.message)
    }

    return response?.data?.data
  },
  async signOut () {
    const response = { data: {} }
    this.removeToken()
    return response?.data?.data
  },
  async fetchLoggedUser () {
    const response = { data: {} }
    response.data = {
      data: ErezJonathan
    }
    return response?.data?.data
  },
  saveToken (token) {
    localStorage.setItem('metwitt.accessToken', token)
  },
  removeToken () {
    localStorage.setItem('metwitt.accessToken', '')
  },
  getToken () {
    return localStorage.getItem('metwitt.accessToken')
  },
  hasToken () {
    return !!localStorage.getItem('metwitt.accessToken')
  }
}

export default authService
