export const LUXURY_HOMES_FOR_RENT = 400

export const LUXURY_HOMES_FOR_RENT_TEXT = ''

export const LAVISH_HIGH_RISE_CONDOMINIUMS = 4000
export const GATED_MANSIONS = 4001
export const SINGLE_FAMILY_HOMES = 4002
export const CUSTOM_HOMES = 4003
export const GRAND_ESTATES = 4004
export const VAST_RANCHES = 4005

export const LAVISH_HIGH_RISE_CONDOMINIUMS_TEXT = 'Lavish High-Rise Condominiums'
export const GATED_MANSIONS_TEXT = 'Gated Mansions'
export const SINGLE_FAMILY_HOMES_TEXT = 'Single-Family Homes'
export const CUSTOM_HOMES_TEXT = 'Custom Homes'
export const GRAND_ESTATES_TEXT = 'Grand Estates'
export const VAST_RANCHES_TEXT = 'Vast Ranches'

export const LuxuryHomesForRentSubTypes = [
  {
    id: LUXURY_HOMES_FOR_RENT,
    title: LUXURY_HOMES_FOR_RENT_TEXT,
    options: [
      { id: LAVISH_HIGH_RISE_CONDOMINIUMS, text: LAVISH_HIGH_RISE_CONDOMINIUMS_TEXT },
      { id: GATED_MANSIONS, text: GATED_MANSIONS_TEXT },
      { id: SINGLE_FAMILY_HOMES, text: SINGLE_FAMILY_HOMES_TEXT },
      { id: CUSTOM_HOMES, text: CUSTOM_HOMES_TEXT },
      { id: GRAND_ESTATES, text: GRAND_ESTATES_TEXT },
      { id: VAST_RANCHES, text: VAST_RANCHES_TEXT }
    ]
  }
]

export const LuxuryHomesForRentSubTypesOptions = [
  ...LuxuryHomesForRentSubTypes[0].options
]
