import * as mutations from './types/mutations'
import User from '@/Model/User'

export default {
  [mutations.USER_LOGOUT]: state => {
    state.authUser = null
  },
  [mutations.SET_LOGGED_USER]: (state, userData) => {
    state.authUser = new User({ ...userData })
  },
  [mutations.SET_TOKEN_RESET]: (state, value) => {
    state.resetPasswordData = {
      ...state.resetPasswordData,
      token: value
    }
  }
}
