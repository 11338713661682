import store from '@/store'
import responsePresenter from '@/FackApi/services/responsePresenter'

export default async function updateMessageById (id, data) {
  await new Promise((resolve) => setTimeout(resolve, 300))
  const activeChatId = store.state.messanger.activeChatId
  data.updatedAt = new Date()
  const response = responsePresenter({ data: { message: data, chatId: activeChatId } })

  return response
}
