import * as actions from './types/actions'
import * as mutations from './types/mutations'
import * as loaderMutations from '@/store/modules/loader/types/mutations'
import * as notifyActions from '@/store/modules/system-notification/types/actions'
import feedService from '@/FackApi/services/feed'
import * as feedTypes from '@/config/post/feedType'

export default {
  [actions.FETCH_POSTS]: async (
    { commit, dispatch },
    data = {
      type: feedTypes.BUSINESS_FEED,
      page: 1
    }
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      commit(mutations.SET_FEED_TYPE, data.type)
      const response = await feedService.fetchAll(data)
      commit(mutations.SET_POSTS, response?.data?.data)
      commit(mutations.SET_PAGINATION, response?.data?.meta)

      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    }
  },
  [actions.CLEAR_POSTS]: async (
    { commit, dispatch }
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      commit(mutations.CLEAR_POSTS)
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    }
  },
  [actions.CREATE_POST]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      const response = await feedService.create(data)

      commit(mutations.CREATE_POST, response?.data?.data)

      dispatch(
        'systemNotification/' + notifyActions.SET_SUCCESS_NOTIFICATION,
        'Your tweet was posted.',
        { root: true }
      )

      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      return response?.data?.data
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    }
  },
  [actions.SAVE_DRAFT]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })

      const response = await feedService.saveDraft(data)

      commit(mutations.SAVE_DRAFT, response?.data?.data)

      dispatch(
        'systemNotification/' + notifyActions.SET_SUCCESS_NOTIFICATION,
        'Your tweet was saved.',
        { root: true }
      )

      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    }
  },
  [actions.CLEAR_DRAFT]: async (
    { commit, dispatch }
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      commit(mutations.CLEAR_DRAFT)
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    }
  },
  [actions.FETCH_BOOKMARKS]: async (
    { commit, dispatch },
    data = {
      page: 1,
      filters: {}
    }
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      const response = await feedService.fetchAllBookmarks(data)

      commit(mutations.SET_BOOKMARKS, response?.data?.data)

      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    }
  },
  [actions.ADD_BOOKMARK]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      const response = null

      if (data.feed_type === feedTypes.PEEP_FEED) {
        commit(mutations.ADD_PEEP_BOOKMARK, data)
      } else {
        commit(mutations.ADD_POST_BOOKMARK, data)
      }
      dispatch(
        'systemNotification/' + notifyActions.SET_SUCCESS_NOTIFICATION,
        `Saved to bookmarks!`,
        { root: true }
      )

      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      return response?.data?.data
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.REMOVE_BOOKMARK]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      const response = null
      if (data.feed_type === feedTypes.PEEP_FEED) {
        commit(mutations.REMOVE_PEEP_BOOKMARK, data)
      } else {
        commit(mutations.REMOVE_POST_BOOKMARK, data)
      }
      dispatch(
        'systemNotification/' + notifyActions.SET_SUCCESS_NOTIFICATION,
        `Removed from bookmarks!`,
        { root: true }
      )

      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      return response?.data?.data
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.CLEAR_POST_BOOKMARKS]: async (
    { commit, dispatch }
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })

      commit(mutations.CLEAR_POST_BOOKMARKS)

      dispatch(
        'systemNotification/' + notifyActions.SET_SUCCESS_NOTIFICATION,
        `The bookmarks has been successfully cleared!`,
        { root: true }
      )

      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.CLEAR_PEEP_BOOKMARKS]: async (
    { commit, dispatch }
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })

      commit(mutations.CLEAR_PEEP_BOOKMARKS)

      dispatch(
        'systemNotification/' + notifyActions.SET_SUCCESS_NOTIFICATION,
        `The bookmarks has been successfully cleared!`,
        { root: true }
      )

      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.SET_CREATE_PEEP_MODAL_STATE]: async (
    { commit, dispatch },
    state
  ) => {
    try {
      commit(mutations.SET_CREATE_PEEP_MODAL_STATE, state)
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  }
}
