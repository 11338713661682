export default {
  feedType: null,
  posts: {},
  postsMap: [],
  pagination: {
    currentPage: 1,
    lastPage: null,
    perPage: 10,
    total: null
  },
  postsBookmarks: {},
  postsBookmarksMap: [],
  peeps: {},
  peepsMaps: [],
  peepBookmarks: {},
  peepBookmarksMap: [],
  draftPost: null,
  createPeepModalState: false
}
