import * as faker from 'faker'

export default class FakeChatMessages {
  static create (creator = {}, recipient = {}) {
    const date = new Date(faker.date.recent(6))

    return {
      id: faker.datatype.uuid(),
      message: faker.lorem.sentence(),
      creator,
      recipient,
      isRead: true, // faker.datatype.boolean(),
      createdAt: date,
      updatedAt: date
    }
  }
}
