import Listing from '@/Model/Listing'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/CommercialForLeaseSubTypes'
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'

export default {
  10: new Listing({
    id: 10,
    type: propertyTypes.COMMERCIAL_FOR_LEASE,
    subType: subTypes.LAND_INDUSTRIAL,
    params: [],
    paramsAll: [
      { name: 'type', title: 'Property Type', value: 'Retail' },
      { name: 'sub-type', title: 'Subtype', value: 'Executive Office, Commercial' },
      { name: 'tenancy', title: 'Tenancy', value: 'Single' },
      { name: 'year-built', title: 'Year Built', value: '1921' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'sqft', title: 'Total Building SQFT', value: '1,410' },
      { name: 'rent-monthly', title: 'Monthly Rent', value: '$3.50/SF/mo' },
      { name: 'rent-yearly', title: 'Yearly Rent', value: '$42/SF/yr' },
      { name: 'lease-type', title: 'Lease Type', value: 'NNN' },
      { name: 'parking', title: 'Parking', value: 'Driveway' }
    ],
    address: '1613 West Boulevard',
    city: 'Mid city',
    state: 'CA',
    zip: '90019',
    price: 385000,
    yearBuild: 1912,
    status: 2,
    createdAt: '2022-06-30 18:22:32'
  }),
  11: new Listing({
    id: 11,
    type: propertyTypes.COMMERCIAL_FOR_LEASE,
    subType: subTypes.LAND_INDUSTRIAL,
    params: [],
    paramsAll: [
      { name: 'type', title: 'Property Type', value: 'Retail' },
      { name: 'sub-type', title: 'Subtype', value: 'Commercial' },
      { name: 'tenancy', title: 'Tenancy', value: 'Single' },
      { name: 'year-built', title: 'Year Built', value: '1970' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'sqft', title: 'Total Building SQFT', value: '1,410' },
      { name: 'rent-monthly', title: 'Monthly Rent', value: '$3.50/SF/mo' },
      { name: 'rent-yearly', title: 'Yearly Rent', value: '$42/SF/yr' },
      { name: 'lease-type', title: 'Lease Type', value: 'NNN' },
      { name: 'parking', title: 'Parking', value: 'Driveway' }
    ],
    price: 500000,
    createdAt: '2022-06-30 18:22:32'
  }),
  12: new Listing({
    id: 12,
    type: propertyTypes.COMMERCIAL_FOR_LEASE,
    subType: subTypes.LAND_INDUSTRIAL,
    params: [],
    paramsAll: [
      { name: 'type', title: 'Property Type', value: 'Retail' },
      { name: 'sub-type', title: 'Subtype', value: 'Executive Office' },
      { name: 'tenancy', title: 'Tenancy', value: 'Single' },
      { name: 'year-built', title: 'Year Built', value: '1941' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'sqft', title: 'Total Building SQFT', value: '1,410' },
      { name: 'rent-monthly', title: 'Monthly Rent', value: '$3.50/SF/mo' },
      { name: 'rent-yearly', title: 'Yearly Rent', value: '$42/SF/yr' },
      { name: 'lease-type', title: 'Lease Type', value: 'NNN' },
      { name: 'parking', title: 'Parking', value: 'Driveway' }
    ],
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    price: 200000,
    createdAt: '2022-06-30 18:22:32'
  })
}
