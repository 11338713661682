
import * as faker from 'faker'
import Bid from '@/Model/RealEstate/Auction/Bid'
import User from '@/Model/User'
import FakeUser from '@/Faker/User'
import Mike from '@/FackApi/api/Users/MikeMaschinoff'
import Ygal from '@/FackApi/api/Users/YgalEdy'
import Erez from '@/FackApi/api/Users/ErezJonathan'
import Paul from '@/FackApi/api/Users/PaulMuller'

export default {
  1: new Bid({
    id: 1,
    user: new User({ ...Mike }),
    amount: 775000,
    date: faker.date.past(faker.datatype.number(5)),
    retracted: false
  }),
  2: new Bid({
    id: 2,
    user: new User({ ...Erez }),
    amount: 315000,
    date: faker.date.past(faker.datatype.number(5)),
    retracted: false
  }),
  3: new Bid({
    id: 3,
    user: new User({ ...Ygal }),
    amount: 125000,
    date: faker.date.past(faker.datatype.number(5)),
    retracted: false
  }),
  4: new Bid({
    id: 4,
    user: new User({ ...FakeUser.create() }),
    amount: 75000,
    date: faker.date.past(faker.datatype.number(5)),
    retracted: false
  }),
  5: new Bid({
    id: 5,
    user: new User({ ...Paul }),
    amount: 25000,
    date: faker.date.past(faker.datatype.number(5)),
    retracted: false
  }),
  6: new Bid({
    id: 6,
    user: new User({ ...Ygal }),
    amount: 85000,
    date: faker.date.past(faker.datatype.number(5)),
    retracted: faker.date.past(faker.datatype.number(5))
  })
}

export const BidsList = {
  52: [],
  53: [
    new Bid({
      id: 1,
      user: new User({ ...Mike }),
      amount: 2775000,
      date: faker.date.past(faker.datatype.number(5)),
      retracted: false
    }),
    new Bid({
      id: 2,
      user: new User({ ...Ygal }),
      amount: 2875000,
      date: faker.date.past(faker.datatype.number(5)),
      retracted: false
    }),
    new Bid({
      id: 4,
      user: new User({ ...FakeUser.create() }),
      amount: 2975000,
      date: faker.date.past(faker.datatype.number(5)),
      retracted: false
    })
  ],
  54: [
    new Bid({
      id: 5,
      user: new User({ ...Mike }),
      amount: 1770000,
      date: faker.date.past(faker.datatype.number(5)),
      retracted: false
    }),
    new Bid({
      id: 6,
      user: new User({ ...Erez }),
      amount: 2170000,
      date: faker.date.past(faker.datatype.number(5)),
      retracted: false
    }),
    new Bid({
      id: 7,
      user: new User({ ...Ygal }),
      amount: 2470000,
      date: faker.date.past(faker.datatype.number(5)),
      retracted: false
    }),
    new Bid({
      id: 8,
      user: new User({ ...FakeUser.create() }),
      amount: 2510000,
      date: faker.date.past(faker.datatype.number(5)),
      retracted: false
    }),
    new Bid({
      id: 9,
      user: new User({ ...Paul }),
      amount: 2600000,
      date: faker.date.past(faker.datatype.number(5)),
      retracted: faker.date.past(faker.datatype.number(5))
    }),
    new Bid({
      id: 10,
      user: new User({ ...Ygal }),
      amount: 2850000,
      date: faker.date.past(faker.datatype.number(5)),
      retracted: false
    })
  ]
}
