import Listing from '@/Model/Listing'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/ResidentialForSaleSubTypes'

export default {
  1: new Listing({
    id: 1,
    subType: subTypes.HOME_VILLA,
    address: '1613 West Boulevard',
    city: 'Mid city',
    state: 'CA',
    zip: '90019',
    price: 385000,
    yearBuild: 1912,
    status: 2,
    createdAt: '2022-06-30 18:22:32',
    paramsAll: [
      { name: 'type-home', title: 'Type', value: 'Single Family' },
      { name: 'sqft', title: 'Sq. Footage', value: '1,410' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1921' },
      { name: 'beds', title: 'Bedrooms', value: 4 },
      { name: 'bath', title: 'Bathrooms', value: 1 },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'air-conditioning-type', title: 'Air Conditioning Type', value: 'Solar' },
      { name: 'heating-type', title: 'Heating Type', value: 'Forced Air' },
      { name: 'condition', title: 'Condition', value: 'Very Good' }
    ]
  }),
  2: new Listing({
    id: 2,
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    subType: subTypes.HOME_SINGLE_FAMILY,
    price: 500000,
    createdAt: '2022-06-30 18:22:32',
    paramsAll: [
      { name: 'type-home', title: 'Type', value: 'Single Family' },
      { name: 'sqft', title: 'Sq. Footage', value: '1,410' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1921' },
      { name: 'beds', title: 'Bedrooms', value: 4 },
      { name: 'bath', title: 'Bathrooms', value: 1 },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'air-conditioning-type', title: 'Air Conditioning Type', value: 'Solar' },
      { name: 'heating-type', title: 'Heating Type', value: 'Forced Air' },
      { name: 'condition', title: 'Condition', value: 'Very Good' }
    ]
  }),
  3: new Listing({
    id: 3,
    price: 200000,
    subType: subTypes.HOME_CONDO,
    createdAt: '2022-06-30 18:22:32',
    paramsAll: [
      { name: 'type-home', title: 'Type', value: 'Single Family' },
      { name: 'sqft', title: 'Sq. Footage', value: '1,410' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1921' },
      { name: 'beds', title: 'Bedrooms', value: 4 },
      { name: 'bath', title: 'Bathrooms', value: 1 },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'air-conditioning-type', title: 'Air Conditioning Type', value: 'Solar' },
      { name: 'heating-type', title: 'Heating Type', value: 'Forced Air' },
      { name: 'condition', title: 'Condition', value: 'Very Good' }
    ]
  })
}
