import * as getters from '@/store/modules/real-estate-properties/types/getters'

export default {
  [getters.GET_VIEW_TYPE]: state => state.viewType,
  [getters.GET_PROPERTIES]: state => state.properties,
  [getters.GET_PROPERTY_BY_ID]: state => state.propertyById,
  [getters.GET_REAL_ESTATE_TYPE]: state => state.propertyType,
  [getters.GET_SEARCH_STRING]: state => state.searchString,
  [getters.GET_REAL_ESTATE_SUBTYPES]: state => state.propertySubTypes,
  [getters.GET_PROPERTY_BIDS]: state => state.propertyBids,
  [getters.GET_SHOW_BIDS_MODAL]: state => state.showBidsModal
}
