import * as mutations from './types/mutations'
import UserModel from '@/Model/User'
import ChatModel from '@/Model/Messanger/Chat'
import MessageModel from '@/Model/Messanger/ChatMessage'

export default {
  [mutations.SET_THEME]: (state, data) => {
    state.theme = data
  },
  [mutations.SET_CREATE_GROUP_CHAT_STATE]: (state, data) => {
    state.createGroupChatState = data
  },
  [mutations.SET_EDIT_GROUP_CHAT_STATE]: (state, data) => {
    state.editGroupChatState = data
  },
  [mutations.SET_EDIT_MEMBERS_GROUP_CHAT_STATE]: (state, data) => {
    state.editGroupMembersChatState = data
  },
  [mutations.SET_ACTIVE_CHAT_ID]: (state, id) => {
    state.activeChatId = id
  },
  [mutations.SET_SEARCH_STRING]: (state, value) => {
    state.searchString = value
  },
  [mutations.SET_CHAT_LIST_LOADING]: (state, value) => {
    state.chatListIsLoading = value
  },
  [mutations.SET_CHAT_LOADING]: (state, value) => {
    state.chatIsLoading = value
  },
  [mutations.SET_CHAT_LIST]: (state, data) => {
    state.chatList = {
      ...state.chatList,
      ...data.reduce(
        (prev, item) => ({
          ...prev,
          [item.id]: new ChatModel(item)
        }),
        {}
      )
    }

    state.chatListMap = [ ...new Set([ ...state.chatListMap, ...data.map(item => item.id) ]) ]
  },
  [mutations.SET_ACTIVE_CHAT_INFO]: (state, data) => {
    state.activeChatInfo = data
  },
  [mutations.SET_ACTIVE_CHAT_MESSAGES]: (state, data) => {
    state.chatList[data.chatId].messages = data.messages
    state.activeChatMessages = data.messages
  },
  [mutations.SET_ACTIVE_CHAT_MEMBERS]: (state, data) => {
    state.activeChatMembers = {
      ...data.reduce(
        (prev, item) => ({
          ...prev,
          [item.id]: new UserModel(item)
        }),
        {}
      )
    }
  },
  [mutations.SEND_MESSAGE]: (state, data) => {
    const message = new MessageModel(data)
    state.chatList[state.activeChatId].messages.push(message)
    // state.activeChatMessages.push(message)
  },
  [mutations.CREATE_CHAT]: (state, data) => {
    const items = { ...state.chatList }

    state.chatList = {
      ...items,
      [data.id]: new ChatModel(data)
    }
    state.chatListMap.unshift(data.id)
  },
  [mutations.SAVE_CHAT]: (state, data) => {
    const items = { ...state.chatList }

    items[data.id] = new ChatModel({ ...data })

    state.chatList = { ...items }
  },
  [mutations.DELETE_CHAT_BY_ID]: (state, id) => {
    const newItems = { ...state.chatList }
    delete newItems[id]

    state.chatList = { ...newItems }

    const newItemsMap = [ ...state.chatListMap ]
    for (let i = 0; i < newItemsMap.length; i++) {
      if (newItemsMap[i] === id) {
        newItemsMap.splice(i, 1)
        i--
      }
    }

    state.chatListMap = [ ...newItemsMap ]
  },
  [mutations.CLEAR_CHAT_BY_ID]: (state, id) => {
    const items = { ...state.chatList }

    items[id].messages = []

    state.chatList = { ...items }
  },
  [mutations.DELETE_MESSAGE_BY_ID]: (state, data) => {
    const chat = state.chatList[data.chatId]

    chat.messages.splice(chat.messages.findIndex(item => item.id === data.messageId), 1)
    state.activeChatMessages = [ ...chat.messages ]
  },
  [mutations.SET_EDIT_MESSAGE_BY_ID]: (state, data) => {
    state.editMessage = data ? new MessageModel(data) : null
  },
  [mutations.UPDATE_MESSAGE_BY_ID]: (state, data) => {
    const chat = state.chatList[data.chatId]
    const index = chat.messages.findIndex(item => item.id === data.message.id)
    chat.messages[index] = new MessageModel({ ...chat.messages[index], ...data.message })
    state.activeChatMessages = [ ...chat.messages ]
  },
  [mutations.SET_DRAFT_MESSAGE]: (state, message) => {
    const newDraftMessage = { [state.activeChatId]: new MessageModel({ message }) }
    state.draftMessages = { ...state.draftMessages, ...newDraftMessage }
  },
  [mutations.SET_DRAFT_HTML_MESSAGE]: (state, message) => {
    const newDraftHtmlMessage = { [state.activeChatId]: new MessageModel({ message }) }
    state.draftHtmlMessages = { ...state.draftHtmlMessages, ...newDraftHtmlMessage }
  },
  [mutations.SET_REPLY_MESSAGE_BY_ID]: (state, data) => {
    state.replyMessage = data ? new MessageModel(data) : null
  },
  [mutations.SET_FORWARD_MESSAGE_BY_ID]: (state, data) => {
    state.forwardMessage = data ? new MessageModel(data) : null
  },
  [mutations.FORWARD_MESSAGE]: (state, data) => {
    const message = new MessageModel(data.message)
    state.chatList[data.chatId].messages.push(message)
  },
  [mutations.SELECT_MESSAGE_BY_ID]: (state, data) => {
    const messages = state.selectedMessages
    const index = messages.indexOf(data)
    if (index === -1) {
      messages.push(data)
    } else {
      messages.splice(index, 1)
    }
    state.selectedMessages = messages
  },
  [mutations.CLEAR_SELECTED_MESSAGES]: (state) => {
    state.selectedMessages = []
  }
}
