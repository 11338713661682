import * as users from '@/FackApi/api/Users'
import LikeReaction from '@/FackApi/api/Reactions/Like'
import JesicaParker from '@/FackApi/api/Comments/JesicaParker'
import PaulMolive from '@/FackApi/api/Comments/PaulMolive'
import Comment from '@/Model/Comment'

export default {
  description: 'Post created by the PostModelConstructor Ipsum is simply dummy text of the printing and typesetting industry.',
  videos: [
    // { src: require('@/uploads/videos/170724_15_Setangibeach.mp4') }
  ],
  time: new Date('October 22, 2021 17:42:25'),
  is_follow: false,
  is_liked: false,
  like_type: LikeReaction.id,
  likes: 12,
  fake: 0,
  reactions: [ ],
  user: users.MikeMaschinoff,
  comments: [
    new Comment({ ...JesicaParker }),
    new Comment({ ...PaulMolive })
  ]
}
