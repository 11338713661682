import * as actions from './types/actions'
import * as mutations from './types/mutations'
import * as notifyActions from '@/store/modules/system-notification/types/actions'
import * as loaderMutations from '@/store/modules/loader/types/mutations'
import PriceOpinion from '@/Model/RealEstate/PriceOpinion'

export default {
  [actions.CREATE_PRICE_OPINION]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      const response = new PriceOpinion({ ...data, createdAt: new Date() })
      commit(mutations.CREATE_PRICE_OPINION, response)
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_INFO_NOTIFICATION,
        `Your Price Opinion has been successfuly added!`,
        { root: true }
      )
      return response
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  }
}
