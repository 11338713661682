import * as faker from 'faker'
import moment from 'moment'
import * as userStatus from '@/config/messanger/userStatus'

export default class FakeChat {
  static create () {
    return {
      id: faker.datatype.uuid(),
      isGroup: false,
      info: this.info(),
      messages: []
    }
  }

  static info () {
    const firstName = faker.name.firstName()
    const lastName = faker.name.lastName()

    return {
      title: `${firstName} ${lastName}`,
      firstName: firstName,
      lastName: lastName,
      image: `https://i.pravatar.cc/300?u=${faker.datatype.uuid()}`,
      username: faker.internet.userName(firstName, lastName),
      address: this.address(),
      phone: faker.phone.phoneNumberFormat(3),
      web: faker.internet.url(),
      onlineStatus: faker.random.arrayElement(userStatus.statusesList),
      lastVisit: new Date(moment(faker.date.recent(12)).utc().format())
    }
  }

  static address () {
    const state = faker.address.stateAbbr()
    return `${faker.address.city()}, ${state} ${faker.address.zipCodeByState('PA')}`
  }
}
