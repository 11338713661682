import Listing from '@/Model/Listing'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/CommercialForSaleSubTypes'
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'

export default {
  7: new Listing({
    id: 7,
    type: propertyTypes.COMMERCIAL_FOR_SALE,
    subType: subTypes.MULTIFAMILY_APARTMENT_BUILDING,
    params: [
      { name: 'cap-rate', value: '4.70% Cap Rate' },
      { name: 'sqft', value: '9,154 SF' },
      { name: 'units', value: '8 Unit Apartment Building' },
      { name: 'year-built', value: 'Built in 2022' }
    ],
    paramsAll: [
      { name: 'type', title: 'Type', value: 'Retail' },
      { name: 'sqft', title: 'Sq. Footage', value: '1,410' },
      { name: 'lot-size', title: 'Lot Size', value: '4,400.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1941' },
      { name: 'cap-rate', title: 'Cap Rate %', value: 3 },
      { name: 'units', title: 'Units', value: 8 },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'rent-control', title: 'Rent Control', value: 'Yes' },
      { name: 'condition', title: 'Condition', value: 'Very Good' }
    ],
    address: '1613 West Boulevard',
    city: 'Mid city',
    state: 'CA',
    zip: '90019',
    price: 385000,
    yearBuild: 1912,
    status: 2,
    createdAt: '2022-06-30 18:22:32'
  }),
  8: new Listing({
    id: 8,
    type: propertyTypes.COMMERCIAL_FOR_SALE,
    subType: subTypes.MULTIFAMILY_APARTMENT_BUILDING,
    params: [
      { name: 'cap-rate', value: '3.20% Cap Rate' },
      { name: 'sqft', value: '10,154 SF' },
      { name: 'units', value: '8 Unit Apartment Building' },
      { name: 'year-built', value: 'Built in 2012' }
    ],
    paramsAll: [
      { name: 'type', title: 'Type', value: 'Retail' },
      { name: 'sqft', title: 'Sq. Footage', value: '1,410' },
      { name: 'lot-size', title: 'Lot Size', value: '4,400.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1941' },
      { name: 'cap-rate', title: 'Cap Rate %', value: 3 },
      { name: 'units', title: 'Units', value: 8 },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'rent-control', title: 'Rent Control', value: 'Yes' },
      { name: 'condition', title: 'Condition', value: 'Very Good' }
    ],
    price: 500000,
    createdAt: '2022-06-30 18:22:32'
  }),
  9: new Listing({
    id: 9,
    type: propertyTypes.COMMERCIAL_FOR_SALE,
    subType: subTypes.MULTIFAMILY_APARTMENT_BUILDING,
    params: [
      { name: 'cap-rate', value: '5.20% Cap Rate' },
      { name: 'sqft', value: '6,154 SF' },
      { name: 'units', value: '8 Unit Apartment Building' },
      { name: 'year-built', value: 'Built in 2021' }
    ],
    paramsAll: [
      { name: 'type', title: 'Type', value: 'Retail' },
      { name: 'sqft', title: 'Sq. Footage', value: '1,410' },
      { name: 'lot-size', title: 'Lot Size', value: '4,400.00 Sq. Ft.' },
      { name: 'year-built', title: 'Year Built', value: '1941' },
      { name: 'cap-rate', title: 'Cap Rate %', value: 3 },
      { name: 'units', title: 'Units', value: 8 },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'rent-control', title: 'Rent Control', value: 'Yes' },
      { name: 'condition', title: 'Condition', value: 'Very Good' }
    ],
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    price: 200000,
    createdAt: '2022-06-30 18:22:32'
  })
}
