export const FIXER_UPPER_FOR_SALE_HOME = 10000

export const FIXER_UPPER_FOR_SALE_TEXT = ''

export const HOME_SINGLE_FAMILY = 10001
export const HOME_CONDO = 10002
export const HOME_TOWNHOUSE = 10003
export const HOME_LOFT = 10004
export const HOME_MOBILE_MANUFACTURED = 10005
export const HOME_DUPLEX_TRIPLEX_FOURPLEX = 10006

export const HOME_SINGLE_FAMILY_TEXT = 'Single family'
export const HOME_CONDO_TEXT = 'Condo'
export const HOME_TOWNHOUSE_TEXT = 'Townhouse'
export const HOME_LOFT_TEXT = 'Loft'
export const HOME_MOBILE_MANUFACTURED_TEXT = 'Mobile / Manufactured'
export const HOME_DUPLEX_TRIPLEX_FOURPLEX_TEXT = 'Duplex/Triplex/Fourplex'

export const FixerUpperForSaleSubTypes = [
  {
    id: FIXER_UPPER_FOR_SALE_HOME,
    title: FIXER_UPPER_FOR_SALE_TEXT,
    options: [
      { id: HOME_SINGLE_FAMILY, text: HOME_SINGLE_FAMILY_TEXT },
      { id: HOME_CONDO, text: HOME_CONDO_TEXT },
      { id: HOME_TOWNHOUSE, text: HOME_TOWNHOUSE_TEXT },
      { id: HOME_LOFT, text: HOME_LOFT_TEXT },
      { id: HOME_MOBILE_MANUFACTURED, text: HOME_MOBILE_MANUFACTURED_TEXT },
      { id: HOME_DUPLEX_TRIPLEX_FOURPLEX, text: HOME_DUPLEX_TRIPLEX_FOURPLEX_TEXT }
    ]
  }
]

export const FixerUpperForSaleSubTypesOptions = [
  ...FixerUpperForSaleSubTypes[0].options
]
