import Listing from '@/Model/Listing'
import * as subTypes from '@/FackApi/api/RealEstate/SubTypes/ResidentialForRentSubTypes'
import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'

export default {
  60: new Listing({
    id: 60,
    headline: 'Oak Park Apartment Homes',
    type: propertyTypes.RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS,
    subType: subTypes.HOME_TOWN_HOMES,
    address: '1613 West Boulevard',
    city: 'Mid city',
    state: 'CA',
    zip: '90019',
    price: 385000,
    yearBuild: 1912,
    status: 2,
    createdAt: '2022-06-30 18:22:32'
  }),
  61: new Listing({
    id: 61,
    headline: 'Dalewood Apartment Homes',
    type: propertyTypes.RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS,
    subType: subTypes.HOME_HOUSES,
    price: 500000,
    createdAt: '2022-06-30 18:22:32'
  }),
  62: new Listing({
    id: 62,
    headline: 'Hollywood Apartment Homes',
    type: propertyTypes.RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS,
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    price: 200000,
    subType: subTypes.HOME_CONDOS,
    createdAt: '2022-06-30 18:22:32'
  })
}
