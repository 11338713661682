import * as actions from './types/actions'
import * as mutations from './types/mutations'
import realEstateService from '@/services/realEstate/services'
import * as loaderMutations from '@/store/modules/loader/types/mutations'
import * as notifyActions from '@/store/modules/system-notification/types/actions'

export default {
  [actions.SET_VIEW_TYPE]: (
    { commit },
    type
  ) => {
    commit(mutations.SET_VIEW_TYPE, type)
  },
  [actions.SET_REAL_ESTATE_TYPE]: (
    { commit },
    type
  ) => {
    commit(mutations.SET_REAL_ESTATE_TYPE, type)
  },
  [actions.SET_SEARCH_STRING]: (
    { commit },
    string
  ) => {
    commit(mutations.SET_SEARCH_STRING, string)
  },
  [actions.FETCH_PROPERTY_SUB_TYPES]: (
    { commit },
    propertyType
  ) => {
    const data = realEstateService.fetchPropertySubTypes(propertyType)
    commit(mutations.SET_PROPERTY_SUB_TYPES, data)
  },
  [actions.SET_SHOW_BIDS_MODAL]: (
    { commit },
    status
  ) => {
    commit(mutations.SET_SHOW_BIDS_MODAL, status)
  },
  [actions.FETCH_PROPERTIES]: async (
    { commit, dispatch },
    data = {
      page: 1,
      filters: {
        propertyType: null
      }
    }
  ) => {
    try {
      if (data.page === 1) {
        commit(mutations.CLEAR_PROPERTIES)
      }

      const response = await realEstateService.fetchProperties(data)

      commit(mutations.SET_PROPERTIES, response?.data?.data)
    } catch (error) {

    }
  },
  [actions.FETCH_PROPERTY_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await realEstateService.getById(id)
      commit(mutations.SET_PROPERTY, response?.data?.data)
    } catch (error) {

    }
  },
  [actions.POST_PROPERTY]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      const response = null
      dispatch(
        'systemNotification/' + notifyActions.SET_SUCCESS_NOTIFICATION,
        `Your listing has been successfully posted!`,
        { root: true }
      )

      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      return response?.data?.data
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.FETCH_PROPERTY_BIDS]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      const response = await realEstateService.fetchPropertyBids(id)
      commit(mutations.SET_PROPERTY_BIDS, response?.data?.data)
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.ADD_PROPERTY_BID]: async (
    { commit, dispatch },
    amount
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      commit(mutations.ADD_PROPERTY_BID, amount)
      dispatch(
        'systemNotification/' + notifyActions.SET_SUCCESS_NOTIFICATION,
        `Your bid has been successfully added!`,
        { root: true }
      )
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.RETRACT_PROPERTY_BID]: async (
    { commit, dispatch }
  ) => {
    try {
      commit('loader/' + loaderMutations.SET_LOADING, true, { root: true })
      commit(mutations.RETRACT_PROPERTY_BID)
      dispatch(
        'systemNotification/' + notifyActions.SET_SUCCESS_NOTIFICATION,
        `Your bid has been successfully retracted!`,
        { root: true }
      )
    } catch (error) {
      commit('loader/' + loaderMutations.SET_LOADING, false, { root: true })
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  }
}
