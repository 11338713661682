import * as users from '@/FackApi/api/Users'
import * as postType from '@/config/post/postType'
import Comment from '@/Model/Comment'
import LoveReaction from '@/FackApi/api/Reactions/Love'
import LikeReaction from '@/FackApi/api/Reactions/Like'
import CareReaction from '@/FackApi/api/Reactions/Care'
import PaulMolive from '@/FackApi/api/Comments/PaulMolive'

export default {
  description: null,
  property: {
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In dictum urna nulla, nec pharetra orci eleifend eget. Sed vestibulum est nisi, ac iaculis dui vestibulum sed. Phasellus pretium eleifend lectus in facilisis. Mauris justo urna, lacinia ac enim at, tristique iaculis ligula. Suspendisse dictum luctus vestibulum. Nulla facilisis eu dolor ut auctor. Nunc iaculis est suscipit, vulputate orci molestie, sodales orci. Donec molestie volutpat diam a tristique. In felis sem, interdum vel odio nisi.',
    image: 'https:/homeiz.com/appfiles/photoalbum/6a251d740176461f3b3df96faabf40a11d35455d/thumbs/7ac7a569e932d7bf8001e93ed496f458254b8f27.jpeg',
    address: '1613 West Boulevard',
    city: 'Mid city',
    state: 'CA',
    zip: '90019',
    paramsAll: [
      { name: 'type-home', title: 'Type', value: 'Single Family' },
      { name: 'sqft', title: 'Sq. Footage', value: '1,410' },
      { name: 'lot-size', title: 'Lot Size', value: '4,900.00 Sq. Ft.' },
      { name: 'price-per-sqft', title: 'Price/sqft', value: '$510' },
      { name: 'year-built', title: 'Year Built', value: '1921' },
      { name: 'beds', title: 'Bedrooms', value: 4 },
      { name: 'bath', title: 'Bathrooms', value: 1 },
      { name: 'parking', title: 'Parking', value: 'Driveway' },
      { name: 'air-conditioning-type', title: 'Air Conditioning Type', value: 'Solar' },
      { name: 'heating-type', title: 'Heating Type', value: 'Forced Air' },
      { name: 'swimming-pool', title: 'Pool', value: 'Yes' },
      { name: 'condition', title: 'Condition', value: 'Very Good' },
      { name: 'agency-fee', title: 'Agency fee', value: '2.5%' }
    ]
  },
  type: postType.PROPERTY,
  time: new Date(),
  is_follow: false,
  is_liked: true,
  like_type: LoveReaction.id,
  likes: 2,
  fake: 0,
  reactions: [
    {
      type: { ...LoveReaction },
      count: 6,
      user_list: []
    },
    {
      type: { ...LikeReaction },
      count: 4,
      user_list: []
    },
    {
      type: { ...CareReaction },
      count: 8,
      user_list: []
    }
  ],
  user: users.RandomUser,
  comments: [
    new Comment({ ...PaulMolive })
  ]
}
