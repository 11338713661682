export const GET_THEME = 'GET_THEME'
export const GET_SEARCH_STRING = 'GET_SEARCH_STRING'
export const GET_CHAT_LIST = 'GET_CHAT_LIST'
export const GET_CHAT_LIST_MAP = 'GET_CHAT_LIST_MAP'
export const GET_ACTIVE_CHAT_ID = 'GET_ACTIVE_CHAT_ID'
export const CREATE_GROUP_CHAT_STATE = 'CREATE_GROUP_CHAT_STATE'
export const CREATE_GROUP_CHAT_DRAFT = 'CREATE_GROUP_CHAT_DRAFT'
export const GET_CHAT_LIST_IS_LOADING = 'GET_CHAT_LIST_IS_LOADING'
export const GET_CHAT_IS_LOADING = 'GET_CHAT_IS_LOADING'
export const GET_ACTIVE_CHAT_INFO = 'GET_ACTIVE_CHAT_INFO'
export const GET_ACTIVE_CHAT_MESSAGES = 'GET_ACTIVE_CHAT_MESSAGES'
export const GET_ACTIVE_CHAT_MEMBERS = 'GET_ACTIVE_CHAT_MEMBERS'
export const GET_ACTIVE_CHAT = 'GET_ACTIVE_CHAT'
export const EDIT_GROUP_CHAT_STATE = 'EDIT_GROUP_CHAT_STATE'
export const EDIT_GROUP_MEMBERS_CHAT_STATE = 'EDIT_GROUP_MEMBERS_CHAT_STATE'
export const GET_EDIT_MESSAGE_STATE = 'GET_EDIT_MESSAGE_STATE'
export const GET_DRAFT_MESSAGE = 'GET_DRAFT_MESSAGE'
export const GET_DRAFT_HTML_MESSAGE = 'GET_DRAFT_HTML_MESSAGE'
export const GET_REPLY_MESSAGE_STATE = 'GET_REPLY_MESSAGE_STATE'
export const GET_FORWARD_MESSAGE = 'GET_FORWARD_MESSAGE'
export const GET_SELECTED_MESSAGES = 'GET_SELECTED_MESSAGES'
