export const NOTE_FOR_SALE_BANK_LENDER = 800

export const NOTE_FOR_SALE_BANK_LENDER_TEXT = 'Bank/Lender Note Sale'

export const COMMERCIAL_REAL_ESTATE_OWNER_OCCUPIED = 8000
export const FIRST_POSITION_ONE_FOUR_FAMILY_RESIDENTIAL = 8001
export const COMMERCIAL_REAL_ESTATE_NON_OWNER_OCCUPIED = 8002
export const JUNIOR_ONE_FOUR_FAMILY_RESIDENTIAL = 8003
export const MULTIFAMILY_AND_APARTMENT_LOANS = 8004
export const AGRICULTURAL_LAND = 8005
export const DEVELOPMENT_LOANS = 8006
export const ONE_FOUR_FAMILY_RESIDENTIAL_CONSTRUCTION = 8007
export const INDUSTRIAL_REAL_ESTATE = 8008
export const FARM_AND_AGRICULTURAL_LOANS = 8009
export const ALL_OTHER_CONSTRUCTION = 8010

export const COMMERCIAL_REAL_ESTATE_OWNER_OCCUPIED_TEXT = 'Commercial Real Estate Owner Occupied'
export const FIRST_POSITION_ONE_FOUR_FAMILY_RESIDENTIAL_TEXT = 'First Position 1-4 Family residential'
export const COMMERCIAL_REAL_ESTATE_NON_OWNER_OCCUPIED_TEXT = 'Commercial Real Estate Non-Owner Occupied'
export const JUNIOR_ONE_FOUR_FAMILY_RESIDENTIAL_TEXT = 'Junior 1-4 Family Residential(Exist)'
export const MULTIFAMILY_AND_APARTMENT_LOANS_TEXT = 'Multifamily and Apartment Loans (5 or more residential units)'
export const AGRICULTURAL_LAND_TEXT = 'Agricultural Land'
export const DEVELOPMENT_LOANS_TEXT = 'Development loans'
export const ONE_FOUR_FAMILY_RESIDENTIAL_CONSTRUCTION_TEXT = '1-4 Family Residential (Construction)'
export const INDUSTRIAL_REAL_ESTATE_TEXT = 'Industrial Real Estate'
export const FARM_AND_AGRICULTURAL_LOANS_TEXT = 'Farm and Agricultural Loans'
export const ALL_OTHER_CONSTRUCTION_TEXT = 'All other construction'

export const NoteForSaleSubTypes = [
  {
    id: NOTE_FOR_SALE_BANK_LENDER,
    title: NOTE_FOR_SALE_BANK_LENDER_TEXT,
    options: [
      { id: COMMERCIAL_REAL_ESTATE_OWNER_OCCUPIED, text: COMMERCIAL_REAL_ESTATE_OWNER_OCCUPIED_TEXT },
      { id: FIRST_POSITION_ONE_FOUR_FAMILY_RESIDENTIAL, text: FIRST_POSITION_ONE_FOUR_FAMILY_RESIDENTIAL_TEXT },
      { id: COMMERCIAL_REAL_ESTATE_NON_OWNER_OCCUPIED, text: COMMERCIAL_REAL_ESTATE_NON_OWNER_OCCUPIED_TEXT },
      { id: JUNIOR_ONE_FOUR_FAMILY_RESIDENTIAL, text: JUNIOR_ONE_FOUR_FAMILY_RESIDENTIAL_TEXT },
      { id: MULTIFAMILY_AND_APARTMENT_LOANS, text: MULTIFAMILY_AND_APARTMENT_LOANS_TEXT },
      { id: AGRICULTURAL_LAND, text: AGRICULTURAL_LAND_TEXT },
      { id: DEVELOPMENT_LOANS, text: DEVELOPMENT_LOANS_TEXT },
      { id: ONE_FOUR_FAMILY_RESIDENTIAL_CONSTRUCTION, text: ONE_FOUR_FAMILY_RESIDENTIAL_CONSTRUCTION_TEXT },
      { id: INDUSTRIAL_REAL_ESTATE, text: INDUSTRIAL_REAL_ESTATE_TEXT },
      { id: FARM_AND_AGRICULTURAL_LOANS, text: FARM_AND_AGRICULTURAL_LOANS_TEXT },
      { id: ALL_OTHER_CONSTRUCTION, text: ALL_OTHER_CONSTRUCTION_TEXT }
    ]
  }
]

export const NoteForSaleSubTypesOptions = [
  ...NoteForSaleSubTypes[0].options
]
