import store from '@/store'
import responsePresenter from '@/FackApi/services/responsePresenter'

export default async function deleteChatById (id) {
  await new Promise((resolve) => setTimeout(resolve, 300))
  const messengerState = store.state.messanger
  const response = responsePresenter({ data: messengerState.chatList[id] })

  return response
}
