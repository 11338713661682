import * as propertyTypes from '@/FackApi/api/RealEstate/Types/propertyTypes'
import { ResidentialForSaleSubTypes } from '@/FackApi/api/RealEstate/SubTypes/ResidentialForSaleSubTypes'
import { ResidentialForRentSubTypes } from '@/FackApi/api/RealEstate/SubTypes/ResidentialForRentSubTypes'
import { CommercialForSaleSubTypes } from '@/FackApi/api/RealEstate/SubTypes/CommercialForSaleSubTypes'
import { CommercialForLeaseSubTypes } from '@/FackApi/api/RealEstate/SubTypes/CommercialForLeaseSubTypes'
import { FixerUpperForSaleSubTypes } from '@/FackApi/api/RealEstate/SubTypes/FixerUpperForSaleSubTypes'
import { LuxuryHomesForRentSubTypes } from '@/FackApi/api/RealEstate/SubTypes/LuxuryHomesForRentSubTypes'
import { TripleNetInvestmentsForSaleSubTypes } from '@/FackApi/api/RealEstate/SubTypes/TripleNetInvestmentsForSaleSubTypes'
import { NoteForSaleSubTypes } from '@/FackApi/api/RealEstate/SubTypes/NoteForSaleSubTypes'
import { CashOfferSubTypes } from '@/FackApi/api/RealEstate/SubTypes/CashOfferSubTypes'

export default {
  [propertyTypes.RESIDENTIAL_FOR_SALE]: ResidentialForSaleSubTypes,
  [propertyTypes.RESIDENTIAL_FOR_RENT]: ResidentialForRentSubTypes,
  [propertyTypes.RESIDENTIAL_FOR_RENT_MULTIPLE_UNITS]: [],
  [propertyTypes.ROOM_MATE_FINDER]: [],
  [propertyTypes.ROOMS_FOR_RENT]: [],
  [propertyTypes.COMMERCIAL_FOR_SALE]: CommercialForSaleSubTypes,
  [propertyTypes.COMMERCIAL_FOR_LEASE]: CommercialForLeaseSubTypes,
  [propertyTypes.COMING_SOON_RESIDENTIAL]: ResidentialForSaleSubTypes,
  [propertyTypes.COMING_SOON_COMMERCIAL]: CommercialForSaleSubTypes,
  [propertyTypes.PRE_FORECLOSURE_RESIDENTIAL]: ResidentialForSaleSubTypes,
  [propertyTypes.PRE_FORECLOSURE_COMMERCIAL]: CommercialForSaleSubTypes,
  [propertyTypes.FSBO_RESIDENTIAL_FOR_SALE]: ResidentialForSaleSubTypes,
  [propertyTypes.FSBO_COMMERCIAL_FOR_SALE]: CommercialForSaleSubTypes,
  [propertyTypes.FIXER_UPPER_FOR_SALE]: FixerUpperForSaleSubTypes,
  [propertyTypes.LUXURY_HOMES_FOR_RENT]: LuxuryHomesForRentSubTypes,
  [propertyTypes.LUXURY_HOMES_FOR_SALE]: LuxuryHomesForRentSubTypes,
  [propertyTypes.TRIPLE_NET_INVESTMENTS_FOR_SALE]: TripleNetInvestmentsForSaleSubTypes,
  [propertyTypes.CASH_OFFER_LISTINGS]: CashOfferSubTypes,
  [propertyTypes.NOTE_FOR_SALE]: NoteForSaleSubTypes,
  [propertyTypes.VACATION_HOMES_RENTALS]: [],
  [propertyTypes.AUCTION_STYLE_LISTING]: []
}
