
import * as actions from './types/actions'
import * as mutations from './types/mutations'
import * as notifyActions from '@/store/modules/system-notification/types/actions'
import messangerHttpService from '@/FackApi/services/messanger'

export default {
  [actions.CHANGE_THEME]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit(mutations.SET_THEME, data)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.OPEN_CREATE_GROUP_CHAT]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_CREATE_GROUP_CHAT_STATE, true)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.CLOSE_CREATE_GROUP_CHAT]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_CREATE_GROUP_CHAT_STATE, false)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.FETCH_ACTIVE_CHAT_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      commit(mutations.SET_CHAT_LOADING, true)
      commit(mutations.SET_CREATE_GROUP_CHAT_STATE, false)
      commit(mutations.SET_ACTIVE_CHAT_ID, id)
      const response = await messangerHttpService.fetchChatById(id)
      commit(mutations.SET_ACTIVE_CHAT_INFO, response?.data?.data?.info)
      commit(mutations.SET_ACTIVE_CHAT_MEMBERS, response?.data?.data.members)
      commit(mutations.SET_ACTIVE_CHAT_MESSAGES, { chatId: id, messages: response?.data?.data?.messages })
      commit(mutations.SET_EDIT_MESSAGE_BY_ID, null)
      commit(mutations.SET_REPLY_MESSAGE_BY_ID, null)
      commit(mutations.SET_CHAT_LOADING, false)
      return response
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.FETCH_CHAT_LIST]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_CHAT_LIST_LOADING, true)
      const response = await messangerHttpService.fetchChatList()
      commit(mutations.SET_CHAT_LIST, response?.data?.data)
      commit(mutations.SET_CHAT_LIST_LOADING, false)
      return response
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.UPDATE_SEARCH_STRING]: async (
    { commit, dispatch },
    value
  ) => {
    try {
      commit(mutations.SET_SEARCH_STRING, value)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.SEND_MESSAGE]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      const response = await messangerHttpService.createMessage(data)
      commit(mutations.SEND_MESSAGE, response?.data?.data)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.CREATE_CHAT]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      const response = await messangerHttpService.createChat(data)
      commit(mutations.CREATE_CHAT, response?.data?.data)
      commit(mutations.SET_CHAT_LOADING, true)
      commit(mutations.SET_ACTIVE_CHAT_ID, response?.data?.data.id)
      commit(mutations.SET_ACTIVE_CHAT_INFO, response?.data?.data?.info)
      commit(mutations.SET_ACTIVE_CHAT_MEMBERS, response?.data?.data.members)
      commit(mutations.SET_ACTIVE_CHAT_MESSAGES, { chatId: response?.data?.data.id, messages: response?.data?.data?.messages })
      commit(mutations.SET_CHAT_LOADING, false)

      dispatch(
        'systemNotification/' + notifyActions.SET_DONE_NOTIFICATION,
        `${response?.data?.data.creator.fullName} created group ${response?.data?.data.title}`,
        { root: true }
      )
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.CLOSE_EDIT_GROUP_CHAT]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_EDIT_GROUP_CHAT_STATE, false)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.OPEN_EDIT_GROUP_CHAT]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_EDIT_GROUP_CHAT_STATE, true)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.OPEN_EDIT_MEMBERS_GROUP_CHAT]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_EDIT_MEMBERS_GROUP_CHAT_STATE, true)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.CLOSE_EDIT_MEMBERS_GROUP_CHAT]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_EDIT_MEMBERS_GROUP_CHAT_STATE, false)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.SAVE_CHAT]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      const response = await messangerHttpService.updateChatById(data.id, data)

      commit(mutations.SAVE_CHAT, response?.data?.data)
      commit(mutations.SET_CHAT_LOADING, true)
      commit(mutations.SET_ACTIVE_CHAT_ID, response?.data?.data.id)
      commit(mutations.SET_ACTIVE_CHAT_INFO, response?.data?.data?.info)
      commit(mutations.SET_ACTIVE_CHAT_MEMBERS, response?.data?.data.members)
      commit(mutations.SET_ACTIVE_CHAT_MESSAGES, { chatId: data.id, messages: response?.data?.data?.messages })
      commit(mutations.SET_CHAT_LOADING, false)

      dispatch(
        'systemNotification/' + notifyActions.SET_DONE_NOTIFICATION,
        `${response?.data?.data.creator.fullName} updated group ${response?.data?.data.title}`,
        { root: true }
      )

      return response?.data?.data
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.DELETE_CHAT_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await messangerHttpService.deleteChatById(id)

      commit(mutations.DELETE_CHAT_BY_ID, id)
      commit(mutations.SET_CHAT_LOADING, true)
      commit(mutations.SET_ACTIVE_CHAT_ID, null)
      commit(mutations.SET_CHAT_LOADING, false)

      dispatch(
        'systemNotification/' + notifyActions.SET_DONE_NOTIFICATION,
        `${response?.data?.data.creator.fullName} deleted ${response?.data?.data.title}`,
        { root: true }
      )
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.CLEAR_CHAT_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await messangerHttpService.clearChatById(id)

      commit(mutations.SET_CHAT_LOADING, true)
      commit(mutations.CLEAR_CHAT_BY_ID, id)
      commit(mutations.SET_ACTIVE_CHAT_MESSAGES, { chatId: id, messages: [] })
      commit(mutations.SET_CHAT_LOADING, false)

      dispatch(
        'systemNotification/' + notifyActions.SET_DONE_NOTIFICATION,
        `${response?.data?.data.creator.fullName} cleared all '${response?.data?.data.title}' chat history`,
        { root: true }
      )
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.BLOCK_CHAT_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await messangerHttpService.updateChatById(id, { isBlocked: true })

      commit(mutations.SAVE_CHAT, response?.data?.data)
      commit(mutations.SET_CHAT_LOADING, true)
      commit(mutations.SET_ACTIVE_CHAT_ID, response?.data?.data.id)
      commit(mutations.SET_ACTIVE_CHAT_INFO, response?.data?.data?.info)
      commit(mutations.SET_ACTIVE_CHAT_MEMBERS, response?.data?.data.members)
      commit(mutations.SET_ACTIVE_CHAT_MESSAGES, { chatId: id, messages: response?.data?.data?.messages })
      commit(mutations.SET_CHAT_LOADING, false)

      dispatch(
        'systemNotification/' + notifyActions.SET_DONE_NOTIFICATION,
        `${response?.data?.data.creator.fullName} blocked '${response?.data?.data.title}'`,
        { root: true }
      )
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.UNBLOCK_CHAT_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await messangerHttpService.updateChatById(id, { isBlocked: false })

      commit(mutations.SAVE_CHAT, response?.data?.data)
      commit(mutations.SET_CHAT_LOADING, true)
      commit(mutations.SET_ACTIVE_CHAT_ID, response?.data?.data.id)
      commit(mutations.SET_ACTIVE_CHAT_INFO, response?.data?.data?.info)
      commit(mutations.SET_ACTIVE_CHAT_MEMBERS, response?.data?.data.members)
      commit(mutations.SET_ACTIVE_CHAT_MESSAGES, { chatId: id, messages: response?.data?.data?.messages })
      commit(mutations.SET_CHAT_LOADING, false)

      dispatch(
        'systemNotification/' + notifyActions.SET_DONE_NOTIFICATION,
        `${response?.data?.data.creator.fullName} unblocked '${response?.data?.data.title}'`,
        { root: true }
      )
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.DELETE_MESSAGE_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await messangerHttpService.deleteMessageById(id)

      commit(mutations.DELETE_MESSAGE_BY_ID, response?.data?.data)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.SET_EDIT_MESSAGE_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await messangerHttpService.getMessageById(id)
      commit(mutations.SET_REPLY_MESSAGE_BY_ID, null)
      commit(mutations.SET_EDIT_MESSAGE_BY_ID, response?.data?.data)
      commit(mutations.SET_DRAFT_MESSAGE, response?.data?.data.message)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.DISCARD_EDIT_MESSAGE]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_EDIT_MESSAGE_BY_ID, null)
      commit(mutations.SET_DRAFT_MESSAGE, null)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.UPDATE_MESSAGE_BY_ID]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      const response = await messangerHttpService.updateMessageById(data.id, data)
      commit(mutations.UPDATE_MESSAGE_BY_ID, response?.data?.data)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.UPDATE_DRAFT_MESSAGE]: (
    { commit, dispatch },
    message
  ) => {
    try {
      commit(mutations.SET_DRAFT_MESSAGE, message)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.UPDATE_DRAFT_HTML_MESSAGE]: (
    { commit, dispatch },
    message
  ) => {
    try {
      commit(mutations.SET_DRAFT_HTML_MESSAGE, message)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.SET_REPLY_MESSAGE_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await messangerHttpService.getMessageById(id)
      commit(mutations.SET_EDIT_MESSAGE_BY_ID, null)
      commit(mutations.SET_DRAFT_MESSAGE, null)
      commit(mutations.SET_REPLY_MESSAGE_BY_ID, response?.data?.data)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.DISCARD_REPLY_MESSAGE]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_REPLY_MESSAGE_BY_ID, null)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.SET_FORWARD_MESSAGE_BY_ID]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      const response = await messangerHttpService.getMessageById(id)
      commit(mutations.SET_EDIT_MESSAGE_BY_ID, null)
      commit(mutations.SET_DRAFT_MESSAGE, null)
      commit(mutations.SET_REPLY_MESSAGE_BY_ID, null)
      commit(mutations.SET_FORWARD_MESSAGE_BY_ID, response?.data?.data)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.DISCARD_FORWARD_MESSAGE]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.SET_FORWARD_MESSAGE_BY_ID, null)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.FORWARD_MESSAGE]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit(mutations.SET_CHAT_LOADING, true)
      commit(mutations.SET_CREATE_GROUP_CHAT_STATE, false)
      commit(mutations.SET_ACTIVE_CHAT_ID, data.chatId)
      // Insert new message
      const responseCreate = await messangerHttpService.forwardMessage(data)
      commit(mutations.FORWARD_MESSAGE, responseCreate?.data?.data)

      const response = await messangerHttpService.fetchChatById(data.chatId)
      commit(mutations.SET_ACTIVE_CHAT_INFO, response?.data?.data?.info)
      commit(mutations.SET_ACTIVE_CHAT_MEMBERS, response?.data?.data.members)
      commit(mutations.SET_ACTIVE_CHAT_MESSAGES, { chatId: data.chatId, messages: response?.data?.data?.messages })
      commit(mutations.SET_EDIT_MESSAGE_BY_ID, null)
      commit(mutations.SET_REPLY_MESSAGE_BY_ID, null)
      commit(mutations.SET_FORWARD_MESSAGE_BY_ID, null)
      commit(mutations.SET_CHAT_LOADING, false)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.SELECT_MESSAGE]: async (
    { commit, dispatch },
    id
  ) => {
    try {
      commit(mutations.SELECT_MESSAGE_BY_ID, id)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.CLEAR_SELECTED_MESSAGES]: async (
    { commit, dispatch }
  ) => {
    try {
      commit(mutations.CLEAR_SELECTED_MESSAGES)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  },
  [actions.BULK_FORWARD_MESSAGE]: async (
    { commit, dispatch },
    data
  ) => {
    try {
      commit(mutations.SET_CHAT_LOADING, true)
      commit(mutations.SET_CREATE_GROUP_CHAT_STATE, false)

      if (data.messages) {
        await data.messages.forEach(async (messageId) => {
          const responseMessage = await messangerHttpService.getMessageById(messageId)
          data = { ...data, message: responseMessage?.data?.data }
          // Insert new message
          const responseCreate = await messangerHttpService.forwardMessage(data)
          commit(mutations.FORWARD_MESSAGE, responseCreate?.data?.data)
        })
      }

      const response = await messangerHttpService.fetchChatById(data.chatId)
      commit(mutations.SET_ACTIVE_CHAT_ID, data.chatId)
      commit(mutations.SET_ACTIVE_CHAT_INFO, response?.data?.data?.info)
      commit(mutations.SET_ACTIVE_CHAT_MEMBERS, response?.data?.data.members)
      commit(mutations.SET_ACTIVE_CHAT_MESSAGES, { chatId: data.chatId, messages: response?.data?.data?.messages })
      commit(mutations.SET_EDIT_MESSAGE_BY_ID, null)
      commit(mutations.SET_REPLY_MESSAGE_BY_ID, null)
      commit(mutations.SET_FORWARD_MESSAGE_BY_ID, null)
      commit(mutations.SET_CHAT_LOADING, false)
    } catch (error) {
      dispatch(
        'systemNotification/' + notifyActions.SET_ERROR_NOTIFICATION,
        error?.response?.data?.error,
        { root: true }
      )
    }
  }
}
