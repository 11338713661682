import fetchPropertySubTypes from '@/services/realEstate/fetchPropertySubTypes'
import fetchProperties from '@/services/realEstate/fetchProperties'
import fetchPropertyBids from '@/services/realEstate/fetchPropertyBids'
import getById from '@/services/realEstate/getById'

export default {
  fetchPropertySubTypes,
  fetchProperties,
  getById,
  fetchPropertyBids
}
